import React from "react";

//系统盘制作教程

const Hints = () => {
  return (
    <div className="Advice">
      <h4>x86_64系列</h4>

      <table>
        <thead>
          <tr>
            <td>操作系统</td>
            <td>软件环境</td>
            <td>安装教程</td>
            <td>绑定教程</td>
          </tr>
        </thead>
        <tbody>
          {/*<tr>
          <td rowspan="3">支持Docker的操作系统，包含：<br/>
            常见的Linux发行版：<br/>
            （如：Ubuntu/Debian/CentOS/Debian/RHEL/SUSE/CoreOS）<br/>
            类Unix：<br/>
            （如：FreeBSD/OpenBSD）<br/>
            Windows7/8/10企业版或专业版或以上；苹果MacOS：<br/>
            （需要手动安装Docker）</td>
          <td>Server</td>
          <td><a href="https://km.webrtc.win/index.php?share/file&user=102&sid=MNrP5RTZ"> 安装教程</a></td>
          <td><a href="https://km.webrtc.win/index.php?share/file&user=102&sid=hYN7eKXq ">远程绑定</a></td>
        </tr>*/}
          <tr>
            <td rowSpan="2">
              支持Docker的操作系统，包含：
              <br />
              常见的Linux发行版：
              <br />
              （如：Ubuntu/Debian/CentOS/Debian/RHEL/SUSE/CoreOS）
              <br />
              类Unix：
              <br />
              （如：FreeBSD/OpenBSD）
              <br />
              Windows7/8/10企业版或专业版或以上；苹果MacOS：
              <br />
              （需要手动安装Docker）
            </td>
            <td>Desktop</td>
            <td>
              <a href="http://kod.webrtc.win/#s/6PSPgxjQ"> 安装教程</a>
            </td>
            <td>
              <a href="/home/doc/bindnode" target={"_self"}>
                本机绑定
              </a>
            </td>
          </tr>
          <tr>
            <td>宝塔面板BT-Panel</td>
            <td>
              <a href="https://www.bt.cn/bbs/thread-30909-1-1.html">
                安装第三方插件
              </a>
            </td>
            <td>
              <a href=" https://www.bt.cn/bbs/thread-30909-1-1.html">
                {" "}
                远程绑定
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <h4>MTK7621系列</h4>
      <table>
        <thead>
          <tr>
            <td>硬件名称</td>
            <td>操作系统</td>
            <td>安装教程</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="2">newifi3(新路由三）</td>
            <td>官方原厂固件</td>
            <td>
              <a href="/home/doc/bindnode">新三正常挖黄金可直接绑定加入</a>或
              <a href="http://kod.webrtc.win/#s/6Ci-Ersg">安装ipk加入</a>
            </td>
          </tr>
          <tr>
            <td>PandoraBox</td>
            <td>
              <a href="http://kod.webrtc.win/#s/6PSLHvig">安装ipk加入</a>
            </td>
          </tr>
          <tr>
            <td>newifi2(新路由二）</td>
            <td>PandoraBox</td>
            <td>
              <a href="http://kod.webrtc.win/#s/6PSLHvig">安装ipk加入</a>
            </td>
          </tr>
          <tr>
            <td>友华WR12000JS</td>
            <td>PandoraBox</td>
            <td>
              <a href="http://kod.webrtc.win/#s/6PSLHvig">安装ipk加入</a>
            </td>
          </tr>
          <tr>
            <td>极路由B70</td>
            <td>PandoraBox</td>
            <td>
              <a href="http://kod.webrtc.win/#s/6PSLHvig">安装ipk加入</a>
            </td>
          </tr>
        </tbody>
      </table>
      <h4>MTK7620系列</h4>
      <table>
        <thead>
          <tr>
            <td>硬件名称</td>
            <td>操作系统</td>
            <td>安装教程</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>优酷路由宝YK-L1</td>
            <td>PandoraBox</td>
            <td>
              <a href="http://kod.webrtc.win/#s/6PSKzTww">安装ipk加入</a>
            </td>
          </tr>
          <tr>
            <td>newifi mini</td>
            <td>PandoraBox</td>
            <td>
              <a href="http://kod.webrtc.win/#s/6PSKzTww">安装ipk加入</a>
            </td>
          </tr>
          <tr>
            <td>newifi y1s(联想云路由）</td>
            <td>PandoraBox</td>
            <td>
              <a href="http://kod.webrtc.win/#s/6PSKzTww">安装ipk加入</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default Hints;
