/*
 * @Author: Tperam
 * @Date: 2021-04-25 10:18:05
 * @LastEditTime: 2022-01-27 16:53:13
 * @LastEditors: Tperam
 * @Description:
 * @FilePath: \openfogos\src\Xhr.js
 */
const initEvent = (event) => ({
  success: () => {},
  error: () => {},
  ...event,
});

const initAction = (action, data) => ({
  start: () => ({
    type: action + "_STARTED",
  }),
  success: (result) => ({
    type: action + "_SUCCESS",
    result,
    data,
  }),
  failure: (error) => ({
    type: action + "_FAILURE",
    error,
  }),
});

const xhr = ({ api, ...param }) => {
  // console.log('xhr:',api, param  );
  const data = api.method === "GET" ? undefined : param.data || undefined;
  const event = initEvent(param.event);
  const action = initAction(param.action, param.data);
  return (dispatch) => {
    dispatch(action.start());
    return fetch(api.url, {
      method: api.method,
      headers: new Headers({
        "X-Pear-Token": localStorage.getItem("openfogos_token"),
        "X-Pear-User": localStorage.getItem("openfogos_userid"),
      }),
      body: data instanceof FormData ? data : JSON.stringify(data),
    })
      .then((response) => {
        response
          .json()
          .then((responseJson) => {
            switch (response.status) {
              case 200:
                dispatch(action.success(responseJson));
                event.success(responseJson);
                break;
              default:
                dispatch(action.failure(responseJson));
                event.error(responseJson);
                break;
            }
          })
          .catch((error) => {
            dispatch(action.failure(error));
            event.error(error);
          });
      })
      .catch((error) => {
        dispatch(action.failure(error));
        event.error(error);
      });
  };
};

export default xhr;
