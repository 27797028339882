import Markdown from "../../modules/markdown_viewer";
import React from "react";
import HomeWindows from "../HomeWindows";

const MdPath = "https://download.openfogos.com/doc/md/";
const adviceMdPath = MdPath + "advice/";
const userMdPath = MdPath + "user/";
const systemMdPath = MdPath + "system/";
const networkMdPath = MdPath + "network/";
const helpMdPath = MdPath + "help/";

export const common_problem = () => (
  <Markdown URL={MdPath + "common_problem.md"} />
);
export const Hints = () => <Markdown URL={MdPath + "hints.md"} />;
export const bandwidth = () => <Markdown URL={adviceMdPath + "bandwidth.md"} />;
export const UPnP = () => <Markdown URL={adviceMdPath + "UPnP.md"} />;
export const bind_node = () => <Markdown URL={userMdPath + "bind_node.md"} />;
export const bind_node_2 = () => (
  <Markdown URL={userMdPath + "bind_node_2.md"} />
);
export const restore_mirror = () => (
  <Markdown URL={systemMdPath + "restore_mirror.md"} />
);
export const restore_mirror_en = () => (
  <Markdown URL={systemMdPath + "restore_mirror_en.md"} />
);
export const restore_mirror_centos = () => (
  <Markdown URL={systemMdPath + "restore_mirror_centos.md"} />
);
export const install_ubuntu_iso = () => (
  <Markdown URL={systemMdPath + "install_ubuntu_iso.md"} />
);
export const install_ubuntu = () => (
  <Markdown URL={systemMdPath + "install_ubuntu.md"} />
);
export const etcher_flash_img = () => (
  <Markdown URL={systemMdPath + "etcher_flash_img.md"} />
);
export const rufus_install_iso = () => (
  <Markdown URL={systemMdPath + "rufus_install_iso.md"} />
);
export const make_system_drive = () => (
  <Markdown URL={systemMdPath + "make_system_drive.md"} />
);
export const extend_ubuntu_img = () => (
  <Markdown URL={systemMdPath + "extend_ubuntu_img.md"} />
);
export const pearos_ubuntu_change_log = () => (
  <Markdown URL={systemMdPath + "pearos_ubuntu_change_log.md"} />
);
export const PCDN = () => <Markdown URL={systemMdPath + "PCDN.md"} />;
export const flush_ax1800pro = () => (
  <Markdown URL={systemMdPath + "flush_ax1800pro.md"} />
);
export const network_config = () => (
  <Markdown URL={networkMdPath + "network_config.md"} />
);
export const network_guide = () => (
  <Markdown URL={networkMdPath + "network_guide.md"} />
);
export const public_ip = () => (
  <Markdown URL={networkMdPath + "public_ip.md"} />
);
export const paid_rule = () => <Markdown URL={helpMdPath + "paid_rule.md"} />;
export const basic_remote = () => (
  <Markdown URL={helpMdPath + "basic_remote.md"} />
);
export const basic_local = () => (
  <Markdown URL={helpMdPath + "basic_local.md"} />
);
export const difference = () => <Markdown URL={helpMdPath + "difference.md"} />;
