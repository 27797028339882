import xhr from "../../Xhr";

const API = (id) => ({
  auth: {
    login: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/login`,
      method: "POST",
    },
    minerInfo: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/dp/platforms`,
      method: "GET",
    },
    bcIDs: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/openfogos/bc`,
      method: "GET",
    },
  },
});

export const login = ({ data, ...event }) => {
  return xhr({
    api: API(localStorage.getItem("openfogos_userid")).auth.login,
    action: "AUTH/LOGIN",
    data,
    event,
  });
};

export const minerGet = () => {
  return xhr({
    api: API(localStorage.getItem("openfogos_userid")).auth.minerInfo,
    action: "AUTH/MINER/INFO",
  });
};

export const bcGet = () => {
  return xhr({
    api: API(localStorage.getItem("openfogos_userid")).auth.bcIDs,
    action: "AUTH/BC/IDS",
  });
};

export const loginQuery = (data) => ({
  type: "AUTH/LOGIN",
  data,
});

export const logout = () => ({
  type: "AUTH/LOGOUT",
});
