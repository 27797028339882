import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ERROR from "../../../Error";
import Card from "@material-ui/core/Card";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { convertGoods, fetchInfo } from "../../../modules/user/actions";
import { messageError, messageOk } from "../../../modules/utils/actions";

const styles = (theme) => ({
  gap: {
    height: theme.spacing(2),
  },
  submitButton: {
    // width: '100%',
    marginTop: theme.spacing(2),
  },
  link: {
    color: "#2196f3",
  },
});

class Goods2 extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.price = 7000;
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.state = {
      address: "",
      name: "",
      mobile: "",
      confirmVisible: false,
    };
  }

  showConfirm() {
    this.setState({ confirmVisible: true });
  }
  hideConfirm() {
    this.setState({ confirmVisible: false });
  }
  onSubmit() {
    this.hideConfirm();
    this.props.onSubmit(
      this.state.address + "|" + this.state.name + "|" + this.state.mobile,
    );
    this.props.history.push({ pathname: "/home/index" });
  }
  handleChange(event) {
    switch (event.target.name) {
      case "address":
        this.setState({ ...this.state, address: event.target.value });
        break;
      case "name":
        this.setState({ ...this.state, name: event.target.value });
        break;
      case "mobile":
        this.setState({ ...this.state, mobile: event.target.value });
        break;
      default:
        break;
    }
  }
  render() {
    const { classes } = this.props;
    const buyButtonParam = {
      onClick: this.showConfirm,
      disabled: this.props.balance < this.price,
    };
    return (
      <div>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              newifi 3
            </Typography>
            <Typography>1. 已刷好PandoraBox 自带OpenFogOS插件。</Typography>
            <Typography>
              2. 如需直接现金购买，请到官方指定
              <a
                target="_blank"
                className={classes.link}
                href="https://market.m.taobao.com/app/idleFish-F2e/widle-taobao-rax/page-detail?id=586912803774"
              >
                店铺
              </a>
              。
            </Typography>
            <Typography>3. 兑换之后会在一个工作日内发货。</Typography>
            <Typography>
              4. 请确认收货信息填写无误，一旦提交无法修改。
            </Typography>
            <Typography>5. 不支持退货以及换货。</Typography>
          </CardContent>
        </Card>
        <div className={classes.gap}></div>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              兑换
            </Typography>
            <form>
              <Typography>地址</Typography>
              <Input name="address" onChange={this.handleChange} />
              <Typography>收件人</Typography>
              <Input name="name" onChange={this.handleChange} />
              <Typography>手机号</Typography>
              <Input name="mobile" onChange={this.handleChange} />
              <br />
              <Tooltip
                title={this.props.balance < this.price ? "余额不足" : ""}
              >
                <Button
                  className={classes.submitButton}
                  color="primary"
                  variant="contained"
                  {...buyButtonParam}
                >
                  确定兑换
                </Button>
              </Tooltip>
            </form>
          </CardContent>
        </Card>

        <Button color="primary" variant="contained" style={{ marginTop: 10 }}>
          <Link to="/home/index">返回</Link>
        </Button>

        <Dialog open={this.state.confirmVisible}>
          <DialogTitle>确定兑换</DialogTitle>
          <DialogContent>
            <Typography>
              消耗 {this.price / 100} PRC 购买一台newifi？
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideConfirm} color="primary">
              取消
            </Button>
            <Button onClick={this.onSubmit} color="primary" autoFocus>
              确定
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  componentDidMount() {
    this.props.onRequire();
  }
}

const mapStateToProps = (state) => {
  return {
    phone: state.user.info.phone,
    balance: state.user.info.balance,
    infoLoading: state.user.infoStatus === "loading" ? true : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(fetchInfo({}));
    },
    onSubmit: (qqNumber) => {
      console.log("submit qq", qqNumber);
      dispatch(
        convertGoods({
          data: {
            goods_no: "2",
            data: qqNumber,
          },
          success: () => {
            dispatch(messageOk("兑换成功！"));
          },
          error: (error) => {
            dispatch(messageError(ERROR[error.errorcode]));
          },
        }),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Goods2));
