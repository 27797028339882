import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Blue from "@material-ui/core/colors/blue";
import Divider from "@material-ui/core/Divider";
import Contact from "../common/contact";

const styles = (theme) => ({
  card: {
    minWidth: 275,
    maxWidth: 1000,
    marginLeft: "auto",
    marginRight: "auto",
  },
  gap: {
    height: theme.spacing(2),
  },
  tableCell: {
    paddingRight: "10px",
    // paddingLeft: '0px',
    // paddingTop: '5px',
    // paddingBottom: '0px',
    "& a": {
      color: Blue[500],
    },
  },
  top1: {
    color: "#e40015",
    fontWeight: "bold",
  },
  top2: {
    color: "#eb8b00",
    fontWeight: "bold",
  },
  top3: {
    color: "#efdb00",
    fontWeight: "bold",
  },
  statistics: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  aLink: {
    color: Blue[500],
  },
  headerBackground: {
    position: "absolute",
    // top: -theme.spacing( 2),
    left: 0,
    width: "100%",
    height: "55vh",
    background: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0)), url(/largeRecruitBG.png) `,
    backgroundPosition: "center bottom",
    backgroundSize: "cover",
    // zIndex: -200,
  },
  header: {
    height: "55vh",
    padding: "13vh 0 16vh",
    boxSizing: "border-box",
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  main: {
    marginTop: theme.spacing(2),
    backgroundColor: "#fff",
    "& h6": {
      paddingTop: theme.spacing(2),
    },
    "& section": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  main2: {
    marginTop: theme.spacing(4),
    "& h6": {
      paddingTop: theme.spacing(2),
    },
    "& section": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  table: {
    backgroundColor: "#fff",
  },
  bigTitle: {
    fontSize: "4.25em",
    color: "#fff",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3em",
    },
  },
  subTitle: {
    fontSize: "1.5em",
    color: "#e1c052",
    fontWeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(6),
  },
  mainButtonContainer: {
    display: "block",
  },
  mainButton: {
    // border: '1px solid #fff',
    fontSize: "1.5em",
    padding: "18px 40px",
    backgroundColor: "#ff6a00",
    color: "#fff",
    boxSizing: "border-box",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    borderRadius: "8px",
    "&:hover": {
      background: "#ef6900",
    },
  },
  step: {
    backgroundPosition: "center center",
    backgroundSize: "100%",
    display: "block",
    width: "70px",
    height: "70px",
    margin: "10px auto 30px",
    filter: "hue-rotate(211.5deg) saturate(53.5%) brightness(137%)",
    // filter: "hue-rotate(177deg) saturate(200%) brightness(137%)",
    // filter: "hue-rotate(204deg) saturate(47%) brightness(142%)"
  },
  step1: {
    backgroundImage: `url(/largeRecruit/step-1.png)`,
  },
  step2: {
    width: "65px",
    height: "65px",
    margin: "15px auto 30px",
    backgroundImage: `url(/largeRecruit/step-2.png)`,
  },
  step3: {
    backgroundImage: `url(/largeRecruit/step-3.png)`,
  },
  step4: {
    backgroundImage: `url(/largeRecruit/step-4.png)`,
  },
  step5: {
    backgroundImage: `url(/largeRecruit/step-5.png)`,
  },
  stepArrow: {
    display: "block",
    position: "absolute",
    backgroundSize: "100%",
    backgroundImage: `url(/largeRecruit/arrow.jpg)`,
    top: "22px",
    right: "-80px",
    width: "110px",
    height: "20px",
    filter: "hue-rotate(211.5deg) saturate(53.5%) brightness(137%)",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    // filter: "hue-rotate(177deg) saturate(200%) brightness(137%)",
  },
  stepContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    padding: "0",

    "& li": {
      listStyle: "none",
      width: "150px",
      position: "relative",
    },
  },
  tableHead: {
    backgroundColor: "#ccc",
    "& th": {
      color: "#111",
      fontSize: "1rem",
    },
  },
});

function getSteps() {
  return [
    "提交机器信息",
    "验证和机器部署",
    "安装资源共享程序",
    "验收和分发资源测试",
    "正式共享资源",
  ];
}

// 硬盘 <a href="https://item.taobao.com/item.htm?id=613751308949" target="_blank" rel="noopener noreferrer">120G</a>
// 硬盘 T <a href="https://item.taobao.com/item.htm?id=631246955009" target="_blank" rel="noopener noreferrer">
// 内存 <a href="https://item.taobao.com/item.htm?id=613800018326" target="_blank" rel="noopener noreferrer">
function getTableContent0() {
  return [
    [
      "8线程",
      "10G",
      "120G SSD",
      "2T SSD",
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "-",
      "每1Gbps",
    ],
  ];
}
function getTableContent1() {
  return [
    [
      "8线程",
      "10G",
      "120G SSD",
      <>
        2T SSD
        <br />
        <span style={{ color: "#999" }}>3*4T HDD</span>
      </>,
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "静态IP",
      "每1Gbps",
    ],
  ];
}

function getTableContent2() {
  return [
    [
      "16线程",
      "16G",
      "120G SSD",
      "2T SSD",
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "静态IP",
      "1Gbps~2Gbps",
    ],
    [
      "20线程",
      "20G",
      "120G SSD",
      "2*2T SSD",
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "静态IP",
      "2Gbps~3Gbps",
    ],
    [
      "24线程",
      "24G",
      "120G SSD",
      ["3*2T SSD"],
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "静态IP",
      "3Gbps-4Gbps",
    ],
    [
      "28线程",
      "28G",
      "120G SSD",
      ["3*2T PCIe"],
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "静态IP",
      "4Gbps-5Gbps",
    ],
    [
      "32线程",
      "32G",
      "120G SSD",
      ["2*4T PCIe"],
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "静态IP",
      "5Gbps-6Gbps",
    ],
    [
      "64线程",
      "64G",
      "120G SSD",
      ["4*4T PCIe"],
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "静态IP",
      "10Gbps",
    ],
  ];
}

function getTableContent3() {
  return [
    [
      "12线程",
      "16G",
      "120G SSD",
      "2T SSD",
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "10*100M",
      "1G",
    ],
    [
      "20线程",
      "32G",
      "120G SSD",
      ["2*", "2T SSD"],
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "20*100M",
      "2G",
    ],
    [
      "104线程",
      "128G",
      "120G SSD",
      ["5*", "2T SSD"],
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "50*100M",
      "5G",
    ],
    [
      "104线程",
      "128G",
      "120G SSD",
      ["10*", "2T SSD"],
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "100*100M",
      "10G",
    ],
    [
      "52线程",
      "128G",
      "120G SSD",
      ["10*", "2T SSD"],
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "50*200M",
      "10G",
    ],
    [
      "40线程",
      " 128G",
      "120G SSD",
      ["10*", "2T SSD"],
      <a href="/home/doc/make_system_drive">PearOS</a>,
      "不开80",
      "20*500M",
      "10G",
    ],
  ];
}

function Site(props) {
  const suggestionRef = useRef();
  const scrollToElement = () => {
    let hashValue = window.location.hash;
    hashValue = hashValue.substr(1);
    if (hashValue == "suggestion") {
      const { current } = suggestionRef;
      if (current !== null) {
        current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  useEffect(() => {
    props.onRequire();
    scrollToElement();
  }, []);
  const steps = getSteps();
  const tableContent0 = getTableContent0();
  const tableContent1 = getTableContent1();
  const tableContent2 = getTableContent2();
  const tableContent3 = getTableContent3();
  const { classes, isAuthenticated } = props;
  return (
    <div>
      <div className={classes.headerBackground}></div>
      <header className={classes.header}>
        <Typography variant="h5" align="center" className={classes.bigTitle}>
          CDN节点招募
        </Typography>
        {/* <Typography variant="body1" align='center'  className={classes.subTitle}>
                    业务稳定&emsp;收益可观&emsp;资源合法&emsp;提现方便
                </Typography> */}
        {isAuthenticated ? (
          <a
            target="_self"
            rel="noopener noreferrer"
            href="/home/submit_node"
            className={classes.mainButton}
          >
            提交节点
          </a>
        ) : (
          <a
            target="_self"
            rel="noopener noreferrer"
            href={
              process.env.REACT_APP_ACCOUNT_SERVER +
              "/login?" +
              encodeURIComponent(
                `source=${document.location.host}&title=OpenFogOS`,
              )
            }
            className={classes.mainButton}
          >
            立即接入
          </a>
        )}
      </header>

      <main className={classes.main}>
        <section style={{ overflow: "auto" }}>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            className={classes.sectionTitle}
          >
            接入流程
          </Typography>
          {/*<Stepper activeStep={0} alternativeLabel>
                        {steps.map(label => (
                            <Step key={label} active={true}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>*/}
          <ul className={classes.stepContainer}>
            <li>
              <a
                className={"LinkCard"}
                target="_self"
                href={
                  isAuthenticated
                    ? "/home/submit_node"
                    : process.env.REACT_APP_ACCOUNT_SERVER +
                      "/login?" +
                      encodeURIComponent(
                        `source=${document.location.host}&title=OpenFogOS`,
                      )
                }
              >
                <span className={`${classes.step} ${classes.step1}`} />
                <Typography variant="body1" align="center" gutterBottom>
                  Step 1
                </Typography>
                <Typography variant="body1" align="center">
                  {steps[0]}
                </Typography>
                <span className={classes.stepArrow} />
              </a>
            </li>
            <li>
              <span className={`${classes.step} ${classes.step2}`} />
              <Typography variant="body1" align="center" gutterBottom>
                Step 2
              </Typography>
              <Typography variant="body1" align="center">
                {steps[1]}
              </Typography>
              <span className={classes.stepArrow} />
            </li>
            <li>
              <span className={`${classes.step} ${classes.step3}`} />
              <Typography variant="body1" align="center" gutterBottom>
                Step 3
              </Typography>
              <Typography variant="body1" align="center">
                {steps[2]}
              </Typography>
              <span className={classes.stepArrow} />
            </li>
            <li>
              <span className={`${classes.step} ${classes.step4}`} />
              <Typography variant="body1" align="center" gutterBottom>
                Step 4
              </Typography>
              <Typography variant="body1" align="center">
                {steps[3]}
              </Typography>
              <span className={classes.stepArrow} />
            </li>
            <li>
              <span className={`${classes.step} ${classes.step5}`} />
              <Typography variant="body1" align="center" gutterBottom>
                Step 5
              </Typography>
              <Typography variant="body1" align="center">
                {steps[4]}
              </Typography>
            </li>
          </ul>
        </section>

        <Divider />
      </main>

      <main className={classes.main2}>
        <section style={{ overflow: "auto" }}>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            className={classes.sectionTitle}
            id="suggestion"
            name="suggestion"
            ref={suggestionRef}
          >
            建议配置
          </Typography>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCell} align="center">
                  链路类型
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  CPU
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  内存
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  系统盘
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  数据盘
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  系统
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  端口
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  IP
                </TableCell>
                {/* <TableCell className={classes.tableCell} align="center">网卡</TableCell> */}
                <TableCell className={classes.tableCell} align="center">
                  带宽
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableContent0.map((row, index) => (
                <TableRow key={index}>
                  {index === 0 ? (
                    <TableCell
                      className={classes.tableCell}
                      align="center"
                      rowSpan={tableContent0.length}
                    >
                      <Typography align="center">
                        <span style={{ whiteSpace: "nowrap" }}>
                          通用模型
                          <br />
                          (汇聚)
                        </span>
                      </Typography>
                    </TableCell>
                  ) : null}
                  {row.map((value, index) => (
                    <TableCell
                      key={index}
                      className={classes.tableCell}
                      align="center"
                    >
                      <Typography align="center">
                        <span
                          style={index === 4 ? null : { whiteSpace: "nowrap" }}
                        >
                          {value}
                        </span>
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {tableContent1.map((row, index) => (
                <TableRow key={index}>
                  {index === 0 ? (
                    <TableCell
                      className={classes.tableCell}
                      align="center"
                      rowSpan={tableContent1.length}
                    >
                      <Typography align="center">
                        <span style={{ whiteSpace: "nowrap" }}>
                          通用模型
                          <br />
                          (专线)
                        </span>
                      </Typography>
                    </TableCell>
                  ) : null}
                  {row.map((value, index) => (
                    <TableCell
                      key={index}
                      className={classes.tableCell}
                      align="center"
                    >
                      <Typography align="center">
                        <span
                          style={index === 4 ? null : { whiteSpace: "nowrap" }}
                        >
                          {value}
                        </span>
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {/* {(tableContent2).map((row, index) => (
                                <TableRow key={index}>
                                    {index === 0 ? <TableCell className={classes.tableCell} align="center"
                                                                rowSpan={tableContent2.length}>
                                        <Typography align="center"><span
                                            style={{whiteSpace: 'nowrap'}}>专线宽带</span></Typography>
                                    </TableCell> : null}
                                    {row.map((value, index) => (
                                        <TableCell key={index} className={classes.tableCell} align="center">
                                            <Typography align="center"><span
                                                style={index === 4 ? null : {whiteSpace: 'nowrap'}}>{value}</span></Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            {(tableContent3).map((row, index) => (
                                <TableRow key={index}>
                                    {index === 0 ? <TableCell className={classes.tableCell} align="center"
                                                                rowSpan={tableContent3.length}>
                                        <Typography align="center"><span
                                            style={{whiteSpace: 'nowrap'}}>汇聚宽带</span></Typography>
                                    </TableCell> : null}
                                    {row.map((value, index) => (
                                        <TableCell key={index} className={classes.tableCell} align="center">
                                            <Typography align="center"><span
                                                style={index === 4 ? null : {whiteSpace: 'nowrap'}}>{value}</span></Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))} */}
            </TableBody>
          </Table>
          <Typography variant={"subtitle1"}>
            CPU：专线要求架构为Xeon E5 V3或以上，建议为Xeon
            Scalable金牌或铂金；汇聚为Xeon E5 V3或以上 <br />
            个别专线类业务需加HDD，以本公司商务/客服/运维人员的沟通为准 <br />
            若不确定具体情况，也可直接通过以下方式联系：
            <ul>
              <li>微信：Cheedoong</li>
              <li>QQ群：715017630</li>
            </ul>
          </Typography>
        </section>
      </main>
      <Contact />
    </div>
  );
}

Site.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.utils.rewardStatisticsStatus,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {},
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Site),
);
