import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles/index";
import PropTypes from "prop-types";
import { messageError, messageOk } from "../../../modules/utils/actions";
import { connect } from "react-redux";
import { reducerStatus } from "../../../modules/userMine/reducer";
import { modifyUserMine } from "../../../modules/userMine/actions";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import UploadIcon from "@material-ui/icons/CloudUploadOutlined";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from "react-router-dom";
import DownloadIcon from "@material-ui/icons/SaveAlt";

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing( 2),
  },
  button: {
    width: "100%",
    size: "medium",
  },
  input: {
    width: "300px",
    margin: theme.spacing(1),
  },
  inputDiv: {
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "300px",
    margin: theme.spacing(1),

    "& span": {
      marginLeft: theme.spacing(1),
      textAlign: "left",
      flexGrow: 1,
      flexShrink: 1,
      overFlow: "visible",
      whiteSpace: "nowrap",
    },
  },
  leftIcon: {
    marginLeft: -theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
  form: {
    textAlign: "center",
  },
  tooltip: {
    background: "none",
    maxWidth: "none",
  },
});

// const certificationStatusCaption = {
//     0: "提交实名认证",
//     1: "实名认证通过",
//     2: "实名认证审核中",
//     3: "实名认证失败，请重新提交",
// };

const selectDataGroup1 = [
  {
    name: "isp",
    title: "运营商",
    values: ["电信", "联通", "移动"],
  },
  {
    name: "link",
    title: "链路类型",
    values: ["专线", "汇聚线路"],
  },
  /*{
        name: "bandwidth",
        title: "可用带宽",
        values: [
            "500M",
            "1G-10G"
        ]
    },*/
];
const selectDataGroup2 = [
  {
    name: "cpu",
    title: "CPU",
    values: [
      "8线程",
      "16线程",
      "24线程",
      "32线程",
      "40线程",
      "64线程",
      "80线程",
      "96线程",
      "104线程及以上",
    ],
  },
  {
    name: "memory",
    title: "内存",
    values: ["8G", "16G", "32G", "64G", "128G", "256G及以上"],
  },
  {
    name: "storage_type",
    title: "磁盘类型",
    values: ["HDD", "SATA SSD", "PCIe SSD（含NVMe, M.2, U.2）"],
  },
];

const selectDataGroup3 = [
  {
    name: "system",
    title: "系统类型",
    values: [
      "OpenFogOS（基于Ubuntu）（默认请选此）",
      "OpenFogOS（基于CentOS）",
      "官方Ubuntu22.04 LTS ",
      "官方Ubuntu20.04 LTS ",
      "官方Ubuntu18.04 LTS ",
      "官方CentOS 8",
      "官方CentOS 7",
    ],
  },
];

// const requiredNames = ["region", "isp", "link", "access_type", "bandwidth", "cpu", "memory", "storage_type", "storage", "system", "ssh", "username", "password", "contact_info"];
const requiredNames = [
  "region",
  "isp",
  "link",
  "bandwidth",
  "cpu",
  "memory",
  "storage_type",
  "storage",
  "system",
  "username",
  "password",
  "contact_info",
  "asset_owner",
  "asset_type",
];
const optionalNames = ["ssh", "limited_area"];

class Certification extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onTextChange = this.onTextChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.checkForm = this.checkForm.bind(this);
    this.jumpToManage = this.jumpToManage.bind(this);
    this.state = {
      formData: {
        limtied_area: -1,
        ...(props.nodeInfo || {}),
        asset_type: "无",
      },
      id: (props.nodeInfo || {}).id || 0,
      jump: "",
    };
  }

  onTextChange(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value,
      },
    });
  }

  onFileChange(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.files[0],
        [event.target.name + "_value"]: event.target.value,
      },
    });
  }

  jumpToManage() {
    this.setState({
      jump: "/home/my_mine_nodes",
    });
  }

  checkForm() {
    let formData = this.state.formData;
    let result = true;
    requiredNames.forEach((name) => {
      if (!formData[name]) {
        console.log("checkForm false", name, formData[name]);
        result = false;
      }
    });
    return result;
  }

  render() {
    const { classes } = this.props;
    const textFieldProps = {
      className: classes.input,
      onChange: this.onTextChange,
    };
    const canSubmit = this.checkForm();

    const generateSelect = (data) => (
      <div key={data.name}>
        <FormControl className={classes.input} required>
          <InputLabel htmlFor={`submit-node-${data.name}`}>
            {data.title}
          </InputLabel>
          <Select
            value={this.state.formData[data.name] || ""}
            onChange={this.onTextChange}
            inputProps={{
              name: data.name,
              id: `submit-node-${data.name}`,
            }}
          >
            {data.values.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
      </div>
    );
    if (this.state.jump) {
      return <Redirect to={this.state.jump} />;
    }

    return (
      <form className={classes.form}>
        <TextField
          {...textFieldProps}
          required
          label="地区"
          name="region"
          placeholder="直辖市或者省-市"
          value={this.state.formData.region || ""}
        />
        <br />
        {selectDataGroup1.map((data) => generateSelect(data))}
        {/* <FormControl component="fieldset" className={classes.input} required>
                <FormLabel component="legend">物理接入方式（序号越小，利用率越高）</FormLabel>
                <RadioGroup row aria-label="access_type" name="access_type" value={`${this.state.formData.access_type||""}`} onChange={this.onTextChange}>
                    {[1,2,3,4,5,6,7,8].map(i=>(
                      <FormControlLabel value={`${i}`} control={<Tooltip interactive classes={{tooltip: classes.tooltip}} title={<img src={`/accessType/type_${i}.png`} alt={`type_${i}`}/>} aria-label={`type_${i}`}><Radio /></Tooltip>} label={i} key={i} />
                    ))}
                </RadioGroup>
            </FormControl> */}
        <FormControl className={classes.input} required>
          <InputLabel htmlFor={`submit-node-limited_area`}>调度范围</InputLabel>
          <Select
            value={
              !this.state.formData["limited_area"] &&
              this.state.formData["limited_area"] != 0
                ? -1
                : this.state.formData["limited_area"]
            }
            // value={!form["limited_area"] && form["limited_area"]!=0? -1 : form["limited_area"]}
            onChange={this.onTextChange}
            inputProps={{
              name: "limited_area",
              id: `submit-node-limited_area`,
            }}
          >
            <MenuItem value={-1}>不设置</MenuItem>
            <MenuItem value={0}>全国覆盖</MenuItem>
            <MenuItem value={1}>本省覆盖</MenuItem>
            <MenuItem value={2}>大区覆盖</MenuItem>
          </Select>
        </FormControl>
        <br />
        <TextField
          {...textFieldProps}
          required
          label="可用上行带宽"
          name="bandwidth"
          placeholder="线路数*单路带宽，如500M、1G、10G"
          value={this.state.formData.bandwidth || ""}
        />
        {selectDataGroup2.map((data) => generateSelect(data))}
        <TextField
          {...textFieldProps}
          required
          label="磁盘容量"
          name="storage"
          placeholder="个数及单盘容量，例如10*2T"
          value={this.state.formData.storage || ""}
        />
        <br />
        {selectDataGroup3.map((data) => generateSelect(data))}
        <TextField
          {...textFieldProps}
          label="IP地址（可不填）"
          name="ssh"
          placeholder="IP或域名或向日葵等连接方式"
          value={this.state.formData.ssh || ""}
        />
        <br />
        <TextField
          {...textFieldProps}
          required
          label="登录用户"
          name="username"
          placeholder="如使用Pear公司系统，则默认为pear"
          value={this.state.formData.username || ""}
        />
        <br />
        <TextField
          {...textFieldProps}
          required
          label="登录密码"
          name="password"
          placeholder="如使用Pear公司系统，则默认为Pear@123456"
          value={this.state.formData.password || ""}
        />
        <br />
        <TextField
          {...textFieldProps}
          required
          label="联系方式"
          name="contact_info"
          placeholder="电话或QQ或微信，确保官方客服能访问到您"
          value={this.state.formData.contact_info || ""}
        />
        <br />
        <FormControl required className={classes.input}>
          <InputLabel htmlFor={`asset_owner`}>机器归属</InputLabel>
          <Select
            value={this.state.formData["asset_owner"] || ""}
            onChange={this.onTextChange}
            inputProps={{
              name: "asset_owner",
              id: "asset_owner",
            }}
          >
            <MenuItem value={"从 Pear 租借"}>从 Pear 租借</MenuItem>
            <MenuItem value={"从 Pear 购置"}>从 Pear 购置</MenuItem>
            <MenuItem value={"其它"}>其它</MenuItem>
          </Select>
        </FormControl>
        <br />
        {this.state.formData["asset_owner"] === "从 Pear 租借" ||
        this.state.formData["asset_owner"] === "从 Pear 购置" ? (
          <>
            <FormControl className={classes.input} required>
              <InputLabel htmlFor={`asset_type`}>机器类型</InputLabel>
              <Select
                value={this.state.formData["asset_type"] || ""}
                onChange={this.onTextChange}
                inputProps={{
                  name: "asset_type",
                  id: "asset_type",
                }}
              >
                <MenuItem value={"PearBox 1"}>PearBox 1</MenuItem>
                <MenuItem value={"Pear MiniPC"}>Pear MiniPC</MenuItem>
                <MenuItem value={"Server-1-Socket"}>Server-1-Socket</MenuItem>
                <MenuItem value={"Server-2-Socket"}>Server-2-Socket</MenuItem>
              </Select>
            </FormControl>
            <br />
          </>
        ) : null}
        <div className={classes.inputDiv}>
          <label htmlFor="file_input">
            <Button color="default" variant={"outlined"} component="span">
              <UploadIcon className={classes.leftIcon} />
              重新上传汇聚带宽的拨号账号和密码
            </Button>
          </label>
        </div>
        <br />
        <a
          rel="noreferrer"
          href="https://download.openfogos.com/docs/net-template.xlsx"
          target="_blank"
        >
          <Button color="primary" variant={"text"}>
            <DownloadIcon className={classes.leftIcon} />
            模板
          </Button>
        </a>
        <a
          rel="noreferrer"
          href="https://download.openfogos.com/docs/net-template-demo.xlsx"
          target="_blank"
        >
          <Button color="primary" variant={"text"}>
            <DownloadIcon className={classes.leftIcon} />
            样例
          </Button>
        </a>
        <br />
        <span>{this.state.formData.file_value || "未选择文件"}</span>
        <input
          id="file_input"
          name="file"
          {...textFieldProps}
          type="file"
          style={{ display: "none" }}
          onChange={this.onFileChange}
        />

        <br />
        <Button
          className={classes.input}
          color="primary"
          variant={"contained"}
          component="span"
          disabled={!canSubmit}
          onClick={() =>
            this.props.onSubmit(
              this.state.id,
              this.state.formData,
              this.jumpToManage,
            )
          }
        >
          提交
        </Button>
      </form>
    );
  }
}

Certification.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    certificationStatusStatus:
      state.user.certificationStatusStatus || reducerStatus.LOADING,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
    onSubmit: (id, data, jumpToManage) => {
      data["ssh"] = data["ssh"] || "";
      var formData = new FormData();
      requiredNames.forEach((name) => {
        if (name === "asset_type") {
          if (data["asset_owner"] === "其它") {
            data[name] = "";
          }
        }
        formData.append(name, data[name]);
      });
      optionalNames.forEach((name) => {
        formData.append(name, data[name]);
      });
      if (data.file) {
        formData.append("file", data.file);
      }
      // console.log(data)
      // console.log(formData)
      // window.D = formData;
      dispatch(
        modifyUserMine({
          id: id,
          data: formData,
          success: () => {
            dispatch(messageOk("提交成功"));
            jumpToManage();
          },
          error: () => {
            dispatch(messageError("提交失败"));
          },
        }),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Certification));
