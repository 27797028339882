import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";

// import classNames from 'classnames';

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class BindForm extends Component {
  render() {
    const handleChange = (event) => {
      this.props.onChange({
        [event.target.name]: event.target.value,
      });
      console.log("handleChange", event.target);
    };

    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            远程绑定节点
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="mac">节点MAC地址</InputLabel>
              <Input
                id="mac"
                name="mac"
                autoComplete="mac"
                autoFocus
                value={this.props.mac || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="pearid">节点NodeID</InputLabel>
              <Input
                name="pearid"
                type="text"
                id="pearid"
                value={this.props.pearid || ""}
                onChange={handleChange}
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.props.onSubmit}
              className={classes.submit}
            >
              绑定
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(BindForm);
