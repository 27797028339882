import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import {
  deleteMac,
  fetchInfo,
  fetchTotal,
  searchMac,
  setLimit,
  setNickname,
} from "../../../modules/node/actions";
import copy from "copy-to-clipboard";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Edit from "@material-ui/icons/Edit";
import SmileIcon from "@material-ui/icons/SentimentVerySatisfied";
import SadIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import IconList from "@material-ui/icons/List";
import IconCircleOutline from "@material-ui/icons/AddCircleOutline";
import IconCheck from "@material-ui/icons/Check";
import IconBack from "@material-ui/icons/ArrowBack";
import {
  confirm,
  messageError,
  messageOk,
} from "../../../modules/utils/actions";
import classNames from "classnames";
import red from "@material-ui/core/colors/red";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Link } from "react-router-dom";
import Blue from "@material-ui/core/colors/blue";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import IconClose from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import IconNetwork from "@material-ui/icons/SwapVert";
import IconMemory from "@material-ui/icons/Memory";
import IconStorage from "@material-ui/icons/SaveOutlined";
import MenuItem from "@material-ui/core/MenuItem";
//const Search = Input.Search

const styles = (theme) => ({
  root: {
    // overflowX: 'auto',
  },
  table: {
    overflowX: "auto",
  },
  tableCell: {
    paddingRight: "20px",
    paddingLeft: "0px",
    paddingTop: "5px",
    paddingBottom: "0px",
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
  power: {
    // color: '#a68540',
    // color: green['A700']
  },
  mac: {
    // color: '#898989',
    marginTop: "4px",
  },
  idealReward: {
    color: red[500],
  },
  idealRewardButton: {
    cursor: "pointer",
    border: "1px solid",
    padding: "5px",
    borderRadius: "3px",
  },
  idealRewardButtonDisabled: {
    // cursor: 'pointer',
    pointerEvents: "none",
    border: "1px solid",
    padding: "5px",
    borderRadius: "3px",
  },
  aLink: {
    color: Blue[500],
  },
  dialogTitle: {
    paddingLeft: 0,
    paddingBottom: 0,
    paddingTop: theme.spacing(3),
  },
  dialogContent: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  bottomButton: {
    marginTop: theme.spacing(3),
  },
  closeButton: {
    marginTop: theme.spacing(3),
  },
  absoluteBottom: {
    position: "absolute",
    bottom: theme.spacing(2),
    paddingRight: theme.spacing(6),
    width: "100%",
  },
  sliderItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  slider: {
    marginTop: theme.spacing(2),
  },
  setModeButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
});

class NodeList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      current: 1,
      nickname: "",
      nicknameMac: "",
      rowsPerPage: 5,
      page: 0,
      nodeDetailOpen: false,
      nodeDetailIndex: 0,
      adviceOpen: false,
      advice: "",
      nodeLimit: {},
      timer: 0,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.nicknameOnchange = this.nicknameOnchange.bind(this);
    this.setNickname = this.setNickname.bind(this);
    this.openSetNickname = this.openSetNickname.bind(this);
    this.hideSetNickname = this.hideSetNickname.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.openNodeDetail = this.openNodeDetail.bind(this);
    this.closeNodeDetail = this.closeNodeDetail.bind(this);
    this.openAdvice = this.openAdvice.bind(this);
    this.closeAdvice = this.closeAdvice.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.handleSetMode = this.handleSetMode.bind(this);
    this.setLimit = this.setLimit.bind(this);
    this.setLimitNow = this.setLimitNow.bind(this);
  }
  onSearch(value) {
    if (value.indexOf("，") > 0) {
      value = value.replace(/，/g, ",");
    }
    this.props.onSearch(value);
  }
  handleChangePage(event, newPage) {
    this.setState({
      ...this.state,
      page: newPage,
    });
    this.props.onRequire(newPage + 1, this.state.rowsPerPage);
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      ...this.state,
      rowsPerPage: event.target.value,
    });
    this.props.onRequire(this.state.page + 1, event.target.value);
  }
  handleTableChange(pagination, filters, sorter) {
    if (this.state.current !== pagination.current) {
      this.setState({
        ...this.state,
        current: pagination.current,
      });
    }
  }
  copyText(text) {
    copy(text);
    this.props.messageOk("复制成功");
  }
  nicknameOnchange(event) {
    this.setState({
      ...this.state,
      nickname: event.target.value,
    });
  }
  onKeyUp(event) {
    if (event.keyCode === 13) {
      console.log("press enter!");
      this.setNickname();
    }
  }
  onBlur() {
    console.log("onBlur");
    this.setNickname();
  }
  setNickname() {
    this.props.setNickname(this.state.nicknameMac, this.state.nickname);
    // this.hideSetNickname();
    this.hideSetNickname();
    // this.props.onRequire(this.state.page + 1, this.state.rowsPerPage);
  }

  openSetNickname(mac, nickname) {
    this.setState({
      ...this.state,
      nickname: nickname,
      nicknameMac: mac,
    });
  }
  hideSetNickname() {
    this.setState({
      ...this.state,
      nickname: "",
      nicknameMac: "",
    });
  }
  openNodeDetail = (index, item) => () => {
    this.setState({
      nodeDetailIndex: index,
      nodeDetailOpen: true,
      nodeLimit: {
        mac_addr: item.mac_addr,
        storage: item.limit_storage || 0,
        bandwidth: item.limit_bandwidth || 0,
        memory: item.limit_memory || 0,
        cpu: item.limit_cpu || 0,
      },
    });
  };
  closeNodeDetail = () => {
    this.setState({
      nodeDetailOpen: false,
    });
  };
  handleSliderChange = (name) => (event, value) => {
    console.log("target", event.target);
    this.setState({
      nodeLimit: {
        ...this.state.nodeLimit,
        [name]: value,
      },
    });
    this.setLimit();
  };
  handleTextFieldChange = (name) => (event) => {
    this.setState({
      nodeLimit: {
        ...this.state.nodeLimit,
        [name]: event.target.value,
      },
    });
    this.setLimit();
  };

  handleSetMode = (percent) => () => {
    this.setState({
      nodeLimit: {
        ...this.state.nodeLimit,
        storage: percent,
        bandwidth: percent,
        memory: percent,
      },
    });
    this.setLimit();
  };

  setLimit = () => {
    // window.clearTimeout(this.state.timer);
    // this.setState({
    //     timer: window.setTimeout(()=>this.props.setLimit(this.state.nodeLimit, this.state.page + 1, this.state.rowsPerPage),1000)
    // });
  };

  setLimitNow = () => {
    this.props.setLimit(
      this.state.nodeLimit,
      this.state.page + 1,
      this.state.rowsPerPage,
    );
  };
  openAdvice = (text) => () => {
    this.setState({
      adviceOpen: true,
      advice: text,
    });
  };
  closeAdvice = () => {
    this.setState({
      adviceOpen: false,
      advice: "",
    });
  };

  render() {
    const { classes } = this.props;
    const natTypes = {
      FullCone: "全锥型",
      "Open-FullCone": "全锥型",
      "Open-PortRestricted": "端口限制型",
      "Open-RestricNat": "限制型",
      "Open-RestricPortNat": "端口限制型",
      "Open-Restricted": "限制型",
      "Open-Symmetric": "对称型",
      PortRestricted: "端口限制型",
      RestricNat: "限制型",
      RestricPortNat: "端口限制型",
      Restricted: "限制型",
      Symmetric: "对称型",
      SymmetricNat: "对称型",
    };
    const approvedStatus = {
      0: "验收中",
      1: "试跑中",
      2: "压测中",
      3: "验收通过",
      4: "验收不通过",
    };
    const approvedStatusInfo = {
      0: "约1~2天内启动验收",
      1: "开始小规模切量测试",
      2: "测试最大切量，约2~14天完成",
      3: "",
      4: "",
    };
    const sortedList = this.props.list.sort(
      (a, b) => (b.reward_yesterday || 0) - (a.reward_yesterday || 0),
    );
    const modeIndex =
      this.state.nodeLimit.storage === this.state.nodeLimit.bandwidth &&
      this.state.nodeLimit.storage === this.state.nodeLimit.memory
        ? this.state.nodeLimit.storage
        : 0;

    var bind_node_url =
      "http://118.25.127.105:8001/?p=" +
      localStorage.getItem("openfogos_userphone") +
      "&name=" +
      localStorage.getItem("openfogos_username") +
      "&src=" +
      window.location.href;
    var unbind_node_url =
      "http://118.25.127.105:8003/?name=" +
      localStorage.getItem("openfogos_username") +
      "&src=" +
      window.location.href;
    const inviteURL =
      "https://i.openfogos.com?inviter=" +
      localStorage.getItem("openfogos_username");
    const rowsPerPageOptionsOrigin = [5, 10, 25, 50];
    let rowsPerPageOptions = [];
    rowsPerPageOptionsOrigin.map((value) => {
      if (value < this.props.total) {
        rowsPerPageOptions.push(value);
      }
    });
    rowsPerPageOptions.push(this.props.total);
    const natTypeAdvice = (
      <div>
        <Typography variant="body1">
          上级光猫改桥接；上级路由/光猫开启UPnP；上级路由/光猫改成Full-cone
          NAT；上级路由/光猫设置DMZ并映射到该机器...
        </Typography>
      </div>
    );
    const nodeDetail = (
      <Dialog
        maxWidth="md"
        fullScreen={this.props.fullScreen}
        open={this.state.nodeDetailOpen}
        onClose={this.closeNodeDetail}
      >
        <div className={classes.dialogTitle}>
          <IconButton color="primary" onClick={this.closeNodeDetail}>
            <IconBack />
          </IconButton>
          <Typography
            display="inline"
            className={classes.verticalMiddle}
            variant="h6"
          >
            节点信息
          </Typography>
        </div>

        <DialogContent
          className={this.props.fullScreen ? "" : classes.dialogContent}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  公网IP
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {(this.props.list[this.state.nodeDetailIndex] || {})
                    .public_ip || ""}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {(this.props.list[this.state.nodeDetailIndex] || {}).http ==
                  1 ? (
                    <IconCheck color="primary" />
                  ) : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  内网IP
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {(this.props.list[this.state.nodeDetailIndex] || {})
                    .local_ip || ""}
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.tableCell}
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  可用空间
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {(
                    ((this.props.list[this.state.nodeDetailIndex] || {})
                      .storage_avail || 0) / 1024
                  ).toFixed(2)}
                  <Typography
                    display="inline"
                    noWrap
                    color="textSecondary"
                    variant="caption"
                    className={classes.mac}
                  >
                    {" "}
                    GB
                  </Typography>
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {((this.props.list[this.state.nodeDetailIndex] || {})
                    .storage_avail || 0) /
                    1024 >=
                  500 ? (
                    <IconCheck color="primary" />
                  ) : (
                    "建议共享500G以上的闲置空间"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  已用空间
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {(
                    ((this.props.list[this.state.nodeDetailIndex] || {})
                      .storage_used || 0) / 1024
                  ).toFixed(2)}
                  <Typography
                    display="inline"
                    noWrap
                    color="textSecondary"
                    variant="caption"
                    className={classes.mac}
                  >
                    {" "}
                    GB
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.tableCell}
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  验收状态
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {
                    approvedStatus[
                      (
                        (this.props.list[this.state.nodeDetailIndex] || {})
                          .approved || 0
                      ).toString()
                    ]
                  }
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {((this.props.list[this.state.nodeDetailIndex] || {})
                    .approved || 0) == 3 ? (
                    <IconCheck color="primary" />
                  ) : (
                    approvedStatusInfo[
                      (
                        (this.props.list[this.state.nodeDetailIndex] || {})
                          .approved || 0
                      ).toString()
                    ]
                  )}
                </TableCell>
              </TableRow>
              {(this.props.list[this.state.nodeDetailIndex] || {}).http ==
              1 ? null : (
                <TableRow>
                  <TableCell align="left" className={classes.tableCell}>
                    NAT类型
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    {natTypes[
                      (this.props.list[this.state.nodeDetailIndex] || {})
                        .nat_type || ""
                    ] || "未检测出"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableCell}
                  ></TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  上行带宽
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {(
                    (this.props.list[this.state.nodeDetailIndex] || {})
                      .upload_bw || 0
                  ).toFixed(2)}
                  <Typography
                    display="inline"
                    noWrap
                    color="textSecondary"
                    variant="caption"
                    className={classes.mac}
                  >
                    {" "}
                    Mbps
                  </Typography>
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {((this.props.list[this.state.nodeDetailIndex] || {})
                    .upload_bw || 0) >= 100 ? (
                    <IconCheck color="primary" />
                  ) : (
                    <Link to="/home/advice/bandwidth" className={classes.aLink}>
                      优化方案
                    </Link>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  实时业务带宽
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {(
                    (((this.props.list[this.state.nodeDetailIndex] || {})
                      .biz_bw || 0) *
                      8) /
                    1000 /
                    1000
                  ).toFixed(2)}
                  <Typography
                    display="inline"
                    noWrap
                    color="textSecondary"
                    variant="caption"
                    className={classes.mac}
                  >
                    {" "}
                    Mbps
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.tableCell}
                ></TableCell>
              </TableRow>
              {/*<TableRow>
                              <TableCell align="left" className={classes.tableCell}>昨日最大贡献带宽</TableCell>
                              <TableCell align="left" className={classes.tableCell}>
                                  {((this.props.list[this.state.nodeDetailIndex]||{}).bus_max||0).toFixed(2)}
                                  <Typography display="inline" noWrap color="textSecondary" variant="caption" className={classes.mac}> Mbps</Typography>
                              </TableCell>
                              <TableCell align="left" className={classes.tableCell}>
                              </TableCell>
                          </TableRow>*/}
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  运营商
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {(this.props.list[this.state.nodeDetailIndex] || {}).isp ||
                    ""}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {(this.props.list[this.state.nodeDetailIndex] || {}).isp ==
                  "电信" ? (
                    <IconCheck color="primary" />
                  ) : (
                    "一般而言：电信 > 联通 > 移动"
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  昨日在线
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {((this.props.list[this.state.nodeDetailIndex] || {})
                    .online_time || 0) < 0 ? (
                    `统计中`
                  ) : (
                    <span>
                      {(
                        (this.props.list[this.state.nodeDetailIndex] || {})
                          .online_time || 0
                      ).toFixed(2)}
                      <Typography
                        display="inline"
                        noWrap
                        color="textSecondary"
                        variant="caption"
                        className={classes.mac}
                      >
                        {" "}
                        h
                      </Typography>
                    </span>
                  )}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {((this.props.list[this.state.nodeDetailIndex] || {})
                    .online_time || 0) >= 24 ? (
                    <IconCheck color="primary" />
                  ) : (
                    "请保持节点稳定运行"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  昨日离线次数
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {((this.props.list[this.state.nodeDetailIndex] || {})
                    .online_time || 0) < 0 ? (
                    `统计中`
                  ) : (
                    <Typography
                      color={
                        ((this.props.list[this.state.nodeDetailIndex] || {})
                          .online_time || 0) >= 24
                          ? "default"
                          : "error"
                      }
                    >
                      {((this.props.list[this.state.nodeDetailIndex] || {})
                        .online_time || 0) == 24
                        ? 0
                        : (this.props.list[this.state.nodeDetailIndex] || {})
                            .offline_times || 1}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {((this.props.list[this.state.nodeDetailIndex] || {})
                    .online_time || 0) >= 24 ? (
                    <IconCheck color="primary" />
                  ) : null}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography align="center" gutterBottom>
            <Link
              to={{
                pathname: "/home/advice",
                state: {
                  ...(this.props.list[this.state.nodeDetailIndex] || {}),
                },
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="contained"
                className={classes.bottomButton}
              >
                定制优化方案
              </Button>
            </Link>
          </Typography>
          <Typography align="left" variant={"h6"}>
            资源分配
          </Typography>
          <Typography align="center" gutterBottom>
            <Button
              onClick={this.handleSetMode(100)}
              color={modeIndex == 100 ? "primary" : "default"}
              variant={modeIndex == 100 ? "contained" : "outlined"}
              size="small"
              className={classes.setModeButton}
            >
              高收益模式
            </Button>
            <Button
              onClick={this.handleSetMode(80)}
              color={modeIndex == 80 ? "primary" : "default"}
              variant={modeIndex == 80 ? "contained" : "outlined"}
              size="small"
              className={classes.setModeButton}
            >
              保守模式
            </Button>
            <Button
              onClick={this.handleSetMode(60)}
              color={modeIndex == 60 ? "primary" : "default"}
              variant={modeIndex == 60 ? "contained" : "outlined"}
              size="small"
              className={classes.setModeButton}
            >
              低收益模式
            </Button>
          </Typography>
          <div className={classes.sliderItem}>
            <Typography
              id="storage-label"
              variant="body1"
              className={classes.verticalMiddle}
              gutterBottom
            >
              <IconStorage className={classes.verticalMiddle} /> 分配存储：
              {this.state.nodeLimit.storage == 100
                ? "默认"
                : this.state.nodeLimit.storage + "%"}
            </Typography>
            <Slider
              min={60}
              max={100}
              step={1}
              classes={{ container: classes.slider }}
              className={classes.slider}
              value={this.state.nodeLimit.storage}
              aria-labelledby="storage-label"
              onChange={this.handleSliderChange("storage")}
            />
          </div>
          <div className={classes.sliderItem}>
            <Typography
              id="bandwidth-label"
              variant="body1"
              className={classes.verticalMiddle}
            >
              <IconNetwork className={classes.verticalMiddle} /> 分配带宽：
              {this.state.nodeLimit.bandwidth == 100
                ? "默认"
                : this.state.nodeLimit.bandwidth + "%"}
            </Typography>
            <Slider
              min={60}
              max={100}
              step={1}
              classes={{ container: classes.slider }}
              className={classes.slider}
              value={this.state.nodeLimit.bandwidth}
              aria-labelledby="bandwidth-label"
              onChange={this.handleSliderChange("bandwidth")}
            />
          </div>
          <div className={classes.sliderItem}>
            <Typography variant="body1" className={classes.verticalMiddle}>
              <embed
                className={classes.verticalMiddle}
                src="/memory.svg"
                type="image/svg+xml"
                width="24"
                height="24"
                style={{ marginRight: "0px" }}
              />{" "}
              分配内存：
              {this.state.nodeLimit.memory == 100
                ? "默认"
                : this.state.nodeLimit.memory + "%"}
            </Typography>
            <Slider
              min={60}
              max={100}
              step={1}
              classes={{ container: classes.slider }}
              className={classes.slider}
              value={this.state.nodeLimit.memory}
              onChange={this.handleSliderChange("memory")}
            />
          </div>

          <Typography variant="body1" className={classes.verticalMiddle}>
            <IconMemory className={classes.verticalMiddle} /> CPU核数：
          </Typography>
          <FormControl margin="normal" fullWidth>
            <Select
              value={this.state.nodeLimit.cpu}
              onChange={this.handleTextFieldChange("cpu")}
              inputProps={{
                name: "age",
                id: "age-simple",
              }}
            >
              <MenuItem value={0}>auto</MenuItem>
              {((this.props.list[this.state.nodeDetailIndex] || {}).cpu_cores ||
                0) > 2 ? (
                <MenuItem value={2}>2</MenuItem>
              ) : null}
              {((this.props.list[this.state.nodeDetailIndex] || {}).cpu_cores ||
                0) > 4 ? (
                <MenuItem value={4}>4</MenuItem>
              ) : null}
              {((this.props.list[this.state.nodeDetailIndex] || {}).cpu_cores ||
                0) > 8 ? (
                <MenuItem value={8}>8</MenuItem>
              ) : null}
              {((this.props.list[this.state.nodeDetailIndex] || {}).cpu_cores ||
                0) > 12 ? (
                <MenuItem value={12}>12</MenuItem>
              ) : null}
              {((this.props.list[this.state.nodeDetailIndex] || {}).cpu_cores ||
                0) > 16 ? (
                <MenuItem value={16}>16</MenuItem>
              ) : null}
              {((this.props.list[this.state.nodeDetailIndex] || {}).cpu_cores ||
                0) > 24 ? (
                <MenuItem value={24}>24</MenuItem>
              ) : null}
              {((this.props.list[this.state.nodeDetailIndex] || {}).cpu_cores ||
                0) > 32 ? (
                <MenuItem value={32}>32</MenuItem>
              ) : null}
              {((this.props.list[this.state.nodeDetailIndex] || {}).cpu_cores ||
                0) > 64 ? (
                <MenuItem value={64}>64</MenuItem>
              ) : null}
              {((this.props.list[this.state.nodeDetailIndex] || {}).cpu_cores ||
                0) > 0 ? (
                <MenuItem
                  value={
                    (this.props.list[this.state.nodeDetailIndex] || {})
                      .cpu_cores || 0
                  }
                >
                  {(this.props.list[this.state.nodeDetailIndex] || {})
                    .cpu_cores || 0}
                </MenuItem>
              ) : null}
            </Select>
          </FormControl>
          <Typography align="center" gutterBottom>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => this.setLimitNow()}
              className={classes.bottomButton}
            >
              保存修改
            </Button>
          </Typography>
          <Typography align="center">
            <IconButton
              className={classes.closeButton}
              onClick={this.closeNodeDetail}
            >
              <IconClose fontSize="large" color="primary" />
            </IconButton>
          </Typography>
        </DialogContent>
      </Dialog>
    );
    const adviceDialog = (
      <Dialog
        maxWidth="md"
        fullScreen={true}
        open={this.state.adviceOpen}
        onClose={this.closeAdvice}
      >
        <DialogTitle
          id="form-dialog-title"
          className={classNames(classes.dialogTitle, classes.verticalMiddle)}
        >
          <IconButton color="primary" onClick={this.closeAdvice}>
            <IconBack />
          </IconButton>
          <Typography
            display="inline"
            className={classes.verticalMiddle}
            variant="h6"
          >
            优化建议
          </Typography>
        </DialogTitle>
        <DialogContent
          className={this.props.fullScreen ? "" : classes.dialogContent}
        >
          {this.state.advice}
        </DialogContent>
      </Dialog>
    );

    return (
      <div className={classes.root}>
        <Typography gutterBottom variant="subtitle1">
          <IconList
            className={classNames(classes.verticalMiddle, classes.leftIcon)}
          />
          <span className={classes.verticalMiddle}>雾节点</span>
        </Typography>
        <Typography gutterBottom>
          <a href={bind_node_url} target="_self">
            <Button color="primary" size="small" variant="contained">
              <IconCircleOutline className={classes.leftButton} />
              本地绑定节点
            </Button>
          </a>
          <Tooltip title="适用于宝塔 OpenFogOS 插件" placement="top">
            <Link style={{ marginLeft: 15 }} to={"/home/bind"}>
              <Button color="primary" size="small" variant="contained">
                <IconCircleOutline className={classes.leftButton} />
                远程绑定节点
              </Button>
            </Link>
          </Tooltip>
          <a
            style={{ marginLeft: 15 }}
            target="_blank"
            href="https://github.com/OpenFog/guide/wiki/%E8%8A%82%E7%82%B9%E7%BB%91%E5%AE%9A%E6%96%B9%E6%B3%95%EF%BC%88%E6%9C%AC%E6%9C%BA%E7%9B%B4%E6%8E%A5%E7%BB%91%E5%AE%9A%EF%BC%89"
          >
            绑定教程
          </a>
          {/*<a style={{marginLeft: 15}} target="_blank" href="https://market.m.taobao.com/app/idleFish-F2e/widle-taobao-rax/page-detail?id=586912803774">设备购买</a>
                  <a style={{marginLeft: 15}} href={unbind_node_url} target='_self'>解绑</a>*/}
        </Typography>
        <Typography variant="subtitle1">
          温馨提示：请尽量保持节点常在线，连续不在线2天，会被禁止调度1个月
        </Typography>
        <div className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>节点</TableCell>
                {/*<TableCell align="right">别名</TableCell>*/}
                <TableCell className={classes.tableCell} align="right">
                  状态
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <span style={{ whiteSpace: "nowrap" }}>昨日在线</span>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <span style={{ whiteSpace: "nowrap" }}>昨日收益</span>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <span style={{ whiteSpace: "nowrap" }}>待提升收益</span>
                </TableCell>
                {/*<TableCell className={classes.tableCell} align="right">
                          <Tooltip title="与带宽相同的其他设备最高收益的差距" placement="top">
                          <span className={classes.verticalMiddle} style={{whiteSpace:'nowrap'}}>待提升收益 <IconHelp className={classes.verticalMiddle} color="action" style={{ fontSize: 16 }} /></span>
                        </Tooltip>
                        </TableCell>*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.list
                .slice(0, this.state.rowsPerPage)
                .map((row, index) => (
                  <TableRow key={row.mac_addr}>
                    <TableCell className={classes.tableCell}>
                      {this.state.nicknameMac === row.mac_addr ? (
                        <Input
                          maxLength={20}
                          autoFocus
                          value={this.state.nickname}
                          onChange={this.nicknameOnchange}
                          onKeyUp={this.onKeyUp}
                          onBlur={this.onBlur}
                        />
                      ) : (
                        <span className={classes.verticalMiddle}>
                          {row.nickname || "设置别名"}
                          {
                            <Edit
                              style={{ fontSize: 16 }}
                              className={classNames(
                                classes.icon,
                                classes.verticalMiddle,
                              )}
                              color="action"
                              onClick={() => {
                                this.openSetNickname(
                                  row.mac_addr,
                                  row.nickname || "",
                                );
                              }}
                            />
                          }
                        </span>
                      )}
                      <Typography
                        display={"block"}
                        noWrap
                        color="textSecondary"
                        variant="caption"
                        className={classes.mac}
                      >
                        {row.mac_addr}
                      </Typography>
                      {/*{row.nickname}{row.nickname? <Edit style={{ fontSize: 20 }} className={classes.icon} color="action" onClick={()=>{this.openSetNickname(row.mac_addr, row.nickname)}}/>:
                                  <Button color="primary" size="small" variant="outlined" onClick={()=>{this.openSetNickname(row.mac_addr, "")}}>设置别名</Button>}*/}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <Typography noWrap>
                        {row.online ? (
                          <SmileIcon
                            className={classes.power}
                            color="primary"
                          />
                        ) : (
                          <Tooltip title="删除节点" placement="right">
                            <SadIcon
                              onClick={() =>
                                this.props.confirm(
                                  `确定删除该节点？`,
                                  () =>
                                    this.props.deleteMac(
                                      row.mac_addr,
                                      this.state.page + 1,
                                      this.state.rowsPerPage,
                                    ),
                                  false,
                                )
                              }
                            />
                          </Tooltip>
                        )}
                      </Typography>
                    </TableCell>
                    {/*<TableCell align="right" className={classes.tableCell}><Typography noWrap >{row.online? "在线":"离线"}</Typography></TableCell>*/}
                    <TableCell align="right" className={classes.tableCell}>
                      {(row.online_time || 0) < 0
                        ? `统计中`
                        : `${(row.online_time || 0).toFixed(1)} h`}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {(row.reward_yesterday || 0) < 0 ? (
                        `结算中`
                      ) : (
                        <span>
                          {row.reward_yesterday || 0}
                          <Typography
                            style={{ display: "inline" }}
                            noWrap
                            color="textSecondary"
                            variant="caption"
                            className={classes.mac}
                          >
                            {" "}
                            PRT
                          </Typography>
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classNames(
                        classes.tableCell,
                        classes.idealReward,
                      )}
                    >
                      <span
                        onClick={this.openNodeDetail(index, row)}
                        className={classes.idealRewardButton}
                      >
                        {(row.reward_yesterday || 0) < 0 ? (
                          `结算中`
                        ) : (
                          <span>
                            {
                              -Math.abs(
                                (row.reward_ideal || 0) -
                                  (row.reward_yesterday || 0),
                              )
                            }
                            <Typography
                              display="inline"
                              noWrap
                              color="textSecondary"
                              variant="caption"
                              className={classes.mac}
                            >
                              {" "}
                              PRT
                            </Typography>
                          </span>
                        )}
                      </span>
                    </TableCell>
                    {/*<TableCell align="right" className={classes.tableCell}>{row.storage_total ? (row.storage_total > 1024 * 1024 ? (row.storage_total / 1024 / 1024).toFixed(1) + 'TB' :(row.storage_total / 1024).toFixed(1) + 'GB') : '未知'}</TableCell>*/}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          style={this.props.total <= 3 ? { display: "none" } : {}}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={this.props.total}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage={"每页行数："}
          // labelDisplayedRows={({ from, to, count }) => `总数：${count}，当前：${from}-${to}`}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} / ${count}`
          }
        />
        {/*<Table {...tableParam} />*/}
        {nodeDetail}
        {adviceDialog}
      </div>
    );
  }
  componentDidMount() {
    if (this.props.list.length === 0)
      this.props.onRequire(this.state.page + 1, this.state.rowsPerPage);
  }
}

NodeList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    list: state.node.info || [],
    total: state.node.totalNode,
    fetchLoading: state.node.infoStatus === "loading" ? true : false,
    modalVisible: state.node.modalShow,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: (page, pageLength) => {
      dispatch(fetchInfo(page, pageLength));
      dispatch(fetchTotal());
    },
    onSearch: (value) => {
      dispatch(searchMac(value));
    },
    setNickname: (mac, nickname) => {
      dispatch(
        setNickname(mac, nickname, {
          // success: () => {
          //     alert('修改成功')
          // },
          error: () => {
            dispatch(messageError("修改失败"));
          },
        }),
      );
    },
    messageOk: (str) => {
      dispatch(messageOk(str));
    },
    setLimit: (data, page, pageLength) => {
      dispatch(
        setLimit({
          data,
          success: () => {
            dispatch(messageOk("修改成功"));
            dispatch(fetchInfo(page, pageLength));
            dispatch(fetchTotal());
          },
          error: () => {
            dispatch(messageError("修改配置失败"));
          },
        }),
      );
    },
    deleteMac: (mac, page, pageLength) => {
      dispatch(
        deleteMac(mac, {
          success: () => {
            dispatch(messageOk("删除成功"));
            dispatch(fetchInfo(page, pageLength));
            dispatch(fetchTotal());
          },
          error: () => {
            dispatch(messageError("删除失败"));
          },
        }),
      );
    },
    confirm: (message, callback, hasVcode) => {
      dispatch(confirm(message, callback, hasVcode));
    },
  };
};

export default withMobileDialog()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeList)),
);
