import React from "react";
//import { view as UserInfo } from '../modules/user/info/'
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Blue from "@material-ui/core/colors/blue";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  gap: {
    height: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  aLink: {
    color: Blue[500],
  },
  subItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  topItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

const Hints = (props) => {
  const { classes } = props;
  const nodeInfo = props.location.state;
  const networkUPnP = (
    <div className={classes.subItem}>
      <Link
        to={{ pathname: "/home/advice/upnp", state: {} }}
        style={{ float: "right" }}
      >
        <Button
          color="primary"
          size="small"
          variant="contained"
          className={classes.bottomButton}
        >
          查看
        </Button>
      </Link>
      <Typography variant="body1">如何打开路由器UPnP功能？</Typography>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        通过路由器管理后台开启UPnP功能
      </Typography>
    </div>
  );
  const networkBandwidth = (
    <div className={classes.subItem}>
      <Link
        to={{ pathname: "/home/advice/bandwidth", state: {} }}
        style={{ float: "right" }}
      >
        <Button
          color="primary"
          size="small"
          variant="contained"
          className={classes.bottomButton}
        >
          查看
        </Button>
      </Link>
      <Typography variant="body1">上行带宽如何提速优化？</Typography>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        通过不同的方式提高上行带宽
      </Typography>
    </div>
  );
  const networkISP = (
    <div className={classes.subItem}>
      <Typography variant="body1">建议使用中国电信的网络</Typography>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        一般而言，不同运营商的收益大小：电信 &gt; 联通 &gt; 移动
      </Typography>
    </div>
  );
  const networkPublicIP = (
    <div className={classes.subItem}>
      <Link
        to={{ pathname: "/home/advice/public_ip.md", state: {} }}
        style={{ float: "right" }}
      >
        <Button
          color="primary"
          size="small"
          variant="contained"
          className={classes.bottomButton}
        >
          查看
        </Button>
      </Link>
      <Typography variant="body1">如何将路由器设置成公网IP？</Typography>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        开通公网IP，路由器拨号上网
      </Typography>
    </div>
  );

  const storageSpace = (
    <div className={classes.subItem}>
      <Typography variant="body1">建议共享200G以上的闲置空间</Typography>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        共享资源可用性更佳
      </Typography>
    </div>
  );
  const storageQuality = (
    <div className={classes.subItem}>
      <Typography variant="body1">使用读写性能更高的外置存储设备</Typography>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        有助于保护数据安全，且共享资源可用性更佳
      </Typography>
    </div>
  );

  return (
    <div className="Advice">
      <Typography variant="h5" gutterBottom>
        优化方案
      </Typography>
      <div className={classes.topItem}>
        <Typography variant="h6">网络优化</Typography>
        <Divider />
        {nodeInfo.http === 1 ? null : networkPublicIP}
        {networkUPnP}
        {networkBandwidth}
        {nodeInfo.isp === "电信" ? null : networkISP}
      </div>
      <div className={classes.topItem}>
        <Typography variant="h6">存储优化</Typography>
        <Divider />
        {nodeInfo.storage_total > 200000 ? null : storageSpace}
        {storageQuality}
      </div>

      <Link to="/home/hints" className={classes.aLink}>
        通用优化方案
      </Link>
    </div>
  );
};
Hints.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Hints);
