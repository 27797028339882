import xhr from "../../Xhr";
import moment from "moment";

const API = (param = {}) => ({
  node: {
    faultTime: {
      //获取节点列表
      url: process.env.REACT_APP_NMSAPI_SERVER + `/get_fault_time`,
      method: "GET",
    },
    get: {
      //获取节点列表
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine`,
      method: "GET",
    },
    add: {
      //提交节点
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine`,
      method: "POST",
    },
    bind: {
      //绑定节点
      url: process.env.REACT_APP_NMSAPI_SERVER + `/v2/user_mine`,
      method: "POST",
    },
    modify: {
      //修改节点配置
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/modify/${param.id || ""}`,
      method: "PUT",
    },
    resubmit: {
      //重新提交节点
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/resubmit/${param.id || ""}`,
      method: "PUT",
    },
    setName: {
      //设置节点别名
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/${param.id || ""}?name=${param.name || ""}`,
      method: "PUT",
    },
    setMonitor: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/monitor`,
      method: "PUT",
    },
    deleteNode: {
      //删除节点
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/${param.id || ""}`,
      method: "DELETE",
    },
    unBindNode: {
      //解除绑定节点
      url: process.env.REACT_APP_NMSAPI_SERVER + "/v2/user_mine",
      method: "DELETE",
    },
    trafficToday: {
      //当天流量详情
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/traffic_m/${param.id || ""}`,
      method: "GET",
    },
    macTraffic: {
      //当天流量详情
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/traffic_mac/${param.id || ""}?date=${param.date}`,
      method: "GET",
    },
    machineError: {
      //当天机器错误
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/error/${param.id || ""}`,
      method: "GET",
    },
    macInfo: {
      //当天mac信息
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/mac_info/${param.id || ""}?date=${param.date}`,
      method: "GET",
    },
    storage: {
      //当天存储详情
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/storage/${param.id || ""}?date=${param.date}`,
      method: "GET",
    },
    cpuIoToday: {
      //当天流量详情
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/cpu_io/${param.id || ""}`,
      method: "GET",
    },
    retransToday: {
      //当天流量详情
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/retrans/${param.id || ""}`,
      method: "GET",
    },
    trafficYesterday: {
      //当天流量详情
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/traffic_m/${param.id || ""}?date=${moment()
          .subtract(1, "d")
          .unix()}`,
      method: "GET",
    },
    cpuIoYesterday: {
      //当天流量详情
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/cpu_io/${param.id || ""}?date=${moment()
          .subtract(1, "d")
          .unix()}`,
      method: "GET",
    },
    retransYesterday: {
      //当天流量详情
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/retrans/${param.id || ""}?date=${moment()
          .subtract(1, "d")
          .unix()}`,
      method: "GET",
    },
    chargingBWs: {
      //N天计费带宽
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/charging_bws/${param.id || ""}/${param.days || ""}`,
      method: "GET",
    },
  },
  invite: {
    getCode: {
      //获取节点列表
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/inviter/code`,
      method: "GET",
    },
    getNodes: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/inviter/nodes`,
      method: "GET",
    },
  },
  cash: {
    //散户CDN收益
    get: {
      //获取余额信息
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/cash`,
      method: "GET",
    },
    history: {
      //获取余额信息
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/cash/history?from=${param.from || "0"}&to=${
          param.to || "0"
        }`,
      method: "GET",
    },
  },
  withdraw: {
    get: {
      //获取提现记录
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/withdraw`,
      method: "GET",
    },
    prepare: {
      //提现请求预生成
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/withdraw/${param.amount || 0}`,
      method: "POST",
    },
    check: {
      //查询请求状态
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/withdraw/${param.id || 0}`,
      method: "GET",
    },
    add: {
      //发起提现请求
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/withdraw`,
      method: "POST",
    },
    fapiao: {
      //发起提现请求
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/withdraw/fapiao`,
      method: "PUT",
    },
  },
  collectionMethod: {
    get: {
      //获取收款方式列表
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/collection_method`,
      method: "GET",
    },
    add: {
      //添加或修改收款方式
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/collection_method`,
      method: "POST",
    },
    setType: {
      //选择默认收款类型
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/user_mine/collection_method/${param.type || ""}`,
      method: "PUT",
    },
    resetOpenid: {
      //重置临时openid
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/openid`,
      method: "DELETE",
    },
    getOpenid: {
      //获取临时openid
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/openid`,
      method: "GET",
    },
  },
  unionpayMethod: {
    get: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/unionpay`,
      method: "GET",
    },
    add: {
      // add or update
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/unionpay`,
      method: "PUT",
    },
    delete: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/unionpay`,
      method: "DELETE",
    },
  },
  invoiceMethod: {
    add: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/invoice`,
      method: "POST",
    },
  },
  cperror: {
    list: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/cp_error`,
      method: "GET",
    },
  },
  errorType: {
    list: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/error_type`,
      method: "GET",
    },
  },
});

//获取节点列表
export const getUserMine = () => {
  return xhr({
    api: API().node.get,
    action: "USERMINE/NODE/GET",
  });
};

export const getFaultTime = () => {
  return xhr({
    api: API().node.faultTime,
    action: "USERMINE/NODE/FAULTTIME",
  });
};

//提交CDN节点
export const submitUserMine = ({ data, ...event }) => {
  return xhr({
    api: API().node.add,
    action: "USERMINE/NODE/ADD",
    data,
    event,
  });
};

//绑定CDN节点
export const bindUserMine = ({ data, ...event }) => {
  return xhr({
    api: API().node.bind,
    action: "USERMINE/NODE/BIND",
    data,
    event,
  });
};

//修改CDN节点
export const modifyUserMine = ({ id, data, ...event }) => {
  return xhr({
    api: API({ id }).node.modify,
    action: "USERMINE/NODE/MODIFY",
    data,
    event,
  });
};

//重新提交节点
export const resubmitUserMine = (id, event) => {
  return xhr({
    api: API({ id }).node.resubmit,
    action: "USERMINE/NODE/RESUBMIT",
    event,
  });
};

export const updateMachineMonitor = (id, monitorStatus, event) => {
  return xhr({
    api: API().node.setMonitor,
    action: "USERMINE/NODE/SETMONITOR",
    data: {
      machine_id: id,
      is_monitor: monitorStatus,
    },
    event,
  });
};

//设置CDN别名
export const setName = (id, name, event) => {
  return xhr({
    api: API({ id: id, name: name }).node.setName,
    action: "USERMINE/NODE/SETNAME",
    event,
  });
};

//删除节点
export const deleteUserMine = (id, event) => {
  return xhr({
    api: API({ id: id }).node.deleteNode,
    action: "USERMINE/NODE/DELETE",
    event,
  });
};

//解除绑定节点
export const unBindUserMine = (data, event) => {
  return xhr({
    api: API().node.unBindNode,
    action: "USERMINE/NODE/DELETE",
    event,
    data,
  });
};

//获取节点当天流量详情
export const getUserMineTrafficToday = (id, event) => {
  return xhr({
    api: API({ id: id }).node.trafficToday,
    action: "USERMINE/NODE/TRAFFICTODAY",
    event,
  });
};

export const getMacBandwidth = (data, event) => {
  data.id = data.id || 0;
  data.date = data.date || "";
  return xhr({
    api: API(data).node.macTraffic,
    action: "USERMINE/NODE/MAC/TRAFFIC",
    event,
  });
};

//获取节点当天IO异常详情
export const getUserMineIOToday = (id, event) => {
  return xhr({
    api: API({ id: id }).node.cpuIoToday,
    action: "USERMINE/NODE/CPUIOTODAY",
    event,
  });
};
export const getUserMineRetransToday = (id, event) => {
  return xhr({
    api: API({ id: id }).node.retransToday,
    action: "USERMINE/NODE/RETRANSTODAY",
    event,
  });
};

//获取节点昨天流量详情
export const getUserMineTrafficYesterday = (id, event) => {
  return xhr({
    api: API({ id: id }).node.trafficYesterday,
    action: "USERMINE/NODE/TRAFFICYESTERDAY",
    event,
  });
};

//获取节点昨天IO异常详情
export const getUserMineIOYesterday = (id, event) => {
  return xhr({
    api: API({ id: id }).node.cpuIoYesterday,
    action: "USERMINE/NODE/CPUIOYESTERDAY",
    event,
  });
};
//获取节点昨天IO异常详情
export const getUserMineRetransYesterday = (id, event) => {
  return xhr({
    api: API({ id: id }).node.retransYesterday,
    action: "USERMINE/NODE/RETRANSYESTERDAY",
    event,
  });
};

//获取节点7天计费带宽
export const getUserMineChargingBWsLastWeek = (id, event) => {
  return xhr({
    api: API({ id: id, days: 7 }).node.chargingBWs,
    action: "USERMINE/NODE/CHARGINGBWS/LASTWEEK",
    event,
  });
};

//获取节点30天计费带宽
export const getUserMineChargingBWsLastMonth = (id, event) => {
  return xhr({
    api: API({ id: id, days: 30 }).node.chargingBWs,
    action: "USERMINE/NODE/CHARGINGBWS/LASTMONTH",
    event,
  });
};

//获取收益历史
export const getCashHistory = (from, to, event) => {
  return xhr({
    api: API({ from, to }).cash.history,
    action: "USERMINE/CASH/HISTORY",
    event,
  });
};

//获取余额信息
export const getCash = () => {
  return xhr({
    api: API().cash.get,
    action: "USERMINE/CASH/GET",
  });
};

//获取提现记录
export const getWithdrawLogs = () => {
  return xhr({
    api: API().withdraw.get,
    action: "USERMINE/WITHDRAW/GET",
  });
};

//查询请求状态
export const checkWithdrawLog = (id, event) => {
  return xhr({
    api: API({ id: id }).withdraw.check,
    action: "USERMINE/WITHDRAW/CHECK",
    event,
  });
};

//提现请求预生成
export const prepareWithdraw = (amount, event) => {
  return xhr({
    api: API({ amount: amount }).withdraw.prepare,
    action: "USERMINE/WITHDRAW/PREPARE",
    event,
  });
};

//添加或修改收款方式
export const addWithdrawRequest = (withdraw, event) => {
  return xhr({
    api: API().withdraw.add,
    action: "USERMINE/WITHDRAW/ADD",
    data: {
      amount: parseInt(withdraw.amount),
      type: parseInt(withdraw.type),
      type_id: parseInt(withdraw.typeID),
      tax_rate: parseInt(withdraw.taxRate || 0),
      agreement_id: 1,
      agreement_is_agreed: withdraw.isAgreed ? 1 : 0,
    },
    event,
  });
};

//添加或修改收款方式
export const addInvoice = (inoviceNumber, phone, company, event) => {
  return xhr({
    api: API().invoiceMethod.add,
    action: "USERMINE/INVOICE/ADD",
    data: {
      invoice_number: inoviceNumber,
      phone: phone,
      company: company,
    },
    event,
  });
};

//提交发票号
export const setFapiao = (form, event) => {
  return xhr({
    api: API().withdraw.fapiao,
    action: "USERMINE/WITHDRAW/FAPIAO",
    data: form,
    event,
  });
};

//获取收款方式列表
export const getCollectionMethod = () => {
  return xhr({
    api: API().collectionMethod.get,
    action: "USERMINE/CM/GET",
  });
};
//添加或修改收款方式
export const addCollectionMethod = (data, event) => {
  return xhr({
    api: API().collectionMethod.add,
    action: "USERMINE/CM/ADD",
    data,
    event,
  });
};

export const getUnionpay = (data, event) => {
  return xhr({
    api: API().unionpayMethod.get,
    action: "USERMINE/UNIONPAY/GET",
    data,
    event,
  });
};

//添加或修改收款方式
export const addUnionpay = (data, event) => {
  return xhr({
    api: API().unionpayMethod.add,
    action: "USERMINE/UNIONPAY/ADD",
    data,
    event,
  });
};
export const deleteUnionpay = (data, event) => {
  return xhr({
    api: API().unionpayMethod.delete,
    action: "USERMINE/UNIONPAY/DELETE",
    data,
    event,
  });
};
//选择默认收款类型
export const setCollectionMethodType = (type, event) => {
  return xhr({
    api: API({ type }).collectionMethod.setType,
    action: "USERMINE/CM/TYPE/SET",
    event,
  });
};
//重置临时openid
export const resetOpenid = () => {
  return xhr({
    api: API().collectionMethod.resetOpenid,
    action: "USERMINE/OPENID/RESET",
  });
};
//获取临时openid
export const getOpenid = (event) => {
  return xhr({
    api: API().collectionMethod.getOpenid,
    action: "USERMINE/OPENID/GET",
    event,
  });
};
//获取矿场推广邀请码
export const getInviteCode = () => {
  return xhr({
    api: API().invite.getCode,
    action: "USERMINE/INVITE/CODE",
  });
};
//获取矿场推广节点
export const getInviteNodes = () => {
  return xhr({
    api: API().invite.getNodes,
    action: "USERMINE/INVITE/NODES",
  });
};

export const getMachineError = (event, data) => {
  data.id = data.id || "";
  return xhr({
    api: API(data).node.machineError,
    action: "USERMINE/MACHINE_ERROR/GET",
    event,
  });
};
export const getMachineStorage = (data, event) => {
  data.id = data.id || 0;
  data.date = data.date || "";
  return xhr({
    api: API(data).node.storage,
    action: "USERMINE/STORAGE/GET",
    event,
  });
};
export const getMacInfo = (data, event) => {
  data.id = data.id || 0;
  data.date = data.date || "";
  return xhr({
    api: API(data).node.macInfo,
    action: "USERMINE/MAC/GET",
    event,
  });
};

export const getCPError = (event) => {
  return xhr({
    api: API().cperror.list,
    action: "USERMINE/CPERROR/GET",
    event,
  });
};
export const getErrorType = (event) => {
  return xhr({
    api: API().errorType.list,
    action: "USERMINE/ERROR_TYPE/GET",
    event,
  });
};
