import React from "react";
import copy from "copy-to-clipboard/index";
import { withStyles } from "@material-ui/core/styles/index";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { messageError, messageOk } from "../../../modules/utils/actions";
import { bindUserMine } from "../../../modules/userMine/actions";
import { connect } from "react-redux";
// import Form from './form';
import BindForm from "./bindForm";

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing( 2),
  },
  button: {
    width: "100%",
    size: "medium",
  },
  input: {
    width: "100%",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
});
class Certification extends React.Component {
  state = {
    formData: {},
    loading: false,
    disabled: false,
  };

  copyText(text) {
    copy(text);
    this.props.messageOk("复制成功");
  }

  onSubmit = () => {
    if (this.state.formData.node_id?.length !== 22) {
      this.props.messageError("请输入正确的node_id");
      return;
    }
    this.setState({
      loading: true,
      disabled: true,
    });
    this.props.onSubmit(
      this.state.formData,
      () => {
        this.props.history.replace({ pathname: "/home/my_mine_nodes" });
      },
      () => {
        this.setState({
          disabled: false,
        });
      },
      () => {
        this.setState({
          loading: false,
        });
      },
    );
  };

  onFormChange(fields) {
    this.setState({
      formData: {
        ...this.state.formData,
        ...fields,
      },
    });
  }

  render() {
    const { classes } = this.props;
    // const titleProps = {
    //     gutterBottom: true,
    //     align: 'center',
    //     variant: 'h6',
    // }
    const bindFormParam = {
      onSubmit: this.onSubmit,
      onChange: (fields) => this.onFormChange(fields),
      isSN: true,
      ...this.state.formData,
      loading: this.state.loading,
      disabled: this.state.disabled,
    };

    return (
      <div>
        {/* <Typography {...titleProps}>
                提交CDN节点
            </Typography> */}
        <BindForm {...bindFormParam} />
      </div>
    );
  }
  componentDidMount() {
    this.props.onRequire();
  }
}

Certification.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {},
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
    messageError: (dataStr) => {
      dispatch(messageError(dataStr));
    },
    onSubmit: (data, successCallBack, errorCallBack, finallyCallBack) => {
      dispatch(
        bindUserMine({
          data: data,
          error: () => {
            dispatch(messageError("绑定失败！请检查输入是否有误。"));
            errorCallBack();
          },
          success: () => {
            dispatch(
              messageOk("绑定成功！等待1秒后返回", () => {
                successCallBack();
              }),
            );
          },
        }),
      ).finally(finallyCallBack);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Certification));
