import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { messageOk } from "../../../modules/utils/actions";
import { connect } from "react-redux";
import Form from "./form";

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing( 2),
  },
  button: {
    width: "100%",
    size: "medium",
  },
  input: {
    width: "100%",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
});
class Certification extends React.Component {
  render() {
    const { classes } = this.props;
    const nodeInfo = this.props.location.state;
    const titleProps = {
      gutterBottom: true,
      align: "center",
      variant: "h6",
    };
    return (
      <div>
        <Typography {...titleProps}>修改节点配置</Typography>
        <Form nodeInfo={nodeInfo} />
      </div>
    );
  }
  componentDidMount() {
    this.props.onRequire();
  }
}

Certification.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {},
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Certification));
