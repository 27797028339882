import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles/index";
import withMobileDialog from "@material-ui/core/withMobileDialog/index";
import Typography from "@material-ui/core/Typography";
import { cashToRMB } from "../../../modules/utils/functions";

const styles = (theme) => ({
  textField: {
    minWidth: 223,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 223,
  },
  title: {
    fontWeight: "bold",
  },
  littleTitle: {
    display: "flex",
    alignItems: "center",
  },
  line: {
    flex: "1 1 auto",
    width: "100%",
    height: 0,
    border: "1px solid #999",
    verticalAlign: "middle",
  },
  littleTitleContent: {
    flex: "0 0 auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  fapiaoInfo: {
    textAlign: "left",
    marginTop: theme.spacing(1),
  },
});

class Form extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange({
      ...this.props.form,
      [event.target.name]:
        event.target.name === "mac_addr"
          ? (event.target.value || "").toLowerCase()
          : event.target.value,
    });
  }

  render() {
    const { classes, fullScreen, open, onCancel, onOk, form } = this.props;
    const textFieldProps = {
      className: classes.textField,
      variant: "outlined",
      margin: "dense",
    };
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        fullScreen={fullScreen}
        open={open}
        onClose={onCancel}
      >
        <DialogTitle id="form-dialog-title">填写发票号</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <TextField
            {...textFieldProps}
            onChange={this.onChange}
            label="发票号"
            name="invoice_number"
            type="text"
            value={form.invoice_number || ""}
            placeholder="8位数字"
          />
          <br />
          <TextField
            {...textFieldProps}
            label="发票税价合计"
            name="price"
            type="text"
            value={(cashToRMB(form.amount) - cashToRMB(form.tax)).toFixed(2)}
            disabled={true}
          />
          <br />

          <div className={classes.fapiaoInfo}>
            <div className={classes.littleTitle}>
              <div className={classes.line}></div>
              <Typography
                className={classes.littleTitleContent}
                variant="body1"
                align={"center"}
              >
                开票信息
              </Typography>
              <div className={classes.line}></div>
            </div>

            <Typography variant="caption">
              <span className={classes.title}>名称：</span>深圳梨享计算有限公司
              <br />
              <span className={classes.title}>纳税人识别号：</span>
              91440300MA5EDRDG7X
              <br />
              <span className={classes.title}>地址、电话：</span>
              深圳市前海深港合作区前湾一路1号A栋201室（入驻深圳市前海商务秘书有限公司）
              0755-27605666
              <br />
              <span className={classes.title}>开户行及账号：</span>
              招商银行深圳分行蔡屋围支行 755933213010401
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            取消
          </Button>
          <Button
            onClick={onOk}
            color="primary"
            disabled={!form.invoice_number}
          >
            提交
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(Form));
