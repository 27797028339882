import React, { useEffect, useRef, useState } from "react";
//import { view as UserInfo } from '../modules/user/info/'
import Assets from "../../modules/displayAssets";
import { WithdrawListItem as ListItem } from "../../modules/listItem";
import { withStyles } from "@material-ui/core/styles";
import { fetchCertificationStatus } from "../../modules/user/actions";
import {
  confirm,
  fetchNotifications,
  messageError,
  messageOk,
} from "../../modules/utils/actions";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import RightArrowIcon from "@material-ui/icons/ArrowForward";
import IconButton from "@material-ui/core/IconButton";
import {
  addCollectionMethod,
  addInvoice,
  addUnionpay,
  addWithdrawRequest,
  checkWithdrawLog,
  deleteUnionpay,
  getCash,
  getCollectionMethod,
  getOpenid,
  getUnionpay,
  getWithdrawLogs,
  resetOpenid,
  setCollectionMethodType,
  setFapiao,
} from "../../modules/userMine/actions";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import { SimpleTitle, Title } from "../../modules/title";
import { cashToRMB } from "../../modules/utils/functions";
import Table from "../../modules/table";
import moment from "moment";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import UnionpayForm from "../../modules/userMine/withdrawLog/unionpayForm";
import WechatpayForm from "../../modules/userMine/withdrawLog/wechatpayForm";
import AlipayForm from "../../modules/userMine/withdrawLog/alipayForm";
import FapiaoNumberForm from "../../modules/userMine/withdrawLog/fapiaoForm";
import FapiaoTaxRateForm from "../../modules/userMine/withdrawLog/fapiaoTaxRateForm";
import CreditCard from "@material-ui/icons/CreditCard";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import EasyAnnounce from "../../modules/easyAnnounce";
import FormHelperText from "@material-ui/core/FormHelperText";

const htmlPath = "https://download.openfogos.com/doc/html/";
const agreementPath = htmlPath + "自由职业者服务协议_v1.html";

const styles = (theme) => ({
  gap: {
    height: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputPaper: {
    // marginTop: 2,
    marginBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "70vw",
    maxWidth: "500px",
    minWidth: "300px",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  cmDiv: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  cmIcon: {
    verticalAlign: "middle",
    margin: 3,
    marginRight: 4,
  },
  cmTitle: {
    // width: 130,
    paddingRight: theme.spacing(2),
    display: "inline-block",
  },
  cmContent: {
    // paddingRight: theme.spacing(2),
  },
  cmButton: {
    float: "right",
    // marginRight: theme.spacing(2),
  },
});

function ConfirmCheck(props) {
  const [error, setError] = useState(false);
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={props.open}
        onClose={props.close}
      >
        <DialogTitle id="alert-dialog-title">确认操作</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.init ? (
              <div>应相关政策变动，提现需要同意协议</div>
            ) : (
              <div>{`确定提现${props.amount}元`}</div>
            )}

            <FormControlLabel
              style={{ marginTop: "10px" }}
              control={
                <Checkbox
                  color="primary"
                  checked={props.check}
                  onChange={(e) => {
                    setError(false);
                    props.changeCheck(e);
                  }}
                  name="isAgreed"
                />
              }
              label={
                <sapn>
                  我已阅读并同意
                  <a
                    target="_blank"
                    style={{ color: "#cfae51", textDecoration: "underline" }}
                    href={agreementPath}
                  >
                    &nbsp;《自由职业者服务协议》&nbsp;
                  </a>
                </sapn>
              }
            />
            {error && (
              <FormHelperText error={true}>请勾选同意协议内容！</FormHelperText>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            取消
          </Button>
          <Button
            onClick={() => {
              if (!props.check) {
                setError(true);
                return;
              }
              props.confirm();
              props.close();
            }}
            color="primary"
          >
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function MyReward(props) {
  const {
    classes,
    onRequire,
    getWithdrawLogsLoading,
    list,
    cash,
    submitFapiao,
    collectionMethods,
    onAddWithdrawRequest,
    certificationStatus,
    messageError,
    fullScreen,
    onSetCollectionMethodType,
    onAddUnionPay,
    onAddCollectionMethod,
    onResetOpenid,
    onCheckOpenid,
    confirm,
    notifications,
    unionpayMethods,
    onAddUnionpay,
    onDeleteUnionpay,
    onAddInvoiceRequest,
  } = props;
  const assetsData = {
    title: "我的资产",
    total: cashToRMB(cash.cash) + cashToRMB(cash.frozen_cash),
    details: [
      {
        name: "可提现",
        amount: cashToRMB(cash.cash),
      },
      {
        name: "待解冻",
        amount: cashToRMB(cash.frozen_cash),
      },
    ],
    history: {
      title: "账单",
      linkTo: "/home/my_bill",
    },
  };
  /**
   * formOpenType
   * 1: 银联
   * 2：微信
   * 3：支付宝
   * 4：发票税率
   * 5：填写发票号
   */
  const [formOpenType, setFormOpenType] = useState(0);
  const [formData, setFormData] = useState({});
  const [checkOpenidID, setCheckOpenidID] = useState(0);
  const [withdrawAmountStr, setWithdrawAmountStr] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [jumpToCertificationOpen, setJumpToCertificationOpen] = useState(false);
  const [withdrawInfo, setWithdrawInfo] = useState({});
  useEffect(() => {
    onRequire();
  }, []);

  // useEffect(() => {
  //   if (list && list.length) {
  //     if (
  //       list.some((i) => {
  //         const data = new Date(i.created_at);
  //         const year = data.getFullYear();
  //         const month = data.getMonth() + 1;
  //         const day = data.getDate();
  //         console.log(year, month, day, i.description);
  //         if (
  //           year === 2024 &&
  //           month >= 8 &&
  //           day >= 1 &&
  //           i.description === "申请"
  //         ) {
  //           return true;
  //         }
  //       })
  //     ) {
  //       setAgreementInit(true);
  //       setOpenConfirm(true);
  //     }
  //   }
  // }, [list]);

  let unionpayCM = null,
    wechatpayCM = null,
    alipayCM = null,
    fapiaoCM,
    activeCM = null;
  const [selectedValue, setSelectedValue] = React.useState("a");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  let activeType = 0;
  collectionMethods.forEach((v) => {
    switch (v.type) {
      case 1:
        unionpayCM = v;
        break;
      case 2:
        wechatpayCM = v;
        break;
      case 3:
        alipayCM = v;
        break;
      case 4:
        fapiaoCM = v;
        break;
      default:
        break;
    }
    if (v.type > 0 && v.type === v.active_type) {
      activeCM = v;
      activeType = v.type;
    }
  });
  const pendingSum = {
    unionpay: 0,
    wechat: 0,
    alipay: 0,
    fapiao: 0,
    amount: 0,
  };
  for (let log of list) {
    if (
      (log.status === 1 ||
        log.status === 2 ||
        log.status === 4 ||
        log.status === 5) &&
      new Date(log.created_at) > new Date().setDate(1)
    ) {
      switch (log.type) {
        case 1:
          pendingSum.unionpay++;
          break;
        case 2:
          pendingSum.wechat++;
          break;
        case 3:
          pendingSum.alipay++;
          break;
        case 4:
          pendingSum.fapiao++;
          break;
        default:
          break;
      }
      pendingSum.amount += log.cash;
    }
  }
  pendingSum.amount = Math.ceil(pendingSum.amount / 100);

  const jumpToCertificationDialog = (
    <Dialog open={jumpToCertificationOpen}>
      <DialogTitle>尚未通过实名认证</DialogTitle>
      <DialogContent>
        {`您尚未通过实名认证，无法提取，是否前往实名认证页面?`}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setJumpToCertificationOpen(false)}
          color="primary"
        >
          取消
        </Button>
        <Link to={"/home/certification"}>
          <Button color="primary" autoFocus>
            确定
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  );

  const [agreementInit, setAgreementInit] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const confirmData = useRef({});

  const handleChangeCheck = (event) => {
    const isAgreed = event.target.checked;
    confirmData.current.isAgreed = isAgreed;
    setIsAgreed(isAgreed);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirm = () => {
    onAddWithdrawRequest(confirmData.current);
  };

  function onWithdraw(
    activeCM,
    selectedValue,
    { unionpay, alipay, wechat, fapiao, amount: pendingAmount }
  ) {
    if (certificationStatus !== 1) {
      setJumpToCertificationOpen(true);
      return;
    }
    if (!selectedValue) {
      messageError("请先设置并选择收款方式");
      return;
    }
    // (activeCM.type !== 2 && activeCM.type !== 4)  ->
    if (selectedValue[0] !== "1" && selectedValue[0] !== "4") {
      messageError("提现方式暂仅支持银行和发票对公转账");
      return;
    }

    //时间
    const now = moment();
    if (
      now.date() < 25 ||
      now.date() > 28 ||
      now.hours() < 9 ||
      now.hours() >= 17
    ) {
      messageError("每月提现，请在25-28日（9点-17点）申请");
      return;
    }
    // 原先公对公为 1-10号，但现在为 16-19号
    // if (activeCM.type === 4 && now.date() > 10 ){
    //   messageError("发票对公转账，请在每月1-10日申请");
    //   return;
    // }

    let amount = withdrawAmount;
    let maxAmountThisTime = cashToRMB(cash.cash) - pendingAmount;
    if (amount + pendingAmount > cashToRMB(cash.cash)) {
      messageError(
        `本次提现金额加上待审核金额大于余额，您这次最多可提 ${maxAmountThisTime}元`
      );
      return;
    }
    // if(amount + pendingAmount > 50000){
    //   messageError(`超过每月提现总额上限（50000元），您这次最多可提 ${maxAmountThisTime}元`);
    //   return;
    // }
    const typeInfo = selectedValue.split("-");
    switch (typeInfo[0]) {
      case "1":
        if (unionpay >= 1) {
          messageError("银联提现超过每月次数上限（1次）");
          return;
        }
        if (amount < 50) {
          messageError("请输入50以上的提现金额");
          return;
        }
        if (
          !unionpayMethods.every((v) => {
            if (
              v.id === typeInfo[1] &&
              !(
                v.name &&
                v.phone &&
                v.id_card &&
                v.account &&
                v.bank &&
                v.branch
              )
            ) {
              messageError(
                "请完整填写银行信息！需与银行卡开户信息相同（姓名、电话、身份证号、银行卡...）"
              );
              return false;
            }
            return true;
          })
        ) {
          return;
        }
        break;
      case "2":
        if (wechat >= 1) {
          messageError("微信提现超过每月次数上限（1次）");
          return;
        }
        if (amount < 50 || amount > 200) {
          messageError("请输入50-200的提现金额");
          return;
        }
        break;
      case "3":
        if (alipay >= 1) {
          messageError("支付宝提现超过每月次数上限（1次）");
          return;
        }
        if (amount < 50 || amount > 50000) {
          messageError("请输入50-50000的提现金额");
          return;
        }
        break;
      case "4":
        if (fapiao >= 1) {
          messageError("发票对公转账超过每月次数上限（1次）");
          return;
        }
        if (amount < 50) {
          messageError("请输入大于50的提现金额");
          return;
        }
        // 填写发票税率
        /*
                  请求时传入
                  invoice_number
                  company
                  phone
                  同步等待、返回typeID
                */
        // 需要弹出一个框框
        break;
      default:
        messageError("不支持的提现方式！");
        return;
    }
    setWithdrawInfo({
      amount: amount,
      type: typeInfo[0],
      typeID: typeInfo[1],
    });
    /*
          amount
          type
          type_id
          tax_rate
        */
    if (typeInfo[0] === "4") {
      setFormOpenType(4);
      return;
    }
    if (!typeInfo[1]) {
      messageError("出现错误");
      return;
    }
    setOpenConfirm(true);
    confirmData.current = {
      amount: amount,
      type: typeInfo[0],
      typeID: typeInfo[1],
      isAgreed: isAgreed,
    };
  }

  const setOpenid = (openid) => {
    clearInterval(checkOpenidID);
    setFormData({
      ...formData,
      openid: openid,
    });
  };

  const onOpenForm = (type, data) => {
    if (type === 2) {
      onResetOpenid();
      setFormOpenType(type);
      setFormData({ ...data });
      setCheckOpenidID(
        window.setInterval(() => onCheckOpenid(setOpenid), 2000)
      );
    } else if (type === 1) {
      setFormOpenType(type);
      setFormData({ ...data });
    } else if (type === 3) {
      setFormOpenType(type);
      setFormData({ ...data });
    } else if (type === 4) {
      setFormOpenType(type);
      setFormData({ ...data });
    } else if (type === 5) {
      setFormOpenType(type);
      setFormData({ ...data });
    }
  };

  const onChangeForm = (form) => {
    setFormData({ ...form });
  };

  const onCloseForm = () => {
    clearInterval(checkOpenidID);
    setFormOpenType(0);
    setFormData({});
  };

  const onSubmitForm = (type) => {
    clearInterval(checkOpenidID);
    onAddCollectionMethod({
      ...formData,
      type: type,
    });
    setFormOpenType(0);
    setFormData({});
  };
  const onSubmitInvoice = (withdrawInfo) => {
    onAddInvoiceRequest({
      withdrawInfo,
      invoiceInfo: {
        ...formData,
      },
    });
    setFormOpenType(0);
    setFormData({});
  };
  const onSubmitUnionPay = () => {
    clearInterval(checkOpenidID);
    onAddUnionpay({
      ...formData,
      type: 1,
    });
    setFormOpenType(0);
    setFormData({});
  };
  const onSubmitFapiao = () => {
    submitFapiao({
      ...formData,
    });
    setFormOpenType(0);
    setFormData({});
  };

  const commonFormParam = {
    fullScreen: fullScreen,
    onCancel: onCloseForm,
    onChange: onChangeForm,
    form: formData,
  };
  const unionpayFormParam = {
    ...commonFormParam,
    onOk: () => onSubmitUnionPay(),
    open: formOpenType === 1,
    title: "设置银行卡信息",
  };
  const wechatpayFormParam = {
    ...commonFormParam,
    onOk: () => onSubmitForm(2),
    open: formOpenType === 2,
    title: "设置微信",
  };
  const alipayFormParam = {
    ...commonFormParam,
    onOk: () => onSubmitForm(3),
    open: formOpenType === 3,
    title: "设置支付宝信息",
  };
  const fapiaoTaxRateFormParam = {
    ...commonFormParam,
    onOk: () => onSubmitInvoice(withdrawInfo),
    open: formOpenType === 4,
  };
  const fapiaoNumberFormParam = {
    ...commonFormParam,
    open: formOpenType === 5,
    onOk: onSubmitFapiao,
  };

  function onBlur() {
    let amount = parseInt(withdrawAmountStr) || 0;
    setWithdrawAmountStr(amount ? amount.toString() : "");
    setWithdrawAmount(amount);
  }

  // const withdrawStatusMap = {
  //   0: "预生成",
  //   1: "申请",
  //   2: "通过",
  //   3: "拒绝",
  //   4: "转账中",
  //   5: "转账成功",
  //   6: "转账失败",
  //   7: "错误",
  // };
  const myWithdrawLogsTableParam = {
    list: [...list]
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((v) => ({
        ...v,
        total: cashToRMB(v.amount),
        tax: cashToRMB((v.amount * v.tax_rate) / 100),
        actual: cashToRMB(v.amount) - cashToRMB((v.amount * v.tax_rate) / 100),
        date: new Date(v.created_at).getTime(),
        status: v.description,
        //  ? (v.status === 1 && v.type === 4 && !v.fapiao? "待填写发票号":withdrawStatusMap[v.status || 0]) : (moment().unix() - (v.created_at || 0) > 30 * 60 ? "已失效" : withdrawStatusMap[0]),
        onClick:
          v.invoice_status === 1 &&
          new Date(v.created_at) > new Date().setDate(1)
            ? () => {
                onOpenForm(5, v);
              }
            : null,
      })),
    loading: getWithdrawLogsLoading,
    render: (v, i) => (
      <ListItem
        key={i}
        status={v.status}
        total={v.total}
        tax={v.tax}
        actual={v.actual}
        date={v.date}
        onClick={v.onClick}
      />
    ),
  };
  return (
    <div className="Home">
      <Assets data={assetsData} />
      <EasyAnnounce notifications={notifications} />
      <Title>申请提现</Title>
      <Paper className={classes.inputPaper}>
        <InputBase
          className={classes.input}
          placeholder={`可提现：${Math.min(cashToRMB(cash.cash), 100000)}`}
          value={withdrawAmountStr}
          onChange={(e) => setWithdrawAmountStr(e.target.value)}
          onBlur={onBlur}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          onClick={() => onWithdraw(activeCM, selectedValue, pendingSum)}
          color="primary"
        >
          <RightArrowIcon />
        </IconButton>
      </Paper>
      <Title>收款方式</Title>
      <SimpleTitle>银行卡</SimpleTitle>
      {unionpayMethods &&
        unionpayMethods.map((v, i) => {
          return (
            <div className={classes.cmDiv} key={"1-" + v.id}>
              <Radio
                checked={selectedValue === "1-" + v.id}
                onChange={handleChange}
                value={"1-" + v.id}
                color="primary"
                name="active-type"
                inputProps={{ "aria-label": "银联转账" }}
                size="small"
              />
              <span className={classes.cmTitle}>
                <svg className="icon" aria-hidden="true">
                  <use xlinkHref="#icon-zhifupingtai-yinlian"></use>
                </svg>
                银联转账
              </span>
              <Button
                className={classes.cmButton}
                variant="outlined"
                color="primary"
                onClick={() => {
                  confirm(`确认删除当前信息吗？ 卡号:${v.account}`, () =>
                    onDeleteUnionpay(v || {})
                  );
                }}
              >
                删除
              </Button>
              <Button
                className={classes.cmButton}
                variant="outlined"
                color="primary"
                onClick={() => {
                  onOpenForm(1, v || {});
                }}
              >
                修改
              </Button>
              {v && (
                <Typography>
                  <span className={classes.cmContent}> {v.name || ""} </span>
                  <span className={classes.cmContent}> {v.phone || ""} </span>
                  <span className={classes.cmContent}> {v.id_card || ""} </span>
                  <span className={classes.cmContent}> {v.account || ""} </span>
                  <span className={classes.cmContent}> {v.bank || ""} </span>
                  <span className={classes.cmContent}> {v.branch || ""} </span>
                </Typography>
              )}
            </div>
          );
        })}
      <div className={classes.cmDiv}>
        <Radio
          // checked={activeType === 1}
          // onChange={onActiveTypeChange(activeType)}
          value="1"
          color="primary"
          name="active-type"
          inputProps={{ "aria-label": "银联转账" }}
          size="small"
          disabled={true}
        />
        <span className={classes.cmTitle}>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-zhifupingtai-yinlian"></use>
          </svg>
          银联转账
        </span>
        <Button
          className={classes.cmButton}
          variant="outlined"
          color="primary"
          onClick={() => {
            onOpenForm(1, "" || {});
          }}
        >
          添加
        </Button>
      </div>

      {/* <div className={classes.cmDiv}>
        <Radio
          checked={activeType === 3}
          onChange={onActiveTypeChange(activeType)}
          value="3"
          color="primary"
          name="active-type"
          inputProps={{'aria-label': '支付宝转账'}}
          size="small"
          disabled={!alipayCM}
        />
        <span className={classes.cmTitle}>
                    <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-zhifubao"></use>
                </svg>支付宝转账</span>
        {alipayCM && <React.Fragment>
          <span className={classes.cmContent}>{alipayCM.name || ""}</span>
        </React.Fragment>}
        <Button className={classes.cmButton} variant="outlined" color="primary"
                onClick={() => {onOpenForm(3, alipayCM || {})}}
        >{alipayCM ? "修改" : "添加"}</Button>
      </div> */}
      <SimpleTitle>发票</SimpleTitle>
      <div className={classes.cmDiv}>
        <Radio
          // checked={activeType === 4}
          // onChange={onActiveTypeChange(activeType)}
          checked={selectedValue === "4"}
          onChange={handleChange}
          value="4"
          color="primary"
          name="active-type"
          inputProps={{ "aria-label": "对公转账" }}
          size="small"
        />
        <span className={classes.cmTitle}>
          <CreditCard className={classes.cmIcon} />
          发票对公转账
        </span>
      </div>
      {/* <Button onClick={()=>setFormOpenType(4)}>aa</Button> */}

      <Title>提现记录</Title>
      <Table {...myWithdrawLogsTableParam} />
      <UnionpayForm {...unionpayFormParam} />
      <WechatpayForm {...wechatpayFormParam} />
      <AlipayForm {...alipayFormParam} />
      <FapiaoTaxRateForm {...fapiaoTaxRateFormParam} />
      <FapiaoNumberForm {...fapiaoNumberFormParam} />
      {jumpToCertificationDialog}
      <ConfirmCheck
        init={agreementInit}
        amount={withdrawAmount}
        check={isAgreed}
        open={openConfirm}
        close={handleCloseConfirm}
        confirm={handleConfirm}
        changeCheck={handleChangeCheck}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cash: state.userMine.cash || {},
    getWithdrawLogsLoading: state.userMine.getWithdrawLogsStatus === "loading",
    list: state.userMine.withdrawLogs || [],
    certificationStatus: state.user.certificationStatus || 0,
    collectionMethods: state.userMine.collectionMethods || [],
    notifications: (state.utils.notifications || {})["提现"] || [],
    unionpayMethods: state.userMine.unionpayMethods || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(getCash());
      dispatch(getWithdrawLogs());
      dispatch(fetchCertificationStatus());
      // dispatch(getCollectionMethod());
      dispatch(getUnionpay());
      dispatch(fetchNotifications("提现"));
    },
    onCheckRequest: (id, clearInterval) => {
      dispatch(
        checkWithdrawLog(id, {
          success: (result) => {
            if (result.status) {
              clearInterval();
              dispatch(getWithdrawLogs());
            }
          },
          error: () => {},
        })
      );
    },
    onSetCollectionMethodType: (type) => {
      dispatch(
        setCollectionMethodType(type, {
          success: () => {
            dispatch(getCollectionMethod());
          },
          error: () => {
            dispatch(messageError("设置失败"));
          },
        })
      );
    },
    onAddCollectionMethod: (data) => {
      dispatch(
        addCollectionMethod(data, {
          success: () => {
            dispatch(messageOk("设置成功"));
            dispatch(getCollectionMethod());
          },
          error: () => {
            dispatch(messageError("设置失败"));
          },
        })
      );
    },
    onAddUnionpay: (data) => {
      dispatch(
        addUnionpay(data, {
          success: () => {
            dispatch(messageOk("设置成功"));
            dispatch(getUnionpay());
          },
          error: () => {
            dispatch(messageError("设置失败"));
          },
        })
      );
    },
    onDeleteUnionpay: (data) => {
      dispatch(
        deleteUnionpay(data, {
          success: () => {
            dispatch(messageOk("删除成功"));
            dispatch(getUnionpay());
          },
          error: () => {
            dispatch(messageError("删除失败"));
          },
        })
      );
    },
    onResetOpenid: () => {
      dispatch(resetOpenid());
    },
    onCheckOpenid: (setOpenid) => {
      dispatch(
        getOpenid({
          success: (result) => {
            if (result) {
              dispatch(messageOk("openid 上传成功"));
              setOpenid(result);
            }
          },
          error: () => {},
        })
      );
    },

    onAddWithdrawRequest: (withdrawInfo) => {
      dispatch(
        addWithdrawRequest(withdrawInfo, {
          success: () => {
            dispatch(messageOk("提交成功"));
            dispatch(getWithdrawLogs());
          },
          error: () => {
            dispatch(messageError("提交失败"));
          },
        })
      );
    },
    onAddInvoiceRequest: ({ withdrawInfo, invoiceInfo }) => {
      dispatch(
        addInvoice(
          invoiceInfo.invoiceNumber,
          invoiceInfo.phone,
          invoiceInfo.company,
          {
            success: (data) => {
              dispatch(
                addWithdrawRequest(
                  {
                    amount: withdrawInfo.amount,
                    type: 4,
                    typeID: data,
                    taxRate: invoiceInfo.tax_rate,
                  },
                  {
                    success: () => {
                      dispatch(messageOk("提交成功"));
                      dispatch(getWithdrawLogs());
                    },
                    error: () => {
                      dispatch(messageError("提交失败"));
                    },
                  }
                )
              );
            },
            error: () => {
              dispatch(messageError("提交失败"));
            },
          }
        )
      );
    },
    submitFapiao: (form) => {
      dispatch(
        setFapiao(form, {
          success: () => {
            dispatch(messageOk("提交成功"));
            dispatch(getWithdrawLogs());
          },
          error: () => {
            dispatch(messageError("提交失败"));
          },
        })
      );
    },
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
    messageError: (dataStr) => {
      dispatch(messageError(dataStr));
    },
    confirm: (message, callback, hasVcode) => {
      dispatch(confirm(message, callback, hasVcode));
    },
  };
};

export default withMobileDialog()(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyReward))
);
