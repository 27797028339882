import xhr from "../../Xhr";

export const messageOk = (dataStr, closeCallBack) => ({
  type: "UTILS/MESSAGE/OK",
  dataStr: dataStr,
  closeCallBack,
});
export const messageError = (dataStr) => ({
  type: "UTILS/MESSAGE/ERROR",
  dataStr: dataStr,
});
export const messageWarning = (dataStr) => ({
  type: "UTILS/MESSAGE/WARNING",
  dataStr: dataStr,
});
export const messageInfo = (dataStr) => ({
  type: "UTILS/MESSAGE/INFO",
  dataStr: dataStr,
});
export const messageLoading = (dataStr) => ({
  type: "UTILS/MESSAGE/LOADING",
  dataStr: dataStr,
});
export const closeMessageOk = () => ({
  type: "UTILS/MESSAGE/OK/CLOSE",
});
export const closeMessageError = () => ({
  type: "UTILS/MESSAGE/ERROR/CLOSE",
});
export const closeMessageWarning = () => ({
  type: "UTILS/MESSAGE/WARNING/CLOSE",
});
export const closeMessageInfo = () => ({
  type: "UTILS/MESSAGE/INFO/CLOSE",
});
export const closeMessageLoading = () => ({
  type: "UTILS/MESSAGE/LOADING/CLOSE",
});
export const confirm = (message, callback, hasVcode) => ({
  type: "UTILS/CONFIRM/OPEN",
  message: message,
  callback: callback,
  hasVcode: hasVcode,
});
export const closeConfirm = () => ({
  type: "UTILS/CONFIRM/CLOSE",
});
export const fetchRewardStatistics = () => {
  return xhr({
    api: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/reward/statistics`,
      method: "GET",
    },
    action: "UTILS/REWARD_STATISTICS",
  });
};
export const fetchTop5 = () => {
  return xhr({
    api: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/top5`,
      method: "GET",
    },
    action: "UTILS/TOP5",
  });
};

export const fetchNotifications = (page) => {
  return xhr({
    api: {
      url:
        process.env.REACT_APP_NMSAPI_SERVER +
        `/openfogos/page_notification?page=${page}`,
      method: "GET",
    },
    action: "UTILS/NOTIFICATIONS",
    data: page, //本地标记用
  });
};
