import React from "react";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import IconAddShopping from "@material-ui/icons/AddShoppingCart";
import { view as UserCoin } from "../coin/";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import IconHelp from "@material-ui/icons/HelpOutline";

const styles = (theme) => ({
  card: {
    width: 300,
    display: "inline-block",
    margin: theme.spacing(1),
  },
  media: {
    height: 185,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
  gap: {
    height: theme.spacing(2),
  },
});

class UserStore extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          <Typography variant="subtitle1" display="inline">
            <IconAddShopping
              className={classNames(classes.verticalMiddle, classes.leftIcon)}
            />
            <span className={classes.verticalMiddle}>半价商城</span>
          </Typography>
          <Typography
            variant="caption"
            display="inline"
            className={classes.verticalMiddle}
          >
            <Tooltip
              title="用户需支付RMB和PRC来购买商品，支付的PRC会按照用户每日收益逐渐返回给用户"
              placement="right"
            >
              <span className={classes.verticalMiddle}>
                &emsp;全新商城即将上线
                <IconHelp
                  className={classes.verticalMiddle}
                  color="action"
                  style={{ fontSize: 16 }}
                />
              </span>
            </Tooltip>
          </Typography>
        </div>

        {/*商城维护中，暂不开放购买。*/}
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image="/goods1.png"
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                腾讯视频 VIP 月卡
              </Typography>
              <Typography variant="body1">?.9 RMB + ?? PRC</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image="/jde.png"
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                50元京东E卡
              </Typography>
              <Typography variant="body1">?4.9 RMB + ?? PRC</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image="/goods2.png"
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                newifi 3
              </Typography>
              <Typography variant="body1">?9.9 RMB + ?? PRC</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image="/disk.png"
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                500GB 硬盘
              </Typography>
              <Typography variant="body1">?9.9 RMB + ?? PRC</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        {/*<markdown to={{pathname: "/home/store/goods1", state: {userId: localStorage.getItem('nms_userid'), balance: 100}}}>
                  <Card className={classes.card}>
                      <CardActionArea>
                          <CardMedia
                              className={classes.media}
                              image="/goods1.png"
                              title="Contemplative Reptile"
                          />
                          <CardContent>
                              <Typography gutterBottom variant="h6" component="h2">
                                  腾讯视频 VIP 月卡
                              </Typography>
                              <Typography component="p">
                                  15 PRC
                              </Typography>
                          </CardContent>
                      </CardActionArea>
                  </Card>
              </markdown>
              <markdown to={{pathname: "/home/store/goods2", state: {userId: localStorage.getItem('nms_userid'), balance: 100}}}>
                  <Card className={classes.card}>
                      <CardActionArea>
                          <CardMedia
                              className={classes.media}
                              image="/goods2.png"
                              title="Contemplative Reptile"
                          />
                          <CardContent>
                              <Typography gutterBottom variant="h6" component="h2">
                                  newifi 3
                              </Typography>
                              <Typography component="p">
                                  70 PRC
                              </Typography>
                          </CardContent>
                      </CardActionArea>
                  </Card>
              </markdown>*/}
        <div className={classes.gap}></div>
        <UserCoin />
      </div>
    );
  }
}
UserStore.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(UserStore));
