import React from "react";
import { connect } from "react-redux";
import { logout } from "../../../modules/auth/actions";
import IconButton from "@material-ui/core/IconButton";
import ExitIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";

class LogoutBox extends React.Component {
  render() {
    return (
      <Tooltip title={"退出登录"}>
        <IconButton
          color="inherit"
          onClick={this.props.onLogout}
          style={{ color: "#a68540" }}
        >
          <ExitIcon />
        </IconButton>
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      dispatch(logout({}));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutBox);
