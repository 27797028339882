import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Footer, Header } from "../../common";
import { HomeRoutes } from "../../Routes";

const { HomeHeader } = Header;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    // marginTop: theme.spacing( 2),
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  main: {
    minWidth: 275,
    maxWidth: 1000,
    marginLeft: "auto",
    marginRight: "auto",
    // padding: theme.spacing( 2),
    // background: '#fff',
    zIndex: -999,
    // position: 'relative'
  },
  footer: {
    marginTop: theme.spacing(5),
  },
}));

const HomeLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <HomeHeader />

      <div className={classes.content}>
        <div className={classes.main}>
          <HomeRoutes />
          <div className={classes.footer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLayout;
/*
import React from 'react'
import { Layout } from 'antd'
import { Header, Footer } from '../common/'
import { HomeRoutes } from '../Route'

const { HomeHeader } = Header
const { Content } = Layout

const HomeLayout = (props) => {
  return (
    <Layout>
      <HomeHeader />
      <Layout className="ant-layout-has-sider">
        {/!*<Sider {...props} />*!/}
        <Layout>
          <Content style={{padding: 20}}>
            <HomeRoutes />
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default HomeLayout
*/
