/*
 * @Author: Tperam
 * @Date: 2022-03-14 14:36:47
 * @LastEditTime: 2022-03-14 15:55:26
 * @LastEditors: Tperam
 * @Description:
 * @FilePath: \openfogos\src\layout\qrcode.js
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

const useStyle = makeStyles(() => ({
  QRCodeDiv: {
    position: "fixed",
    right: "2rem",
    bottom: "2rem",
  },
  mobileQRCodeDiv: {
    position: "fixed",
    right: 0,
    bottom: 0,
  },
}));

function QRCodeDiv() {
  const classes = useStyle();
  const matches = useMediaQuery("(min-width:600px)");
  const divClass = matches ? classes.QRCodeDiv : classes.mobileQRCodeDiv;
  return (
    <div className={`${divClass}`}>
      <Wechatpublic />
    </div>
  );
}

function Wechatpublic() {
  return <img height="100" src="/qrcode_wechatpublic.jpg" />;
}

export default QRCodeDiv;
