import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

// import classNames from 'classnames';

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class BindForm extends Component {
  render() {
    const handleChange = (event) => {
      this.props.onChange({
        [event.target.name]: event.target.value,
      });
    };

    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            远程绑定节点
          </Typography>
          <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
            <TextField
              id="os_drive_serial"
              label="节点SN号"
              name="os_drive_serial"
              value={this.props.os_drive_serial || ""}
              margin="normal"
              autoFocus
              onChange={handleChange}
              fullWidth
            />
            <TextField
              id="node_id"
              label="节点NodeID"
              name="node_id"
              value={this.props.node_id || ""}
              margin="normal"
              error={this.props.node_id && this.props.node_id.length !== 22}
              helperText={
                this.props.node_id &&
                this.props.node_id.length !== 22 &&
                "请输入正确的node_id"
              }
              fullWidth
              required
              onChange={handleChange}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.props.onSubmit}
              className={classes.submit}
              disabled={this.props.disabled || this.props.loading}
              startIcon={
                this.props.loading && (
                  <CircularProgress color="inherit" size={20} />
                )
              }
              type="submit"
            >
              {"绑定" + (this.props.loading ? "中..." : "")}
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(BindForm);
