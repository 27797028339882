import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { fetchNotifications, messageOk } from "../../utils/actions";
import { connect } from "react-redux";
import { reducerStatus } from "../../../modules/user/reducer";
import { fetchCertificationStatus } from "../actions";
import Form from "./form";
import EasyAnnounce from "../../../modules/easyAnnounce";

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing( 2),
  },
  button: {
    width: "100%",
    size: "medium",
  },
  input: {
    width: "100%",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
});

const certificationStatusCaption = {
  0: "提交实名认证",
  1: "实名认证通过",
  2: "实名认证审核中",
  3: "实名认证失败，请重新提交",
};
class Certification extends React.Component {
  render() {
    const {
      classes,
      certificationStatus,
      certificationStatusStatus,
      notifications,
    } = this.props;
    const titleProps = {
      gutterBottom: true,
      align: "center",
      variant: "h6",
    };
    switch (certificationStatusStatus) {
      case reducerStatus.LOADING:
        return (
          <div>
            <Typography {...titleProps}>加载中...</Typography>
            <EasyAnnounce notifications={notifications} />
          </div>
        );
      case reducerStatus.FAILURE:
        return (
          <div>
            <Typography {...titleProps}>查询失败</Typography>
            <EasyAnnounce notifications={notifications} />
          </div>
        );
      case reducerStatus.SUCCESS:
        switch (certificationStatus) {
          case 0:
            return (
              <div>
                <Typography {...titleProps}>
                  {certificationStatusCaption[0]}
                </Typography>
                <EasyAnnounce notifications={notifications} />
                <Form />
              </div>
            );
          case 1:
            return (
              <div>
                <Typography {...titleProps}>
                  {certificationStatusCaption[1]}
                </Typography>
                <EasyAnnounce notifications={notifications} />
              </div>
            );
          case 2:
            return (
              <div>
                <Typography {...titleProps}>
                  {certificationStatusCaption[2]}
                </Typography>
                <EasyAnnounce notifications={notifications} />
              </div>
            );
          case 3:
            return (
              <div>
                <Typography {...titleProps}>
                  {certificationStatusCaption[3]}
                </Typography>
                <EasyAnnounce notifications={notifications} />
                <Form />
              </div>
            );
        }
        return (
          <div>
            查询失败
            <EasyAnnounce notifications={notifications} />
          </div>
        );
      default:
        return null;
    }
  }
  componentDidMount() {
    this.props.onRequire();
  }
}

Certification.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    certificationStatus: state.user.certificationStatus,
    certificationStatusStatus:
      state.user.certificationStatusStatus || reducerStatus.LOADING,
    notifications: (state.utils.notifications || {})["实名认证"] || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(fetchCertificationStatus());
      dispatch(fetchNotifications("实名认证"));
    },
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Certification));
