import React, { useEffect } from "react";
import Bandwidth from "../../displayBandwidth";
import { connect } from "react-redux";
import moment from "moment";
import {
  getBasicNodeChargingBWsLastMonth,
  getBasicNodeChargingBWsLastWeek,
  getBasicNodeIOToday,
  getBasicNodeIOYesterday,
  getBasicNodeTrafficToday,
  getBasicNodeTrafficYesterday,
} from "../../../modules/node/actions";

function BandwidthTodayInner(props) {
  const { nodeInfo, getToday, trafficToday, cpuIoToday } = props;
  useEffect(() => {
    if (nodeInfo && nodeInfo.mac) {
      getToday(nodeInfo.mac);
    }
  }, [nodeInfo]);

  let data = trafficToday
    .map((v) => (v >= 0 ? v : 0))
    .map((v, i) => ({
      label: moment()
        .startOf("day")
        .add(i * 5, "m")
        .format("HH:mm"),
      bw: (v * 8) / 300 / 1024,
    }));
  if (cpuIoToday) {
    const cpuUtil = cpuIoToday.cpu_util_abnormal_times || Array(288).fill(0);
    const ioUtil = cpuIoToday.io_util_abnormal_times || Array(288).fill(0);
    const ioReadAwait =
      cpuIoToday.io_read_await_abnormal_times || Array(288).fill(0);
    const ioWriteAwait =
      cpuIoToday.io_write_await_abnormal_times || Array(288).fill(0);
    const online = cpuIoToday.online || Array(288).fill(0);
    data = data.map((v, i) => ({
      ...v,
      cpu: cpuUtil[i] || 0,
      io: ioUtil[i] || 0,
      ioRead: ioReadAwait[i] || 0,
      ioWrite: ioWriteAwait[i] || 0,
      online: online[i] || 0,
    }));
  }
  return <Bandwidth data={data} bwOnly={false} />;
}

function BandwidthYesterdayInner(props) {
  const { nodeInfo, getYesterday, trafficYesterday, cpuIoYesterday } = props;
  useEffect(() => {
    if (nodeInfo && nodeInfo.mac) {
      getYesterday(nodeInfo.mac);
    }
  }, [nodeInfo]);

  let data = trafficYesterday
    .map((v) => (v >= 0 ? v : 0))
    .map((v, i) => ({
      label: moment()
        .startOf("day")
        .add(i * 5, "m")
        .format("HH:mm"),
      bw: (v * 8) / 300 / 1024,
    }));
  if (cpuIoYesterday) {
    const cpuUtil =
      cpuIoYesterday.cpu_util_abnormal_times || Array(288).fill(0);
    const ioUtil = cpuIoYesterday.io_util_abnormal_times || Array(288).fill(0);
    const ioReadAwait =
      cpuIoYesterday.io_read_await_abnormal_times || Array(288).fill(0);
    const ioWriteAwait =
      cpuIoYesterday.io_write_await_abnormal_times || Array(288).fill(0);
    const online = cpuIoYesterday.online || Array(288).fill(0);
    data = data.map((v, i) => ({
      ...v,
      cpu: cpuUtil[i] || 0,
      io: ioUtil[i] || 0,
      ioRead: ioReadAwait[i] || 0,
      ioWrite: ioWriteAwait[i] || 0,
      online: online[i] || 0,
    }));
  }
  return <Bandwidth data={data} bwOnly={false} />;
}

function BandwidthLastWeekInner(props) {
  const { nodeInfo, getLastWeek, chargingBWsLastWeek } = props;
  useEffect(() => {
    if (nodeInfo && nodeInfo.mac) {
      getLastWeek(nodeInfo.mac);
    }
  }, [nodeInfo]);

  const data = chargingBWsLastWeek
    .map((v) => (v >= 0 ? v : 0))
    .map((v, i) => ({
      label: moment()
        .subtract(7 - i, "d")
        .format("M-DD"),
      bw: v,
    }));
  return <Bandwidth data={data} bwOnly={true} />;
}

function BandwidthLastMonthInner(props) {
  const { nodeInfo, getLastMonth, chargingBWsLastMonth } = props;
  useEffect(() => {
    if (nodeInfo && nodeInfo.mac) {
      getLastMonth(nodeInfo.mac);
    }
  }, [nodeInfo]);

  const data = chargingBWsLastMonth
    .map((v) => (v >= 0 ? v : 0))
    .map((v, i) => ({
      label: moment()
        .subtract(30 - i, "d")
        .format("M-DD"),
      bw: v,
    }));

  return <Bandwidth data={data} bwOnly={true} />;
}

const mapStateToProps = (state) => {
  return {
    trafficToday: state.node.basicNodeTrafficToday || [],
    cpuIoToday: state.node.basicNodeCPUIOToday || {},
    trafficYesterday: state.node.basicNodeTrafficYesterday || [],
    cpuIoYesterday: state.node.basicNodeCPUIOYesterday || {},
    chargingBWsLastWeek: state.node.basicNodeChargingBWsLastWeek || [],
    chargingBWsLastMonth: state.node.basicNodeChargingBWsLastMonth || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getToday: (mac) => {
      dispatch(getBasicNodeTrafficToday(mac));
      dispatch(getBasicNodeIOToday(mac));
    },
    getYesterday: (mac) => {
      dispatch(getBasicNodeTrafficYesterday(mac));
      dispatch(getBasicNodeIOYesterday(mac));
    },
    getLastWeek: (mac) => {
      dispatch(getBasicNodeChargingBWsLastWeek(mac));
    },
    getLastMonth: (mac) => {
      dispatch(getBasicNodeChargingBWsLastMonth(mac));
    },
  };
};

export const BandwidthToday = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BandwidthTodayInner);
export const BandwidthYesterday = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BandwidthYesterdayInner);
export const BandwidthLastWeek = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BandwidthLastWeekInner);
export const BandwidthLastMonth = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BandwidthLastMonthInner);
