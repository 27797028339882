import React, { useEffect, useState } from "react";
import {
  confirm,
  fetchNotifications,
  messageError,
  messageOk,
} from "../../modules/utils/actions";
import { connect } from "react-redux";
import { Title } from "../../modules/title";
import Tab from "../../modules/tab";
import Table from "../../modules/table";
import {
  BasicNodeListItem,
  InviteeListItem,
  LinkListItem,
} from "../../modules/listItem";
import { BasicNodeDetail } from "../../modules/nodeDetail";
import {
  deleteMac,
  fetchInfo,
  fetchTotal,
  getInviterNodes,
  setLimit,
  setNickname,
} from "../../modules/node/actions";
import { cashToRMB } from "../../modules/utils/functions";
import EasyAnnounce from "../../modules/easyAnnounce";

function MyReward(props) {
  const {
    onRequire,
    onSetNickname,
    onDelete,
    confirm,
    list,
    total,
    setLimit,
    inviterNodes,
    myLoading,
    inviterLoading,
    notifications,
  } = props;
  const [openedNodeInfo, setOpenedNodeInfo] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    onRequire(page, rowsPerPage);
  }, []);

  const myNodesTableParam = {
    list: list.map((v) => ({
      ...v,
      mac: v.mac_addr,
      online: v.online,
      approved: (v.approved || 0) === 3,
      onlineTime: v.online_time || 0,
      rewardYesterday: cashToRMB(v.reward_yesterday),
      moreReward: cashToRMB(v.reward_yesterday) - cashToRMB(v.reward_ideal),
      cpu_cores: v.cpu_cores || 0,
      nodeLimit: {
        storage: v.limit_storage || 100,
        bandwidth: v.limit_bandwidth || 100,
        memory: v.limit_memory || 100,
        cpu: v.limit_cpu || 0,
      },
    })),
    loading: myLoading,
    total: total,
    page: page,
    length: rowsPerPage,
    onPageChange: (newValue) => {
      setPage(newValue);
      onRequire(newValue, rowsPerPage);
    },
    onRowsPerPageChange: (newValue) => {
      setPage(0);
      setRowsPerPage(newValue);
      onRequire(0, newValue);
    },
    render: (v) => (
      <BasicNodeListItem
        nodeInfo={v}
        key={v.mac}
        onClick={() => setOpenedNodeInfo(v)}
      />
    ),
  };
  const inviteeTableParam = {
    list: inviterNodes.map((v) => ({
      userName: v.user_name,
      nodes: v.node_num,
      rewardYesterday: cashToRMB(v.inviter_reward_yesterday),
    })),
    loading: inviterLoading,
    render: (v) => <InviteeListItem nodeInfo={{ ...v }} key={v.userName} />,
  };
  const bind_node_url =
    "http://118.25.127.105:8001/?p=" +
    localStorage.getItem("openfogos_userphone") +
    "&name=" +
    localStorage.getItem("openfogos_username") +
    "&src=" +
    window.location.href;

  return (
    <div>
      <Title>闲小节点</Title>
      <EasyAnnounce notifications={notifications} />
      <Tab
        data={
          inviterNodes.length > 0
            ? [
                {
                  label: "我的节点",
                  content: <Table {...myNodesTableParam} />,
                },
                {
                  label: "推广节点",
                  content: <Table {...inviteeTableParam} />,
                },
              ]
            : [
                {
                  label: "我的节点",
                  content: <Table {...myNodesTableParam} />,
                },
              ]
        }
      />
      <BasicNodeDetail
        open={openedNodeInfo != null}
        onClose={() => setOpenedNodeInfo(null)}
        nodeInfo={openedNodeInfo}
        onSaveLimit={(nodeLimit) => setLimit(nodeLimit, page, rowsPerPage)}
        onSetNickname={(mac, nickname) => onSetNickname(mac, nickname)}
        onDelete={(mac) =>
          confirm(
            `确定删除该节点？`,
            () => {
              onDelete(mac, page, rowsPerPage);
              setOpenedNodeInfo(null);
            },
            false,
          )
        }
      />
      <Title>添加节点</Title>
      <LinkListItem
        title="绑定本地节点"
        subtitle="将手机或电脑连接到节点所在的局域网，即可自动搜索绑定"
        useHref={true}
        href={bind_node_url}
        target="_self"
      />
      <LinkListItem
        title="绑定远程节点"
        subtitle="输入节点的 MAC 地址和 NodeID，即可绑定远程节点"
        to="/home/bind"
      />
      {/*<ActionListItem title="复制邀请链接" subtitle="朋友通过链接注册账号后，绑定的节点会成为你的推广节点" onClick={f=>f}/>*/}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.node.info || [],
    myLoading: state.node.infoStatus === "loading",
    inviterNodes: state.node.inviterNodes || [],
    inviterLoading: state.node.getInviterNodesStatus === "loading",
    total: state.node.totalNode || 0,
    loading: state.node.infoStatus === "loading",
    notifications: (state.utils.notifications || {})["闲小节点"] || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: (page, pageLength) => {
      dispatch(fetchInfo(page + 1, pageLength));
      dispatch(fetchTotal());
      dispatch(getInviterNodes());
      dispatch(fetchNotifications("闲小节点"));
    },
    setLimit: (data, page, pageLength) => {
      dispatch(
        setLimit({
          data,
          success: () => {
            dispatch(messageOk("修改成功"));
            dispatch(fetchInfo(page + 1, pageLength));
            dispatch(fetchTotal());
          },
          error: () => {
            dispatch(messageError("修改配置失败"));
          },
        }),
      );
    },
    onSetNickname: (mac, nickname) => {
      dispatch(
        setNickname(mac, nickname, {
          success: () => {
            dispatch(messageOk("设置成功"));
          },
          error: () => {
            dispatch(messageError("修改失败"));
          },
        }),
      );
    },
    onDelete: (mac, page, pageLength) => {
      dispatch(
        deleteMac(mac, {
          success: () => {
            dispatch(messageOk("删除成功"));
            dispatch(fetchInfo(page + 1, pageLength));
            dispatch(fetchTotal());
          },
          error: () => {
            dispatch(messageError("删除失败"));
          },
        }),
      );
    },
    confirm: (message, callback, hasVcode) => {
      dispatch(confirm(message, callback, hasVcode));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReward);
