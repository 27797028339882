/*
 * @Author: Tperam
 * @Date: 2021-04-25 10:18:05
 * @LastEditTime: 2021-11-12 15:51:07
 * @LastEditors: Tperam
 * @Description:
 * @FilePath: \openfogos\src\Store.js
 */
import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from "redux";
import { thunk } from "redux-thunk";
import { reducer as Auth } from "./modules/auth";
import { reducer as Node } from "./modules/node/";
import { reducer as User } from "./modules/user/";
import { reducer as Utils } from "./modules/utils/";
import { reducer as Miner } from "./modules/miner/";
import { reducer as BC } from "./modules/bc/";
import { reducer as userMine } from "./modules/userMine/";
import { reducer as pearbox } from "./modules/pearbox/";
const reducer = combineReducers({
  auth: Auth,
  node: Node,
  user: User,
  utils: Utils,
  miner: Miner,
  bc: BC,
  userMine: userMine,
  pearbox: pearbox,
});

const storeEnhancers = compose(
  applyMiddleware(thunk),
  window && window.devToolsExtension ? window.devToolsExtension() : (f) => f,
);

export default createStore(reducer, {}, storeEnhancers);
