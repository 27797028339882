/*
 * @Author: Tperam
 * @Date: 2021-04-25 10:18:05
 * @LastEditTime: 2022-03-18 11:58:31
 * @LastEditors: Tperam
 * @Description:
 * @FilePath: \openfogos\src\modules\utils\easyAnnounce.js
 */
import React from "react";
import Typography from "@material-ui/core/Typography";
import Announce from "./announce";

import ReactMarkdown from "react-markdown";

/**
 *   title: "通知",
 *   abstractColor: "#f00"
 *   abstract: "九月份是囧代分销三等分砍掉啊道街，发的赛快餐広。翻到从来光扫到草桑代非常"
 */
function EasyAnnounce(props) {
  const { notifications } = props;

  const parseContent = (content) => {
    let array = content.split("\n");
    let result = [];
    for (let v of array) {
      result.push(v, <br />);
    }
    return result;
  };

  const replaceCharN = (content) => {
    return content.replace(/\n/gi, "<br/>");
  };
  notifications.map((n) => {
    n.content = replaceCharN(n.content);
    return n;
  });
  return (
    <React.Fragment>
      {notifications.map((n) => (
        <Announce
          key={n.id}
          id={n.id}
          title={n.title}
          abstractColor={n.color || "#000"} //#f62b2b
          abstract={n.abstract}
        >
          {/* <Typography variant="body1" paragraph>
          {parseContent(n.content)}
        </Typography> */}
          <ReactMarkdown>{n.content}</ReactMarkdown>
          <Typography variant="body2" align="right">
            OpenFogOS 团队
            <br />
            {n.date}
          </Typography>
        </Announce>
      ))}
    </React.Fragment>
  );
}

export default EasyAnnounce;
