/*
 * @Author: Tperam
 * @Date: 2021-11-12 15:41:18
 * @LastEditTime: 2021-11-16 10:35:28
 * @LastEditors: Tperam
 * @Description:
 * @FilePath: \openfogos\src\modules\pearbox\reducer.js
 */
export const Status = {
  LOADING: "loading",
  SUCCESS: "success",
  FAILURE: "failure",
};

export default (
  state = {
    // pearboxBindingInfo: [
    //     {id:1,user_id:8348,device_id:"pearbox_010010",url:"https://www.baidu.com",level:"1"},
    //     {id:2,user_id:8348,device_id:"pearbox_010010",url:"https://www.google.com.com",level:"2"},
    //     {id:3,user_id:8348,device_id:"pearbox_010010",url:"https://www.golang.org",level:"3"},
    //     {id:4,user_id:8348,device_id:"pearbox_010010",url:"https://www.openfogos.com",level:"4"},
    //     {id:5,user_id:8348,device_id:"pearbox_010010",url:"https://sms.webrtc.win",level:"5"}
    // ]
  },
  action,
) => {
  switch (action.type) {
    case "PEARBOX/BIND/DEVICEID": {
      return { ...state, bindDeviceID: action.result };
    }
    case "PEARBOX/BIND/URL": {
      return { ...state, bindURL: action.result };
    }
    case "POST/PEARBOX/BINDING_STARTED": {
      return { ...state, postBindStatus: Status.LOADING };
    }
    case "POST/PEARBOX/BINDING_SUCCESS": {
      return { ...state, postBindStatus: Status.SUCCESS };
    }
    case "POST/PEARBOX/BINDING_FAILURE": {
      return { ...state, postBindStatus: Status.FAILURE };
    }
    case "GET/PEARBOX/BINDING_STARTED": {
      return { ...state, getBindStatus: Status.LOADING };
    }
    case "GET/PEARBOX/BINDING_SUCCESS": {
      return {
        ...state,
        getBindStatus: Status.SUCCESS,
        pearboxBindingInfo: action.result,
      };
    }
    case "GET/PEARBOX/BINDING_FAILURE": {
      return { ...state, getBindStatus: Status.FAILURE };
    }
    case "DELETE/PEARBOX/BINDING_STARTED": {
      return { ...state, deleteBindStatus: Status.LOADING };
    }
    case "DELETE/PEARBOX/BINDING_SUCCESS": {
      return { ...state, deleteBindStatus: Status.SUCCESS };
    }
    case "DELETE/PEARBOX/BINDING_FAILURE": {
      return { ...state, deleteBindStatus: Status.FAILURE };
    }
    default: {
      return { ...state };
    }
  }
};
