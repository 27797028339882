import React from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import AreaChart from "recharts/lib/chart/AreaChart";
import ComposedChart from "recharts/lib/chart/ComposedChart";
import XAxis from "recharts/lib/cartesian/XAxis";
import YAxis from "recharts/lib/cartesian/YAxis";
import Legend from "recharts/lib/component/Legend";
import CartesianGrid from "recharts/lib/cartesian/CartesianGrid";
import Tooltip from "recharts/lib/component/Tooltip";
import Area from "recharts/lib/cartesian/Area";
import Line from "recharts/lib/cartesian/Line";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
//LabelImportant

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 300,
    position: "relative",
    // transform: "scale(0.5)"
  },
  tooltip: {
    background: "rgba(255, 255, 255, 0.8)",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  loading: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileTip: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    animationName: "$fadeout",
    animationDelay: "6s",
    animationDuration: "2s",
    animationFillMode: "forwards",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "4px 10px",
    borderRadius: "3px",
    color: "white",
    pointerEvents: "none",
  },
  mobileTip2: {
    position: "absolute",
    top: "65%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    animationName: "$fadeout",
    animationDelay: "6s",
    animationDuration: "2s",
    animationFillMode: "forwards",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "4px 10px",
    borderRadius: "3px",
    color: "white",
    pointerEvents: "none",
  },
  "@keyframes fadeout": {
    from: { opacity: 1 },
    to: {
      opacity: 0,
    },
  },
}));

function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
}

/**
 * data:[
 * {
 *   label: "00.05",
 *   bw: 13.5,//单位 Mbps
 * }
 * ]
 */
const CustomTooltip = ({ bwOnly, maxValue, active, payload, label, toG }) => {
  const classes = useStyles();
  if (active) {
    return (
      payload && (
        <div className={classes.tooltip}>
          <Typography variant="body1">{label}</Typography>
          {!toG ? (
            <Typography variant="body1">{`带宽：${payload[0].payload.bw.toFixed(maxValue > 1 ? 2 : 4)} Mbps`}</Typography>
          ) : (
            <Typography variant="body1">{`带宽：${payload[0].payload.bw.toFixed(4)} Gbps`}</Typography>
          )}
          {/* <Typography variant="body1">{`带宽：${(payload[0].payload.bw).toFixed(maxValue > 1?2:4)} Mbps`}</Typography> */}

          {!bwOnly && [
            <Typography
              variant="body1"
              key={1}
            >{`在线节点：${payload[0].payload.online}`}</Typography>,
            <Typography
              variant="body1"
              key={2}
            >{`IO读异常：${payload[0].payload.ioRead}`}</Typography>,
            <Typography
              variant="body1"
              key={3}
            >{`IO写异常：${payload[0].payload.ioWrite}`}</Typography>,
            <Typography
              variant="body1"
              key={4}
            >{`IO使用率异常：${payload[0].payload.io}`}</Typography>,
            <Typography
              variant="body1"
              key={5}
            >{`CPU使用率异常：${payload[0].payload.cpu}`}</Typography>,
            <Typography
              variant="body1"
              key={6}
            >{`重传率：${payload[0].payload.retrans}%`}</Typography>,
          ]}
        </div>
      )
    );
  }

  return null;
};

export default function DisplayBandwidth(props) {
  const { data, bwOnly, toG } = props;
  const classes = useStyles();
  const Chart = bwOnly ? AreaChart : ComposedChart;
  var lineColorList = [
    "#31ef3a",
    "#ef3131",
    "#d930de",
    "#3262cd",
    "#f87e21",
    "#f8d229",
  ];
  const isMobile = () =>
    navigator.userAgent.match(
      /Mobile|iphone|ipad|ipod|Android|BlackBerry|phone|opera\smini/i,
    );
  const mobile = isMobile();
  return (
    <div className={classes.root}>
      {data.length ? (
        <React.Fragment>
          <ResponsiveContainer>
            <Chart
              data={data}
              margin={{
                top: 30,
                right: bwOnly ? 30 : 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="10%" stopColor="#a68540" stopOpacity={0.8} />
                  <stop offset="100%" stopColor="#a68540" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="label" />
              <YAxis />
              {!bwOnly && <YAxis yAxisId="right" orientation="right" />}
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <Tooltip
                content={
                  <CustomTooltip
                    toG={toG}
                    bwOnly={bwOnly}
                    maxValue={data.reduce((max, v) => {
                      if (v.bw > max) return v.bw;
                      return max;
                    }, 0)}
                  />
                }
              />
              <Legend />
              {!toG ? (
                <Area
                  type="monotone"
                  name="带宽"
                  unit=" Mbps"
                  dataKey="bw"
                  stroke="#a68540"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              ) : (
                <Area
                  type="monotone"
                  name="带宽"
                  unit=" Gbps"
                  dataKey="bw"
                  stroke="#a68540"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              )}

              {!bwOnly && [
                <Line
                  yAxisId="right"
                  key={1}
                  type="monotone"
                  dataKey="online"
                  name="在线节点"
                  unit=" 个"
                  dot={false}
                  stroke={lineColorList[0]}
                  strokeWidth={1}
                />,
                <Line
                  yAxisId="right"
                  key={2}
                  type="monotone"
                  dataKey="ioRead"
                  name="IO读异常"
                  unit=" 次"
                  dot={false}
                  stroke={lineColorList[1]}
                  strokeWidth={1}
                />,
                <Line
                  yAxisId="right"
                  key={3}
                  type="monotone"
                  dataKey="ioWrite"
                  name="IO写异常"
                  unit=" 次"
                  dot={false}
                  stroke={lineColorList[2]}
                  strokeWidth={1}
                />,
                <Line
                  yAxisId="right"
                  key={4}
                  type="monotone"
                  dataKey="io"
                  name="IO使用率异常"
                  unit=" 次"
                  dot={false}
                  stroke={lineColorList[3]}
                  strokeWidth={1}
                />,
                <Line
                  yAxisId="right"
                  key={5}
                  type="monotone"
                  dataKey="cpu"
                  name="CPU使用率异常"
                  unit=" 次"
                  dot={false}
                  stroke={lineColorList[4]}
                  strokeWidth={1}
                />,
                <Area
                  key={6}
                  type="monotone"
                  dataKey="retransDisplay"
                  name="重传率"
                  unit=" %"
                  dot={false}
                  stroke={lineColorList[6]}
                />,
              ]}
            </Chart>
          </ResponsiveContainer>
          {mobile && (
            <Typography className={classes.mobileTip}>
              横屏浏览效果更佳
            </Typography>
          )}
          {mobile && (
            <Typography className={classes.mobileTip2}>
              点划此图可看详情
            </Typography>
          )}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export function DisplayStorage(props) {
  const { data } = props;
  const classes = useStyles();
  const Chart = ComposedChart;

  // const lineColorList = ["#31ef3a", "#ef3131", "#d930de", "#3262cd", "#f87e21","#f8d229", "#22b2da", "#3b4a6b"];
  const lineColorList = [
    "#f0d43a",
    "#22b2da",
    "#3b4a6b",
    "#00c235",
    "#ff7c7c",
    "#d676ff",
    "#f8d229",
    "#22b2da",
    "#3b4a6b",
  ];
  return (
    <div className={classes.root}>
      {data.length ? (
        <React.Fragment>
          <ResponsiveContainer>
            <Chart
              data={data}
              margin={{
                top: 30,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="10%" stopColor="#a68540" stopOpacity={0.8} />
                  <stop offset="100%" stopColor="#a68540" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="label" />
              <YAxis />
              <YAxis yAxisId="right" orientation="right" />
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <Tooltip content={<StorageToolTip />} />
              <Legend />
              <Area
                key={1}
                type="monotone"
                dataKey="io_rate"
                name="IO最大使用率"
                unit=" %"
                fill={lineColorList[0]}
                fillOpacity={1}
                stroke={lineColorList[0]}
                dot={false}
              />
              {/* <Line yAxisId="right" key={1} type="monotone" dataKey="io_rate" name="IO最大使用率" unit=" %" dot={false} stroke={lineColorList[0]} strokeWidth={1}/> */}
              <Line
                yAxisId="right"
                key={2}
                type="monotone"
                dataKey="io_times"
                name="IO异常次数"
                unit=" 次"
                dot={false}
                stroke={lineColorList[1]}
                strokeWidth={1}
              />
              <Line
                yAxisId="right"
                key={3}
                type="monotone"
                dataKey="iorl_rate"
                name="IO读最大延迟"
                unit=" ms"
                dot={false}
                stroke={lineColorList[2]}
                strokeWidth={1}
              />
              <Line
                yAxisId="right"
                key={4}
                type="monotone"
                dataKey="iorl_times"
                name="IO读异常次数"
                unit=" 次"
                dot={false}
                stroke={lineColorList[3]}
                strokeWidth={1}
              />
              <Line
                yAxisId="right"
                key={5}
                type="monotone"
                dataKey="iowl_rate"
                name="IO写最大延迟"
                unit=" ms"
                dot={false}
                stroke={lineColorList[4]}
                strokeWidth={1}
              />
              <Line
                yAxisId="right"
                key={6}
                type="monotone"
                dataKey="iowl_times"
                name="IO写异常次数"
                unit=" 次"
                dot={false}
                stroke={lineColorList[6]}
              />
              <Line
                yAxisId="right"
                key={7}
                type="monotone"
                dataKey="io_temp"
                name="硬盘温度"
                unit=" ℃"
                dot={false}
                stroke={lineColorList[7]}
              />
            </Chart>
          </ResponsiveContainer>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

const StorageToolTip = ({ active, payload, label }) => {
  const classes = useStyles();
  if (active) {
    console.log(payload);
    return (
      payload && (
        <div className={classes.tooltip}>
          <Typography variant="body1">{label}</Typography>
          <Typography
            variant="body1"
            key={1}
          >{`IO最大使用率 ${payload[0].payload.io_rate}%`}</Typography>
          <Typography
            variant="body1"
            key={2}
          >{`IO异常次数 ${payload[0].payload.io_times}次`}</Typography>
          <Typography
            variant="body1"
            key={3}
          >{`IO读最大延迟 ${payload[0].payload.iorl_rate}ms`}</Typography>
          <Typography
            variant="body1"
            key={4}
          >{`IO读异常次数 ${payload[0].payload.iorl_times}次`}</Typography>
          <Typography
            variant="body1"
            key={5}
          >{`IO写最大延迟 ${payload[0].payload.iowl_rate}ms`}</Typography>
          <Typography
            variant="body1"
            key={6}
          >{`IO写异常次数 ${payload[0].payload.iowl_times}次`}</Typography>
          <Typography
            variant="body1"
            key={7}
          >{`硬盘温度 ${payload[0].payload.io_temp}℃`}</Typography>
        </div>
      )
    );
  }

  return null;
};
