/*
 * @Author: Tperam
 * @Date: 2021-04-25 10:18:05
 * @LastEditTime: 2022-04-07 18:40:44
 * @LastEditors: Tperam
 * @Description:
 * @FilePath: \openfogos\src\pages\help.js
 */
import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Title } from "../../modules/title";
import { SimpleLinkListItem } from "../../modules/listItem";
import { fetchNotifications } from "../../modules/utils/actions";
import EasyAnnounce from "../../modules/easyAnnounce";

const styles = (theme) => ({});

function MyReward(props) {
  const { onRequire, notifications } = props;
  useEffect(() => {
    onRequire();
  }, []);
  return (
    <div>
      <EasyAnnounce notifications={notifications} />
      {/* <Title>闲小节点</Title>
      <SimpleLinkListItem title="如何本地绑定闲小节点" to="/home/help_basic_local"/>
      <SimpleLinkListItem title="如何远程绑定闲小节点" to="/home/help_basic_remote"/>
      <SimpleLinkListItem title="已适配设备列表" to="/home/doc/supporteddevices"/> */}

      <Title>CDN节点 & 优享节点</Title>
      <SimpleLinkListItem title="常见问题" to="/home/doc/common_problem" />
      <SimpleLinkListItem title="新用户绑定节点" to="/home/doc/bind_node" />
      {/* <SimpleLinkListItem title="CDN节点和优享节点的区别" to="/home/help_difference"/> */}
      {/* <SimpleLinkListItem title="购买系统盘" useHref={true} to=""
                                href="https://item.taobao.com/item.htm?id=613751308949" target="_blank"
                                rel="noopener noreferrer"/> */}
      <SimpleLinkListItem
        title="系统盘制作教程"
        to="/home/doc/make_system_drive"
      />
      {/* <SimpleLinkListItem title="Ubuntu Server 版系统网络配置教程" to="/home/doc/network_config"/> */}
      <SimpleLinkListItem
        title="PearOS Ubuntu 版系统网络配置教程"
        to="/home/doc/network_guide"
      />
      {/* <SimpleLinkListItem title="Windows 版 PCDN 安装教程" to="/home/doc/pcdn"/> */}

      <Title>收益</Title>
      <SimpleLinkListItem
        title="各种节点的收益发放及提现规则"
        to="/home/help_rule"
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    notifications: (state.utils.notifications || {})["帮助"] || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(fetchNotifications("帮助"));
    },
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MyReward),
);
