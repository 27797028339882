import xhr from "../../Xhr";

const API = (id) => ({
  node: {
    bw: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/dp/bws`,
      method: "GET",
    },
    mac: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/dp/node/ids`,
      method: "GET",
    },
    macInfo: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/mac_nodes`,
      method: "POST",
    },
  },
});

export const fetchMinerBW = () => {
  return xhr({
    api: API(localStorage.getItem("openfogos_userid")).node.bw,
    action: "MINER/BW",
  });
};

export const fetchMinerMacs = ({ ...event }) => {
  return xhr({
    api: API(localStorage.getItem("openfogos_userid")).node.mac,
    action: "MINER/MAC",
    event,
  });
};

export const fetchMacInfo = ({ data, ...event }) => {
  return xhr({
    api: API(localStorage.getItem("openfogos_userid")).node.macInfo,
    action: "MINER/MAC/INFO",
    data,
    event,
  });
};
