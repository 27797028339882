import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  img: {
    maxWidth: 488,
    width: "100%",
  },
  imgContainer: {
    display: "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 9999,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: `rgba(0, 0, 0, 0.7)`,
  },
  showImg: {
    display: "block",
    maxWidth: "90vw",
    maxHeight: "85vh",
    pointerEvents: "none",
  },
});

const Viewer = (props) => {
  const { classes } = props;
  const show = (e) => {
    //获得点击的图片的DOM
    const img = e.target;
    const imgShowContainer = img.nextSibling;
    const imgShow = imgShowContainer.querySelector("img");
    imgShowContainer.style.display = "flex";
    imgShowContainer.onmousewheel = (e) => {
      // 获取滚轮的方向，正数表示向上，负数表示向下
      let direction = e.wheelDelta || -e.deltaY;
      // 设置缩放的因子
      let factor = 1.05;
      // 获取图片的当前缩放比例，如果没有设置过，就默认为1
      let scale = imgShow.style.transform.match(/scale\((\d+(\.\d+)?)\)/);
      if (scale) {
        scale = parseFloat(scale[1]);
      } else {
        scale = 1;
      }
      // 根据滚轮的方向，增加或减少缩放比例
      direction > 0 ? (scale *= factor) : (scale /= factor);

      // 设置图片的transform属性，实现缩放和平移
      imgShow.style.transform = `scale(${scale})`;
      return false;
    };
  };
  const close = (e) => {
    const imgShowContainer = e.target;
    imgShowContainer.style.display = "none";
    const imgShow = imgShowContainer.querySelector("img");
    imgShow.style.transform = "none";
  };

  return (
    <>
      <p style={{ textAlign: "center" }}>
        <img onClick={show} style={{}} className={classes.img} {...props} />
        <div onClick={close} className={classes.imgContainer}>
          <img className={classes.showImg} {...props} />
          <p
            style={{ color: "#fff", fontSize: "1.3rem", pointerEvents: "none" }}
          >
            滚轮缩放 / 点击关闭
          </p>
        </div>
      </p>
    </>
  );
};

export default withStyles(styles)(Viewer);
