import React, { useEffect } from "react";
import Assets from "../../modules/displayAssets";
import EasyAnnounce from "../../modules/easyAnnounce";
import { RewardListItem as ListItem } from "../../modules/listItem";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getCash, getUserMine } from "../../modules/userMine/actions";
import { fetchOnlineOfflineStat as fetchBasicNodesInfo } from "../../modules/node/actions";
import { fetchAdvancedNodes } from "../../modules/bc/actions";
import { fetchNotifications } from "../../modules/utils/actions";
import { Title } from "../../modules/title";
import { cashToRMB } from "../../modules/utils/functions";

const styles = (theme) => ({
  gap: {
    height: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

function MyReward(props) {
  const {
    onRequire,
    cashInfo,
    userMineList,
    basicNodeStat,
    advancedNodeList,
    notifications,
  } = props;
  const basicNodesOnline = basicNodeStat.online || 0;
  const basicNodesOffline = basicNodeStat.offline || 0;
  let mineNodesOnline = 0,
    mineNodesOffline = 0,
    advancedNodesOnline = 0,
    advancedNodesOffline = 0;
  userMineList.forEach((v) => {
    if (v.online) {
      mineNodesOnline++;
    } else {
      mineNodesOffline++;
    }
  });
  advancedNodeList.forEach((v) => {
    if (v.online) {
      advancedNodesOnline++;
    } else {
      advancedNodesOffline++;
    }
  });
  const assetsData = {
    title: "昨日收益（预估）",
    total:
      cashToRMB(cashInfo.expected_basic_reward_yesterday) +
      cashToRMB(cashInfo.expected_mine_reward_yesterday) +
      cashToRMB(cashInfo.expected_advanced_reward_yesterday),
    details: [
      {
        name: "余额",
        amount: cashToRMB(cashInfo.cash) + cashToRMB(cashInfo.frozen_cash),
      },
      {
        name: "本月累计收益（预估）",
        amount: cashToRMB(cashInfo.expected_this_month),
      },
      {
        name:
          cashToRMB(cashInfo.delivered_last_month) > 0
            ? "上月收益"
            : "上月收益（待发放）",
        amount:
          cashToRMB(cashInfo.delivered_last_month) > 0
            ? cashToRMB(cashInfo.delivered_last_month)
            : cashToRMB(cashInfo.expected_last_month),
      },
    ],
    history: {
      title: "历史",
      linkTo: "/home/my_history",
    },
  };

  useEffect(() => {
    onRequire();
  }, []);
  return (
    <div className="Home">
      <Assets data={assetsData} />
      <EasyAnnounce notifications={notifications} />
      <Title>昨日收益</Title>
      <ListItem
        name={"闲小节点"}
        online={basicNodesOnline}
        offline={basicNodesOffline}
        amount={cashToRMB(cashInfo.expected_basic_reward_yesterday)}
        to="/home/my_basic_nodes"
      />
      <ListItem
        name={"CDN节点"}
        online={mineNodesOnline}
        offline={mineNodesOffline}
        amount={cashToRMB(cashInfo.expected_mine_reward_yesterday)}
        to="/home/my_mine_nodes"
      />
      <ListItem
        name={"优享节点"}
        online={advancedNodesOnline}
        offline={advancedNodesOffline}
        amount={cashToRMB(cashInfo.expected_advanced_reward_yesterday)}
        to="/home/my_advanced_nodes"
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cashInfo: state.userMine.cash || {},
    userMineList: state.userMine.userMineList || [],
    basicNodeStat: state.node.onOffStat || {},
    advancedNodeList: state.bc.advancedNodes || [],
    notifications: (state.utils.notifications || {})["我的收益"] || [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(getCash());
      dispatch(getUserMine());
      dispatch(fetchBasicNodesInfo());
      dispatch(fetchAdvancedNodes());
      dispatch(fetchNotifications("我的收益"));
    },
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MyReward),
);
