import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
//LabelImportant

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  slideTag: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    marginBottom: 2,
  },
  simpleTitle: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    marginBottom: 2,
  },
  leftIcon: {
    verticalAlign: "middle",
    marginRight: theme.spacing(0.5),
    color: "#808080",
  },
  titleContent: {
    verticalAlign: "middle",
    fontSize: 18,
  },
}));

/**
 *
 * getAPI: (from, to) => URL string
 * render: (dataObject, index) => Element
 */
export default function SlideForMore(props) {
  const { getAPI, render } = props;
  const classes = useStyles();
  const getLength = 9;
  const [from, setFrom] = useState(0);
  const [list, setList] = useState([]);
  const [end, setEnd] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    if (loading || end) return;
    setLoading(true);
    const to = from + getLength;
    const url = getAPI(from, to);
    fetch(url, {
      method: "GET",
      headers: new Headers({
        "X-Pear-Token": localStorage.getItem("openfogos_token"),
        "X-Pear-User": localStorage.getItem("openfogos_userid"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data) {
          console.error("no list");
          setEnd(true);
          setLoading(false);
          return;
        }
        setList([...list, ...data]);
        setFrom(to);
        if (data.length < getLength) {
          setEnd(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  function onScroll(e) {
    let content = document.documentElement;
    let scrolled =
      content.scrollTop / (content.scrollHeight - content.clientHeight);
    if (scrolled >= 1) {
      getData();
    }
  }

  function onWheel(e) {
    let content = document.documentElement;
    if (content.scrollHeight <= content.clientHeight) {
      getData();
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    document.addEventListener("wheel", onWheel);
    document.addEventListener("touchmove", onWheel);
    return () => {
      document.removeEventListener("scroll", onScroll);
      document.removeEventListener("wheel", onWheel);
      document.removeEventListener("touchmove", onWheel);
    };
  });

  return (
    <div className={classes.root}>
      {list.map((v, i) => render(v, i))}
      {loading ? (
        <CircularProgress />
      ) : (
        <div className={classes.slideTag}>
          <Typography align="center" variant="caption">
            {end ? "已无更多数据" : "上拉加载更多"}
          </Typography>
        </div>
      )}
    </div>
  );
}
