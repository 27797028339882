import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import { darcula } from "react-syntax-highlighter/src/styles/hljs";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import React, { useEffect, useState } from "react";
import Blue from "@material-ui/core/colors/blue";
import { withStyles } from "@material-ui/core/styles";
import Viewer from "./picture_viewer";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  gap: {
    height: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  aLink: {
    color: Blue[500],
  },
});

// 必须传入URL或Markdown字符串，二选一
const Markdown = (props) => {
  const [md, setMD] = useState("");
  // 挂载时拉取Markdown，没有传入URL就从字符串获取
  useEffect(() => {
    if (props.URL) {
      fetch(props.URL, { method: "GET" }).then((res) => {
        res.text().then((text) => setMD(text));
        console.log(md);
      });
    } else {
      setMD(props.children);
    }
  }, [props.URL, props.children]);

  return (
    <div className={"Advice"}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          img({ className, children, ...props }) {
            return (
              <>
                <Viewer {...props} />
                {/*<p style={{textAlign: "center"}}>*/}
                {/*    <img {...props}/>*/}
                {/*</p>*/}
                <div></div>
              </>
            );
          },
          code({ className, children, ...props }) {
            // 如果有标注语言，实现代码高亮。
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <SyntaxHighlighter
                style={darcula}
                language={match[1]}
                PreTag="div"
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>
            ) : (
              // 没有标注就直接用代码块显示。
              <code {...props} className={className}>
                {children}
              </code>
            );
          },
        }}
      >
        {md}
        {/* <---传入Markdown字符串*/}
      </ReactMarkdown>
    </div>
  );
};

export default withStyles(styles)(Markdown);
