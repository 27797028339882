import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ERROR from "../../../Error";
import { convertGoods, fetchInfo } from "../../../modules/user/actions";
import { messageError, messageOk } from "../../../modules/utils/actions";

const styles = (theme) => ({
  gap: {
    height: theme.spacing(2),
  },
  submitButton: {
    // width: '100%',
    marginLeft: theme.spacing(2),
  },
});

class Goods1 extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.price = 1500;
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.state = {
      qqNumber: "",
      confirmVisible: false,
    };
  }

  showConfirm() {
    this.setState({ confirmVisible: true });
  }
  hideConfirm() {
    this.setState({ confirmVisible: false });
  }
  onSubmit() {
    this.hideConfirm();
    this.props.onSubmit(this.state.qqNumber);
    this.props.history.push({ pathname: "/home/index" });
  }
  handleChange(event) {
    console.log("new qq:", event.target.value);
    this.setState({ qqNumber: event.target.value });
  }
  render() {
    const { classes } = this.props;
    const buyButtonParam = {
      onClick: this.showConfirm,
      disabled: this.props.balance < this.price,
    };
    return (
      <div>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              腾讯视频 VIP 月卡
            </Typography>
            <Typography>1. 该商品为腾讯视频会员好莱坞 VIP 月卡。</Typography>
            <Typography>
              2. 请填写需要充值的QQ号码，不支持手机号、微信号充值。
            </Typography>
            <Typography>3. 兑换之后将在一个工作日内到账。</Typography>
            <Typography>
              4. 充值前请核对好QQ号，写错账号无法退款和转移。
            </Typography>
          </CardContent>
        </Card>
        <div className={classes.gap}></div>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              兑换
            </Typography>
            <form>
              <Typography>请输入 QQ 号：</Typography>
              <Input onChange={this.handleChange} />
              <Tooltip
                title={this.props.balance < this.price ? "余额不足" : ""}
              >
                <Button
                  className={classes.submitButton}
                  color="primary"
                  variant="contained"
                  {...buyButtonParam}
                >
                  确定兑换
                </Button>
              </Tooltip>
              {/*<Grid container spacing={16}>
                      <Grid item xs={8}>
                          <Input onChange={this.handleChange}/>
                      </Grid>
                      <Grid item xs={4}>
                          <Tooltip title={this.props.balance < this.price? "余额不足":""}>
                              <Button className={classes.submitButton} color="primary" variant="contained" {...buyButtonParam}>确定兑换</Button>
                          </Tooltip>
                      </Grid>
                  </Grid>*/}
            </form>
          </CardContent>
        </Card>
        <Button variant="contained" color="primary" style={{ marginTop: 10 }}>
          <Link to="/home/index">返回</Link>
        </Button>

        <Dialog open={this.state.confirmVisible}>
          <DialogTitle>确定兑换</DialogTitle>
          <DialogContent>
            <Typography>
              消耗 {this.price / 100} PRC 为 QQ 号 “{this.state.qqNumber}”
              充值一个月腾讯视频 VIP？
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideConfirm} color="primary">
              取消
            </Button>
            <Button onClick={this.onSubmit} color="primary" autoFocus>
              确定
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  componentDidMount() {
    this.props.onRequire();
  }
}

const mapStateToProps = (state) => {
  return {
    phone: state.user.info.phone,
    balance: state.user.info.balance,
    infoLoading: state.user.infoStatus === "loading" ? true : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(fetchInfo({}));
    },
    onSubmit: (qqNumber) => {
      console.log("submit qq", qqNumber);
      dispatch(
        convertGoods({
          data: {
            goods_no: "1",
            data: qqNumber,
          },
          success: () => {
            dispatch(messageOk("兑换成功！"));
          },
          error: (error) => {
            dispatch(messageError(ERROR[error.errorcode]));
          },
        }),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Goods1));
