import React from "react";
import SiteContent from "../modules/utils/site";

const Site = () => {
  return (
    <div>
      <SiteContent />
    </div>
  );
};

export default Site;
