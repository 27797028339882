import React from "react";
import { Title } from "../../modules/title";
import { RewardHistoryListItem } from "../../modules/listItem";
import SlideForMore from "../../modules/slideForMore";
import { cashToRMB } from "../../modules/utils/functions";

export default function MyHistory() {
  return (
    <div>
      <Title>收益历史（预估）</Title>
      <SlideForMore
        getAPI={(from, to) =>
          process.env.REACT_APP_NMSAPI_SERVER +
          `/expected/history?from=${from}&to=${to}`
        }
        render={(v, i) => (
          <RewardHistoryListItem
            key={i}
            title={v.comment || ""}
            timestamp={v.time}
            amount={cashToRMB(v.cash)}
          />
        )}
      />
    </div>
  );
}
