import React from "react";
import { Link, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  container: {
    position: "relative",
    width: "100%",
    height: 202,
    color: "#a68540",
    background: `url(/fullCard.png) `,
    backgroundPosition: "center top",
    backgroundSize: "cover",
  },
  total: {
    position: "absolute",
    bottom: "50%",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  detail: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: theme.spacing(1),
  },
  detailItem: {
    padding: theme.spacing(1),
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  accountCard: {
    padding: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    boxSizing: "content-box",
    maxWidth: "373px", //349
  },
  cardContent: {
    backgroundImage: "url(/card.png)",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "220px", //220
    maxWidth: "373px", //349
    padding: theme.spacing(2),
    position: "relative",
    color: "#a68540",
    borderRadius: "24px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
  },
});

/**
 * data:{
 *   title: "我的资产",
 *   total: 56661.70
 *   details: [
 *     {
 *       name: "闲小 & CDN节点",
 *       amount: 18463.18
 *     },
 *     {
 *       name: "优享节点收益（可提现：12416.54）",
 *       amount: 16661.70
 *     }
 *     ...
 *   ]
 * }
 */
function DisplayAssets(props) {
  const { classes, data, loading } = props;
  const xsValue = Math.round(12 / data.details.length);

  return (
    <div>
      {/*<div className={classes.container}>
        <Typography color="inherit" variant={"body1"} className={classes.title}>{data.title}</Typography>

        <Grid container className={classes.detail}>
          {data.details.map(v => (
            <Grid key={v.name} item xs={xsValue} className={classes.detailItem}>
              <Typography color="inherit" align={"center"} variant={"h5"}>{v.amount.toFixed(2)}</Typography>
              <Typography color="inherit" align={"center"} variant={"caption"} display={"block"}>{v.name}</Typography>
            </Grid>
          ))}
        </Grid>
        <Typography color="inherit" align={"center"} variant={"h3"} className={classes.total}>{data.total.toFixed(2)}</Typography>
      </div>*/}
      <div className={classes.accountCard}>
        <div className={classes.cardContent}>
          <div className={classes.title}>
            <Typography color="inherit" variant={"body1"}>
              {data.title}
            </Typography>
            <Link to={data.history.linkTo}>
              <Typography color="inherit" variant={"body1"}>
                {data.history.title}
              </Typography>
            </Link>
          </div>

          <Grid container className={classes.detail}>
            {data.details.map((v) => (
              <Grid
                key={v.name}
                item
                xs={xsValue}
                className={classes.detailItem}
              >
                <Typography color="inherit" align={"center"} variant={"h5"}>
                  {v.amount.toFixed(2)}
                </Typography>
                <Typography
                  color="inherit"
                  align={"center"}
                  variant={"caption"}
                  display={"block"}
                >
                  {v.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Typography
            color="inherit"
            align={"center"}
            variant={"h3"}
            className={classes.total}
          >
            {data.total.toFixed(2)}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(withRouter(DisplayAssets));
