import React, { useEffect } from "react";
import Bandwidth, { DisplayStorage } from "../../../modules/displayBandwidth";
import { connect } from "react-redux";
import moment from "moment";
import {
  getMacBandwidth,
  getMachineStorage,
  getMacInfo,
  getUserMineChargingBWsLastMonth,
  getUserMineChargingBWsLastWeek,
  getUserMineIOToday,
  getUserMineIOYesterday,
  getUserMineRetransToday,
  getUserMineRetransYesterday,
  getUserMineTrafficToday,
  getUserMineTrafficYesterday,
} from "../../../modules/userMine/actions";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMediaQuery } from "@material-ui/core";

function MacBandwidthInner(props) {
  const { nodeInfo, macTraffic, getMacInfo, getMacBandwidth, unix, macInfo } =
    props;
  const matches = useMediaQuery("(max-width:450px)");
  useEffect(() => {
    if (nodeInfo && nodeInfo.id) {
      getMacBandwidth(nodeInfo.id, unix);
      getMacInfo(nodeInfo.id, unix);
    }
  }, [nodeInfo]);

  let bandwidthsComponent = [];
  for (let key in macTraffic) {
    let data = (macTraffic[key] || [])
      .map((v) => (v >= 0 ? v : 0))
      .map((v, i) => ({
        label: moment()
          .startOf("day")
          .add(i * 5, "m")
          .format("HH:mm"),
        bw: (v * 8) / 300 / 1024,
      }));
    bandwidthsComponent.push(
      <div
        style={{ width: matches ? "100%" : "50%", display: "inline-block" }}
        key={key}
      >
        {macInfo[key] ? (
          <>
            {macInfo[key]["pppoe"] ? (
              <>
                <Typography variant="caption" component="span">
                  账号: {macInfo[key]["pppoe"]}
                </Typography>
                &nbsp;&nbsp;
              </>
            ) : null}
            <Typography variant="caption" component="span">
              IPv4: {macInfo[key]["public_ipv4"]}
            </Typography>
            &nbsp;&nbsp;
            <Typography variant="caption" component="span">
              网卡名: {macInfo[key]["nic"]}
            </Typography>
            &nbsp;&nbsp;
          </>
        ) : null}
        <Typography variant="caption" component="span">
          MAC地址: {key}
        </Typography>
        <Bandwidth data={data} bwOnly={true} key={key} />
      </div>,
    );
  }

  return (
    <>
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography> 线路详情 </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%" }}>{bandwidthsComponent}</div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

function StorageInner(props) {
  const { nodeInfo, unix, storage, getMachineStorage } = props;
  // storage
  // {
  //   "pear_id": {
  //     "/dev/sda1": {
  //       "name":"",
  //       "p80id":"",
  //       "busid":"",
  //       "uuid":"",
  //       "snid":"",
  //       "io_rate":"",
  //       "io_times":"",
  //       "io_rl_rate":[],
  //       "io_rl_times":[],
  //       "io_wl_rate":[],
  //       "io_wl_times":[],
  //     }
  //   }
  // }
  const matches = useMediaQuery("(max-width:450px)");
  useEffect(() => {
    if (nodeInfo && nodeInfo.id) {
      getMachineStorage(nodeInfo.id, unix);
    }
  }, [nodeInfo]);

  let storageComponent = [];
  let key = 0;
  for (let pear_id in storage) {
    for (let name in storage[pear_id]) {
      let data = [];
      let tmp = storage[pear_id][name];
      for (var i = 0; i < 288; i++) {
        data.push({
          label: moment()
            .startOf("day")
            .add(i * 5, "m")
            .format("HH:mm"),
          io_rate: tmp["io_rate"][i] === -1 ? 0 : tmp["io_rate"][i],
          io_times: tmp["io_times"][i] === -1 ? 0 : tmp["io_times"][i],
          iorl_rate: tmp["iorl_rate"][i] === -1 ? 0 : tmp["iorl_rate"][i],
          iorl_times: tmp["iorl_times"][i] === -1 ? 0 : tmp["iorl_times"][i],
          iowl_rate: tmp["iowl_rate"][i] === -1 ? 0 : tmp["iowl_rate"][i],
          iowl_times: tmp["iowl_times"][i] === -1 ? 0 : tmp["iowl_times"][i],
          io_temp: !tmp["io_temp"]
            ? 0
            : tmp["io_temp"][i] === -1
              ? 0
              : tmp["io_temp"][i],
          // "io_temp": Math.random()*100,
        });
      }
      storageComponent.push(
        <div
          style={{ width: matches ? "100%" : "50%", display: "inline-block" }}
          key={key}
        >
          <Typography variant="caption" component="span">
            盘名: {tmp["name"]}
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant="caption" component="span">
            SN: {tmp["snid"]}
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant="caption" component="span">
            Bus ID: {tmp["busid"]}
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant="caption" component="span">
            80ID: {tmp["p80id"]}
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant="caption" component="span">
            UUID: {tmp["uuid"]}
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <DisplayStorage data={data} />
        </div>,
      );

      key++;
    }
  }
  return storageComponent.length != 0 ? (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography> 硬盘详情 </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>{storageComponent}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  ) : null;
}

function BandwidthTodayInner(props) {
  const { nodeInfo, getToday, trafficToday, cpuIoToday, retransToday } = props;
  useEffect(() => {
    if (nodeInfo && nodeInfo.id) {
      getToday(nodeInfo.id);
    }
  }, [nodeInfo]);

  let data = trafficToday
    .map((v) => (v >= 0 ? v : 0))
    .map((v, i) => ({
      label: moment()
        .startOf("day")
        .add(i * 5, "m")
        .format("HH:mm"),
      bw: (v * 8) / 300 / 1024,
    }));

  const bandwidthMax = trafficToday.reduce((max, item) => {
    return Math.max(max, item);
  }, 0);
  const retrans = (retransToday || []).map((v) =>
    v >= 0 ? (v <= 100 ? (v * 100).toFixed(2) : 100) : 0,
  );

  data = data.map((v, i) => ({
    ...v,
    retrans: retrans[i] || 0,
    retransDisplay:
      retrans[i] > 100
        ? (bandwidthMax * 8) / 300 / 1024
        : ((bandwidthMax / 100) * retrans[i] * 8) / 300 / 1024,
  }));

  // data = data.map((v, i)=>({...v, retrans:retrans[i]||0, retransDisplay:retrans[i]}));

  if (cpuIoToday) {
    const cpuUtil = cpuIoToday.cpu_util_abnormal_times || Array(288).fill(0);
    const ioUtil = cpuIoToday.io_util_abnormal_times || Array(288).fill(0);
    const ioReadAwait =
      cpuIoToday.io_read_await_abnormal_times || Array(288).fill(0);
    const ioWriteAwait =
      cpuIoToday.io_write_await_abnormal_times || Array(288).fill(0);
    const online = cpuIoToday.online || Array(288).fill(0);
    data = data.map((v, i) => ({
      ...v,
      cpu: cpuUtil[i] || 0,
      io: ioUtil[i] || 0,
      ioRead: ioReadAwait[i] || 0,
      ioWrite: ioWriteAwait[i] || 0,
      online: online[i] || 0,
    }));
  }
  return <Bandwidth data={data} bwOnly={false} />;
}

function BandwidthYesterdayInner(props) {
  const {
    nodeInfo,
    getYesterday,
    trafficYesterday,
    cpuIoYesterday,
    retransYesterday,
  } = props;
  useEffect(() => {
    if (nodeInfo && nodeInfo.id) {
      getYesterday(nodeInfo.id);
    }
  }, [nodeInfo]);

  let data = trafficYesterday
    .map((v) => (v >= 0 ? v : 0))
    .map((v, i) => ({
      label: moment()
        .startOf("day")
        .add(i * 5, "m")
        .format("HH:mm"),
      bw: (v * 8) / 300 / 1024,
    }));

  const bandwidthMax = trafficYesterday.reduce((max, item) => {
    return Math.max(max, item);
  }, 0);

  const retrans = (retransYesterday || []).map((v) =>
    v >= 0 ? (v <= 100 ? (v * 100).toFixed(2) : 100) : 0,
  );
  data = data.map((v, i) => ({
    ...v,
    retrans: retrans[i] || 0,
    retransDisplay:
      retrans[i] > 100
        ? (bandwidthMax * 8) / 300 / 1024
        : ((bandwidthMax / 100) * retrans[i] * 8) / 300 / 1024,
  }));

  if (cpuIoYesterday) {
    const cpuUtil =
      cpuIoYesterday.cpu_util_abnormal_times || Array(288).fill(0);
    const ioUtil = cpuIoYesterday.io_util_abnormal_times || Array(288).fill(0);
    const ioReadAwait =
      cpuIoYesterday.io_read_await_abnormal_times || Array(288).fill(0);
    const ioWriteAwait =
      cpuIoYesterday.io_write_await_abnormal_times || Array(288).fill(0);
    const online = cpuIoYesterday.online || Array(288).fill(0);
    data = data.map((v, i) => ({
      ...v,
      cpu: cpuUtil[i] || 0,
      io: ioUtil[i] || 0,
      ioRead: ioReadAwait[i] || 0,
      ioWrite: ioWriteAwait[i] || 0,
      online: online[i] || 0,
    }));
  }
  data = data.map((v, i) => ({ ...v, retrans: retrans[i] || 0 }));
  return <Bandwidth data={data} bwOnly={false} />;
}

function BandwidthLastWeekInner(props) {
  const { nodeInfo, getLastWeek, chargingBWsLastWeek } = props;
  useEffect(() => {
    if (nodeInfo && nodeInfo.id) {
      getLastWeek(nodeInfo.id);
    }
  }, [nodeInfo]);

  const data = chargingBWsLastWeek
    .map((v) => (v >= 0 ? v : 0))
    .map((v, i) => ({
      label: moment()
        .subtract(7 - i, "d")
        .format("M-DD"),
      bw: v,
    }));
  return <Bandwidth data={data} bwOnly={true} />;
}

function BandwidthLastMonthInner(props) {
  const { nodeInfo, getLastMonth, chargingBWsLastMonth } = props;
  useEffect(() => {
    if (nodeInfo && nodeInfo.id) {
      getLastMonth(nodeInfo.id);
    }
  }, [nodeInfo]);

  const data = chargingBWsLastMonth
    .map((v) => (v >= 0 ? v : 0))
    .map((v, i) => ({
      label: moment()
        .subtract(30 - i, "d")
        .format("M-DD"),
      bw: v,
    }));

  return <Bandwidth data={data} bwOnly={true} />;
}

const mapStateToProps = (state) => {
  return {
    macTraffic: state.userMine.macTraffic || {},
    storage: state.userMine.storage || {},
    macInfo: state.userMine.macInfo || {},
    trafficToday: state.userMine.userMineTrafficToday || [],
    cpuIoToday: state.userMine.userMineCPUIOToday || {},
    retransToday: state.userMine.userMineRetransToday || [],
    trafficYesterday: state.userMine.userMineTrafficYesterday || [],
    cpuIoYesterday: state.userMine.userMineCPUIOYesterday || {},
    retransYesterday: state.userMine.userMineRetransYesterday || [],
    chargingBWsLastWeek: state.userMine.userMineChargingBWsLastWeek || [],
    chargingBWsLastMonth: state.userMine.userMineChargingBWsLastMonth || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getToday: (id) => {
      dispatch(getUserMineTrafficToday(id));
      dispatch(getUserMineIOToday(id));
      dispatch(getUserMineRetransToday(id));
    },
    getYesterday: (id) => {
      dispatch(getUserMineTrafficYesterday(id));
      dispatch(getUserMineIOYesterday(id));
      dispatch(getUserMineRetransYesterday(id));
    },
    getLastWeek: (id) => {
      dispatch(getUserMineChargingBWsLastWeek(id));
    },
    getLastMonth: (id) => {
      dispatch(getUserMineChargingBWsLastMonth(id));
    },
    getMacInfo: (id, date) => {
      dispatch(getMacInfo({ id, date }));
    },
    getMachineStorage: (id, date) => {
      dispatch(getMachineStorage({ id, date }));
    },
    getMacBandwidth: (id, date) => {
      dispatch(getMacBandwidth({ id, date }));
    },
  };
};
export const StorageInfo = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StorageInner);
export const MacBandwidth = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MacBandwidthInner);
export const BandwidthToday = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BandwidthTodayInner);
export const BandwidthYesterday = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BandwidthYesterdayInner);
export const BandwidthLastWeek = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BandwidthLastWeekInner);
export const BandwidthLastMonth = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BandwidthLastMonthInner);
