/*
 * @Author: Tperam
 * @Date: 2022-03-14 12:37:29
 * @LastEditTime: 2022-03-14 14:43:24
 * @LastEditors: Tperam
 * @Description:
 * @FilePath: \openfogos\src\index.js
 */
import React from "react";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Theme from "./theme";
import store from "./Store.js";
import { Provider } from "react-redux";
import { Routes } from "./Routes.js";
import SnackBars from "./modules/snackbars";
import Confirm from "./modules/confirm";
import QRCode from "./components/layout/qrcode.js";
import { createRoot } from "react-dom/client";

// Sentry.init({dsn: "https://67684c5a23494964b3633cf7760443e2@o408002.ingest.sentry.io/5278133"});

const root = createRoot(document.getElementById("root"));
root.render(
  <MuiThemeProvider theme={Theme}>
    <Provider store={store}>
      <SnackBars />
      <Confirm />
      <Routes />
      <QRCode />
    </Provider>
  </MuiThemeProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
