import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles/index";
import withMobileDialog from "@material-ui/core/withMobileDialog/index";

const styles = (theme) => ({
  textField: {
    minWidth: 223,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 223,
  },
});

class AddForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange({
      ...this.props.form,
      [event.target.name]:
        event.target.name === "mac_addr"
          ? (event.target.value || "").toLowerCase()
          : event.target.value,
    });
  }

  render() {
    const { classes, fullScreen, open, onCancel, onOk, form, title } =
      this.props;
    const textFieldProps = {
      className: classes.textField,
      variant: "outlined",
      margin: "dense",
    };
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        fullScreen={fullScreen}
        open={open}
        onClose={onCancel}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <TextField
            {...textFieldProps}
            onChange={this.onChange}
            label="收款人"
            name="name"
            type="text"
            value={form.name || ""}
          />
          <br />
          <TextField
            {...textFieldProps}
            onChange={this.onChange}
            label="手机号-须与银行卡登记的一致"
            placeholder="与银行卡绑定手机相同"
            name="phone"
            type="text"
            value={form.phone || ""}
          />
          <br />
          <TextField
            {...textFieldProps}
            onChange={this.onChange}
            label="身份证号"
            name="id_card"
            type="text"
            value={form.id_card || ""}
          />
          <br />
          <TextField
            {...textFieldProps}
            onChange={this.onChange}
            label="开户银行"
            name="bank"
            type="text"
            value={form.bank || ""}
          />
          <br />
          <TextField
            {...textFieldProps}
            onChange={this.onChange}
            label="开户支行 - 从市开始"
            placeholder="郑州分行文化路支行"
            helperText="e.g. 郑州分行文化路支行"
            name="branch"
            type="text"
            value={form.branch || ""}
          />
          <br />
          <TextField
            {...textFieldProps}
            onChange={this.onChange}
            label="银行卡账号"
            name="account"
            type="text"
            value={form.account || ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            取消
          </Button>
          <Button
            onClick={onOk}
            color="primary"
            disabled={
              !(
                form.name &&
                form.phone &&
                form.id_card &&
                form.account &&
                form.bank &&
                form.branch
              )
            }
          >
            完成设置
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(AddForm));
