import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog/index";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  textField: {
    minWidth: 223,
  },
  title: {
    fontWeight: "bold",
  },
  littleTitle: {
    display: "flex",
    alignItems: "center",
  },
  line: {
    flex: "1 1 auto",
    width: "100%",
    height: 0,
    border: "1px solid #999",
    verticalAlign: "middle",
  },
  littleTitleContent: {
    flex: "0 0 auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  fapiaoInfo: {
    textAlign: "left",
    marginTop: theme.spacing(1),
  },
}));

function Form(props) {
  const { fullScreen, onChange, open, onCancel, onOk, form } = props;
  const classes = useStyles();
  const onFormChange = (event) => {
    onChange({
      ...form,
      [event.target.name]:
        event.target.type === "text"
          ? (event.target.value || "").toLowerCase()
          : Number(event.target.value),
    });
  };
  const onChangeTextField = (event) => {
    onChange({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const SelectProps = {
    className: classes.textField,
    // variant: "outlined",
    margin: "dense",
  };
  const textFieldProps = {
    className: classes.textField,
    variant: "outlined",
    margin: "dense",
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
      open={open}
      onClose={onCancel}
    >
      <DialogTitle id="form-dialog-title">设置发票信息</DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <FormControl {...SelectProps}>
          <InputLabel htmlFor="type">专票税率</InputLabel>
          <Select
            value={form.tax_rate || 0}
            onChange={onFormChange}
            inputProps={{
              name: "tax_rate",
              id: "tax_rate",
            }}
          >
            <MenuItem value={0}>-- 请选择 --</MenuItem>
            <MenuItem value={1}>1%</MenuItem>
            <MenuItem value={2}>2%</MenuItem>
            <MenuItem value={3}>3%</MenuItem>
            <MenuItem value={4}>4%</MenuItem>
            <MenuItem value={5}>5%</MenuItem>
            <MenuItem value={6}>6%</MenuItem>
          </Select>
          <TextField
            {...textFieldProps}
            onChange={onChangeTextField}
            label="公司名称"
            helperText="加快发票验收速度"
            name="company"
            type="text"
            value={form.company || ""}
          />
          <TextField
            {...textFieldProps}
            onChange={onChangeTextField}
            label="手机号"
            helperText="便于沟通"
            name="phone"
            type="text"
            value={form.phone || ""}
          />
        </FormControl>

        <div className={classes.fapiaoInfo}>
          <Typography variant="caption">
            <span className={classes.title}>1. </span>
            发票类型为“增值税专用发票”，标准税率要求为6%
            <br />
            <span className={classes.title}>2. </span>
            如果开票税点不足6%，提现时系统会自动计算差额扣除，请按系统计算出的该发票价税合计值结果开票
            <br />
            <span className={classes.title}>3. </span>
            鼓励地方运营商及有经营公司和个体工商户的资源贡献者在无税损的情况下多多将相关无票收入开票给我司，增值税专票（优先）和普票均可，累积一定数额公司有精美礼品赠送
            <br />
            <span className={classes.title}>4. </span>
            13%的材料类目（如计算机硬件及耗材）发票、9%的工程类目（如基础电信服务、出版物、不动产租赁服务等）发票也欢迎，可特别联系
            <br />
            <div className={classes.littleTitle}>
              <div className={classes.line}></div>
              <Typography
                className={classes.littleTitleContent}
                variant="body1"
                align={"center"}
              >
                开票信息
              </Typography>
              <div className={classes.line}></div>
            </div>
            <span className={classes.title}>名称：</span>深圳梨享计算有限公司
            <br />
            <span className={classes.title}>纳税人识别号：</span>
            91440300MA5EDRDG7X
            <br />
            <span className={classes.title}>地址、电话：</span>
            深圳市南山区桃源街道平山社区平山一路2号南山云谷创业园二期9栋416
            0755-27605666
            <br />
            <span className={classes.title}>开户行及账号：</span>
            招商银行深圳分行蔡屋围支行 755933213010401
            <br />
            <span className={classes.title}>发票邮寄地址：</span>
            深圳市南山区桃园街道平山一路南山云谷创新产业园二期9栋416
            <br />
            <span className={classes.title}>收件人信息：</span>练小姐
            18813589181
            <br />
            <span className={classes.title}>电子发票收件邮箱：</span>
            lyric@pear.hk
            <br />
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          取消
        </Button>
        <Button onClick={onOk} color="primary" disabled={!form.tax_rate}>
          提交
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withMobileDialog()(Form);
