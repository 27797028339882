const Status = {
  LOADING: "loading",
  SUCCESS: "success",
  FAILURE: "failure",
};

export default (state = { bw: {} }, action) => {
  switch (action.type) {
    case "BC/BW_STARTED": {
      return { ...state, bcBWStatus: Status.LOADING };
    }
    case "BC/BW_SUCCESS": {
      return { ...state, bcBWStatus: Status.SUCCESS, bw: action.result };
    }
    case "BC/BW_FAILURE": {
      return { ...state, bcBWStatus: Status.FAILURE };
    }
    case "BC/NODE_STARTED": {
      return { ...state, bcNodeStatus: Status.LOADING };
    }
    case "BC/NODE_SUCCESS": {
      return { ...state, bcNodeStatus: Status.SUCCESS, nodes: action.result };
    }
    case "BC/NODE_FAILURE": {
      return { ...state, bcNodeStatus: Status.FAILURE };
    }
    case "ADVANCEDNODES/NODE_STARTED": {
      return { ...state, getAdvancedNodesStatus: Status.LOADING };
    }
    case "ADVANCEDNODES/NODE_SUCCESS": {
      return {
        ...state,
        getAdvancedNodesStatus: Status.SUCCESS,
        advancedNodes: action.result,
      };
    }
    case "ADVANCEDNODES/NODE_FAILURE": {
      return { ...state, getAdvancedNodesStatus: Status.FAILURE };
    }

    case "ADVANCEDNODE/TRAFFICTODAY_STARTED": {
      return {
        ...state,
        getadvancedNodeTrafficTodayStatus: Status.LOADING,
        advancedNodeTrafficToday: [],
      };
    }
    case "ADVANCEDNODE/TRAFFICTODAY_SUCCESS": {
      return {
        ...state,
        getadvancedNodeTrafficTodayStatus: Status.SUCCESS,
        advancedNodeTrafficToday: action.result,
      };
    }
    case "ADVANCEDNODE/TRAFFICTODAY_FAILURE": {
      return {
        ...state,
        getadvancedNodeTrafficTodayStatus: Status.FAILURE,
        advancedNodeTrafficToday: [],
      };
    }

    case "ADVANCEDNODE/CPUIOTODAY_STARTED": {
      return {
        ...state,
        getadvancedNodeCPUIOTodayStatus: Status.LOADING,
        advancedNodeCPUIOToday: null,
      };
    }
    case "ADVANCEDNODE/CPUIOTODAY_SUCCESS": {
      return {
        ...state,
        getadvancedNodeCPUIOTodayStatus: Status.SUCCESS,
        advancedNodeCPUIOToday: action.result,
      };
    }
    case "ADVANCEDNODE/CPUIOTODAY_FAILURE": {
      return {
        ...state,
        getadvancedNodeCPUIOTodayStatus: Status.FAILURE,
        advancedNodeCPUIOToday: null,
      };
    }
    case "ADVANCEDNODE/RETRANSTODAY_STARTED": {
      return {
        ...state,
        getAdvancedRetransTodayStatus: Status.LOADING,
        advancedNodeRetransToday: null,
      };
    }
    case "ADVANCEDNODE/RETRANSTODAY_SUCCESS": {
      return {
        ...state,
        getAdvancedRetransTodayStatus: Status.SUCCESS,
        advancedNodeRetransToday: action.result,
      };
    }
    case "ADVANCEDNODE/RETRANSTODAY_FAILURE": {
      return {
        ...state,
        getAdvancedRetransTodayStatus: Status.FAILURE,
        advancedNodeRetransToday: null,
      };
    }
    case "ADVANCEDNODE/RETRANSYESTERDAY_STARTED": {
      return {
        ...state,
        getAdvancedRetransYesterdayStatus: Status.LOADING,
        advancedNodeRetransYesterday: null,
      };
    }
    case "ADVANCEDNODE/RETRANSYESTERDAY_SUCCESS": {
      return {
        ...state,
        getAdvancedRetransYesterdayStatus: Status.SUCCESS,
        advancedNodeRetransYesterday: action.result,
      };
    }
    case "ADVANCEDNODE/RETRANSYESTERDAY_FAILURE": {
      return {
        ...state,
        getAdvancedRetransYesterdayStatus: Status.FAILURE,
        advancedNodeRetransYesterday: null,
      };
    }
    case "ADVANCEDNODE/TRAFFICYESTERDAY_STARTED": {
      return {
        ...state,
        getadvancedNodeTrafficYesterdayStatus: Status.LOADING,
        advancedNodeTrafficYesterday: [],
      };
    }
    case "ADVANCEDNODE/TRAFFICYESTERDAY_SUCCESS": {
      return {
        ...state,
        getadvancedNodeTrafficYesterdayStatus: Status.SUCCESS,
        advancedNodeTrafficYesterday: action.result,
      };
    }
    case "ADVANCEDNODE/TRAFFICYESTERDAY_FAILURE": {
      return {
        ...state,
        getadvancedNodeTrafficYesterdayStatus: Status.FAILURE,
        advancedNodeTrafficYesterday: [],
      };
    }

    case "ADVANCEDNODE/CPUIOYESTERDAY_STARTED": {
      return {
        ...state,
        getadvancedNodeCPUIOYesterdayStatus: Status.LOADING,
        advancedNodeCPUIOYesterday: null,
      };
    }
    case "ADVANCEDNODE/CPUIOYESTERDAY_SUCCESS": {
      return {
        ...state,
        getadvancedNodeCPUIOYesterdayStatus: Status.SUCCESS,
        advancedNodeCPUIOYesterday: action.result,
      };
    }
    case "ADVANCEDNODE/CPUIOYESTERDAY_FAILURE": {
      return {
        ...state,
        getadvancedNodeCPUIOYesterdayStatus: Status.FAILURE,
        advancedNodeCPUIOYesterday: null,
      };
    }

    case "ADVANCEDNODE/CHARGINGBWS/LASTWEEK_STARTED": {
      return {
        ...state,
        getadvancedNodeChargingBWsLastWeekStatus: Status.LOADING,
        advancedNodeChargingBWsLastWeek: [],
      };
    }
    case "ADVANCEDNODE/CHARGINGBWS/LASTWEEK_SUCCESS": {
      return {
        ...state,
        getadvancedNodeChargingBWsLastWeekStatus: Status.SUCCESS,
        advancedNodeChargingBWsLastWeek: action.result,
      };
    }
    case "ADVANCEDNODE/CHARGINGBWS/LASTWEEK_FAILURE": {
      return {
        ...state,
        getadvancedNodeChargingBWsLastWeekStatus: Status.FAILURE,
        advancedNodeChargingBWsLastWeek: [],
      };
    }

    case "ADVANCEDNODE/CHARGINGBWS/LASTMONTH_STARTED": {
      return {
        ...state,
        getadvancedNodeChargingBWsLastMonthStatus: Status.LOADING,
        advancedNodeChargingBWsLastMonth: [],
      };
    }
    case "ADVANCEDNODE/CHARGINGBWS/LASTMONTH_SUCCESS": {
      return {
        ...state,
        getadvancedNodeChargingBWsLastMonthStatus: Status.SUCCESS,
        advancedNodeChargingBWsLastMonth: action.result,
      };
    }
    case "ADVANCEDNODE/CHARGINGBWS/LASTMONTH_FAILURE": {
      return {
        ...state,
        getadvancedNodeChargingBWsLastMonthStatus: Status.FAILURE,
        advancedNodeChargingBWsLastMonth: [],
      };
    }
    case "ADVANCEDNODE/ERROR_TYPE_STARTED": {
      return { ...state, getErrorTypeStatus: Status.LOADING };
    }
    case "ADVANCEDNODE/ERROR_TYPE_SUCCESS": {
      let m = {};
      const result = action.result;
      for (let i = 0; i < result.length; i++) {
        m[result[i].id] = result[i];
      }

      return { ...state, getErrorTypeStatus: Status.SUCCESS, errorTypeMap: m };
    }
    case "ADVANCEDNODE/ERROR_TYPE_FAILURE": {
      return { ...state, getErrorTypeStatus: Status.FAILURE };
    }

    case "ADVANCEDNODE/CPERROR_STARTED": {
      return { ...state, getErrorTypeStatus: Status.LOADING };
    }
    case "ADVANCEDNODE/CPERROR_SUCCESS": {
      let m = {};
      const result = action.result;
      for (let i = 0; i < result.length; i++) {
        m[result[i].id] = result[i];
      }
      return { ...state, getErrorTypeStatus: Status.SUCCESS, cpErrorMap: m };
    }
    case "ADVANCEDNODE/CPERROR_FAILURE": {
      return { ...state, getErrorTypeStatus: Status.FAILURE };
    }
    case "ADVANCEDNODE/ERROR_STARTED": {
      return {
        ...state,
        getErrorTypeStatus: Status.LOADING,
        machineErrorMap: {},
      };
    }
    case "ADVANCEDNODE/ERROR_SUCCESS": {
      let m = {};
      const result = action.result;
      for (let i = 0; i < result.length; i++) {
        if (!m[result[i].machine_id]) {
          m[result[i].machine_id] = [];
        }
        m[result[i].machine_id].push(result[i]);
      }
      return {
        ...state,
        getErrorTypeStatus: Status.SUCCESS,
        machineErrorMap: m,
      };
    }
    case "ADVANCEDNODE/ERROR_FAILURE": {
      return { ...state, getErrorTypeStatus: Status.FAILURE };
    }
    case "ADVANCEDNODE/STORAGE_STARTED": {
      return {
        ...state,
        getAdvancedNodeStorageStatus: Status.LOADING,
        advancedNodeStorage: {},
      };
    }
    case "ADVANCEDNODE/STORAGE_SUCCESS": {
      console.log("handle usermine storage", Date.now());
      let m = {};
      // { name,80id,busid,uuid,snid,  c0~c287 }
      const data = action.result;
      // TODO 如果不存在 io_rate中，是否展示
      for (let i = 0; i < data["io_rate"].length; i++) {
        const tmp = data["io_rate"][i];
        if (!m[tmp["pear_id"]]) {
          m[tmp["pear_id"]] = {};
        }

        m[tmp["pear_id"]][tmp["name"]] = {
          name: tmp["name"],
          p80id: tmp["80id"],
          busid: tmp["busid"],
          uuid: tmp["uuid"],
          snid: tmp["snid"] || "",
          io_rate: tmp["c"],
        };
      }

      for (let i = 0; i < data["io_times"].length; i++) {
        const tmp = data["io_times"][i];
        m[tmp["pear_id"]][tmp["name"]]["io_times"] = tmp["c"];
      }
      for (let i = 0; i < data["iorl_rate"].length; i++) {
        const tmp = data["iorl_rate"][i];
        m[tmp["pear_id"]][tmp["name"]]["iorl_rate"] = tmp["c"];
      }
      for (let i = 0; i < data["iorl_times"].length; i++) {
        const tmp = data["iorl_times"][i];
        m[tmp["pear_id"]][tmp["name"]]["iorl_times"] = tmp["c"];
      }
      for (let i = 0; i < data["iowl_rate"].length; i++) {
        const tmp = data["iowl_rate"][i];
        m[tmp["pear_id"]][tmp["name"]]["iowl_rate"] = tmp["c"];
      }
      for (let i = 0; i < data["iowl_times"].length; i++) {
        const tmp = data["iowl_times"][i];
        m[tmp["pear_id"]][tmp["name"]]["iowl_times"] = tmp["c"];
      }
      for (let i = 0; i < data["io_temp"].length; i++) {
        const tmp = data["io_temp"][i];
        m[tmp["pear_id"]][tmp["name"]]["io_temp"] = tmp["c"];
      }
      console.log("end", Date.now());

      return {
        ...state,
        getAdvancedNodeStorageStatus: Status.SUCCESS,
        advancedNodeStorage: m,
      };
    }
    case "ADVANCEDNODE/STORAGE_FAILURE": {
      return { ...state, getStorageStatus: Status.FAILURE };
    }
    default: {
      return state;
    }
  }
};
